export const MortgagesIcon = ({ activeColor }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M425.667-1H84.333c-9.387,0-17.067,7.68-17.067,17.067v477.867c0,9.387,7.68,17.067,17.067,17.067h341.333
				c9.387,0,17.067-7.68,17.067-17.067V16.067C442.733,6.68,435.053-1,425.667-1z M425.667,493.933H84.333V16.067h341.333V493.933z" fill={activeColor}/>
			<path d="M209.773,107.373l11.093-11.093v39.253c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533v-56.32L255,62.147
				l17.067,17.067v56.32c0,5.12,3.413,8.533,8.533,8.533c5.12,0,8.533-3.413,8.533-8.533V96.28l11.093,11.093
				c1.707,1.707,3.413,2.56,5.973,2.56s4.267-0.853,5.973-2.56c3.413-3.413,3.413-8.533,0-11.947l-24.14-24.14
				c-0.689-1.239-1.681-2.232-2.921-2.921l-24.14-24.14c-3.413-3.413-8.533-3.413-11.947,0l-24.14,24.14
				c-1.239,0.689-2.232,1.681-2.92,2.92l-24.14,24.14c-3.413,3.413-3.413,8.533,0,11.947S206.36,110.787,209.773,107.373z"/>
			<path d="M127,220.867h119.467c5.12,0,8.533-3.413,8.533-8.533c0-5.12-3.413-8.533-8.533-8.533H127
				c-5.12,0-8.533,3.413-8.533,8.533C118.467,217.453,121.88,220.867,127,220.867z" fill={activeColor}/>
			<path d="M127,255h85.333c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533H127c-5.12,0-8.533,3.413-8.533,8.533
				S121.88,255,127,255z" fill={activeColor}/>
			<path d="M127,357.4h85.333c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533H127c-5.12,0-8.533,3.413-8.533,8.533
				S121.88,357.4,127,357.4z" fill={activeColor}/>
			<path d="M291.693,214.893c-3.413,3.413-3.413,8.533,0,11.947l25.6,25.6c1.707,1.707,3.413,2.56,5.973,2.56
				c3.413,0,5.12-1.707,6.827-2.56l42.667-59.733c3.413-4.267,1.707-9.387-1.707-11.947c-4.267-3.413-9.387-1.707-11.947,1.707
				l-36.693,51.2l-18.773-18.773C300.227,211.48,295.107,211.48,291.693,214.893z" fill={activeColor}/>
			<path d="M127,323.267h119.467c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533H127c-5.12,0-8.533,3.413-8.533,8.533
				S121.88,323.267,127,323.267z" fill={activeColor}/>
			<path d="M371.053,283.16c-4.267-3.413-9.387-1.707-11.947,1.707l-36.693,51.2l-18.773-18.773c-3.413-3.413-8.533-3.413-11.947,0
				s-3.413,8.533,0,11.947l25.6,25.6c1.707,1.707,3.413,2.56,5.973,2.56c3.413,0,5.12-1.707,6.827-2.56l42.667-59.733
				C376.173,290.84,374.467,285.72,371.053,283.16z" fill={activeColor}/>
			<path d="M127,425.667h119.467c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533H127c-5.12,0-8.533,3.413-8.533,8.533
				S121.88,425.667,127,425.667z" fill={activeColor}/>
			<path d="M127,459.8h85.333c5.12,0,8.533-3.413,8.533-8.533c0-5.12-3.413-8.533-8.533-8.533H127c-5.12,0-8.533,3.413-8.533,8.533
				C118.467,456.387,121.88,459.8,127,459.8z" fill={activeColor}/>
			<path d="M371.053,385.56c-4.267-3.413-9.387-1.707-11.947,1.707l-36.693,51.2l-18.773-18.773c-3.413-3.413-8.533-3.413-11.947,0
				s-3.413,8.533,0,11.947l25.6,25.6c1.707,1.707,3.413,2.56,5.973,2.56c3.413,0,5.12-1.707,6.827-2.56l42.667-59.733
				C376.173,393.24,374.467,388.12,371.053,385.56z" fill={activeColor}/>
    </svg>
  );
};
