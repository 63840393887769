export const AppointmentsIcon = ({ activeColor }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 465 465" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={activeColor} d="M142.5,35H345v47.5c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5V27.51l0-0.01l0-0.01V7.5c0-4.143-3.358-7.5-7.5-7.5
        S345,3.357,345,7.5V20H142.5c-4.142,0-7.5,3.357-7.5,7.5S138.358,35,142.5,35z"/>
      <path fill={activeColor} d="M432.5,20h-50c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5H425v95H40V35h65v47.5c0,4.143,3.358,7.5,7.5,7.5
        s7.5-3.357,7.5-7.5v-75c0-4.143-3.358-7.5-7.5-7.5S105,3.357,105,7.5V20H32.5c-4.142,0-7.5,3.357-7.5,7.5v370
        c0,4.143,3.358,7.5,7.5,7.5h330c0.251,0,0.501-0.013,0.749-0.038c0.186-0.019,0.368-0.05,0.549-0.082
        c0.059-0.01,0.119-0.015,0.178-0.026c0.214-0.043,0.423-0.099,0.63-0.158c0.026-0.008,0.054-0.013,0.08-0.021
        c0.208-0.063,0.41-0.138,0.609-0.218c0.027-0.011,0.054-0.019,0.081-0.029c0.189-0.079,0.371-0.168,0.552-0.261
        c0.037-0.02,0.076-0.035,0.112-0.055c0.165-0.088,0.323-0.187,0.48-0.287c0.05-0.031,0.102-0.059,0.151-0.092
        c0.146-0.098,0.285-0.205,0.423-0.313c0.055-0.043,0.113-0.081,0.167-0.125c0.169-0.139,0.33-0.287,0.486-0.439
        c0.018-0.019,0.039-0.033,0.057-0.052l70-70c0.015-0.015,0.027-0.031,0.042-0.046c0.157-0.16,0.308-0.324,0.451-0.498
        c0.039-0.047,0.071-0.098,0.109-0.145c0.114-0.146,0.227-0.292,0.33-0.446c0.028-0.041,0.05-0.085,0.077-0.127
        c0.106-0.164,0.209-0.331,0.301-0.504c0.017-0.03,0.029-0.063,0.045-0.094c0.096-0.187,0.188-0.375,0.269-0.569
        c0.009-0.022,0.015-0.045,0.024-0.066c0.082-0.204,0.159-0.411,0.223-0.623c0.008-0.025,0.012-0.052,0.02-0.077
        c0.061-0.208,0.116-0.418,0.159-0.632c0.012-0.061,0.017-0.122,0.028-0.183c0.031-0.181,0.063-0.36,0.081-0.545
        c0.025-0.248,0.038-0.498,0.038-0.749v-300C440,23.357,436.642,20,432.5,20z M40,145h385v175h-62.5c-4.142,0-7.5,3.357-7.5,7.5V390
        H40V145z M414.394,335L370,379.394V335H414.394z"/>
      <path fill={activeColor} d="M432.5,450h-400c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h400c4.142,0,7.5-3.357,7.5-7.5S436.642,450,432.5,450z"/>
      <path fill={activeColor} d="M432.5,350c-4.142,0-7.5,3.357-7.5,7.5V420H40v-2.5c0-4.143-3.358-7.5-7.5-7.5s-7.5,3.357-7.5,7.5v10
        c0,4.143,3.358,7.5,7.5,7.5h400c4.142,0,7.5-3.357,7.5-7.5v-70C440,353.357,436.642,350,432.5,350z"/>
      <path fill={activeColor} d="M288.954,207.071c-2.801-1.16-6.028-0.521-8.173,1.625l-21.4,21.399c-2.929,2.93-2.929,7.678,0,10.607
        c2.929,2.928,7.678,2.928,10.606,0l8.597-8.597V321c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5V214
        C293.583,210.967,291.756,208.231,288.954,207.071z"/>
      <path fill={activeColor} d="M206.8,206.5c-19.511,0-35.384,15.873-35.384,35.384c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5
        c0-11.239,9.144-20.384,20.384-20.384c11.239,0,20.383,9.145,20.383,20.384c0,8.15-4.839,15.502-12.329,18.729
        c-2.751,1.185-4.533,3.893-4.533,6.888s1.782,5.703,4.533,6.888c7.489,3.227,12.329,10.578,12.329,18.729
        c0,11.239-9.144,20.384-20.383,20.384c-11.24,0-20.384-9.145-20.384-20.384c0-4.143-3.358-7.5-7.5-7.5s-7.5,3.357-7.5,7.5
        c0,19.511,15.873,35.384,35.384,35.384c19.51,0,35.383-15.873,35.383-35.384c0-9.866-4.085-19.058-10.966-25.616
        c6.881-6.559,10.966-15.75,10.966-25.616C242.184,222.373,226.311,206.5,206.8,206.5z"/>
    </svg>
  );
};
