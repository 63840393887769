import React from "react";

export const HomAddress = ({ record }) => {
  return (
    <React.Fragment>
      <div>{record.planDescription}</div>
      <div>{record.community.name}</div>
      <div>{record.address}</div>
      <div>
        {record.city}
        {record.city && record.state && ","}
        {record.state}
        {record.state && record.zip && ","}
        {record.city && record.zip && !record.state && ","}
        {record.zip}
      </div>
      <div>LotNumber: {record.lotNumber}</div>
      {record.block ? <div>Block: {record.block}</div> : null}
    </React.Fragment>
  );
};
