import {
  Button,
  useRedirect,
  useRecordContext,
  TopToolbar,
  CreateButton,
  EditButton,
} from "react-admin";
import { TYPE } from "../Constants";
export const CustomRedirectButton = ({ name, url }) => {
  const redirect = useRedirect();
  return <Button label={name} onClick={() => redirect(url)} />;
};

export const CustomSeePostsButton = ({ type, customId }) => {
  const { id } = useRecordContext();
  const urlId = customId ? customId : id;
  return <CustomRedirectButton name="View Posts" url={`${type}/${urlId}`} />;
};

export const CustomCreateButton = (props) => {
  const { label, basePath } = props;
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} label={label} />
    </TopToolbar>
  );
};

export const CustomEditButton = ({ basePath, record, icon }) => {
  return <EditButton basePath={basePath} record={record} label="" icon={icon} />;
};

export const SeePostsButton = (props) => {
  const { type } = props;
  const { id } = useRecordContext();
  return (
    <CustomSeePostsButton
      type={type === TYPE.DIVISIONS ? `${TYPE.DIVISIONS}` : `${TYPE.COMMUNITIES}`}
      customId={type === TYPE.DIVISIONS ? props.record.division.id : id}
    />
  );
};
