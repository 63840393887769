export const DefaultElevationImage = () => {
  return (
    <img
      src={"/elevationImage.png"}
      title="image"
      alt="ElevationImage"
      className="homeimg-wrapper"
    />
  );
};
