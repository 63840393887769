import { DashboardIcon } from "../assets/icons/Dashboard";
import { DocumentsIcon } from "../assets/icons/Documents";
import { MessagesIcon } from "../assets/icons/Messages";
import { PhotosIcon } from "../assets/icons/Photos";
import { TimelineIcon } from "../assets/icons/Timeline";
import { WarrantyIcon } from "../assets/icons/Warranty";
import { AdminIcon } from "../assets/icons/admin";
import { AnnouncementIcon } from "../assets/icons/announcement";
import { CommunityIcon } from "../assets/icons/community";
import { DivisionIcon } from "../assets/icons/division";
import { HouseIcon } from "../assets/icons/house";
import { OwnerIcon } from "../assets/icons/owner";
import { ConfigurationIcon } from "../assets/icons/configuration";
import { MortgagesIcon } from "../assets/icons/mortgages";
import { UserWarrantyIcon } from "../assets/icons/UserWarranty";
import { AppointmentsIcon } from "../assets/icons/Appointments";
import AccountTreeIcon from "@material-ui/icons/AccountTree";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SPRINT_URL = process.env.REACT_APP_URL;
// export const BASE_URL = "http://localhost:5001/testwrh-cms/us-central1";
// export const SPRINT_URL = `http://localhost:3000`;

export const RECORDS_PER_PAGE = 25;

export const CHOOSE_ADMIN_ROLE = [
  { id: "rep", name: "Sales Representative" },
  { id: "office", name: "Office Admin" },
  { id: "corp", name: "Corporate Admin" },
];

export const MAPPED_ADMINS = {
  super: "Super Admin",
  corp: "Corporate Admin",
  office: "Office Admin",
  rep: "Sales Admin",
};

export const ADMIN_ROLES = {
  SUPER: "super",
  OFFICE: "office",
  CORPORATE: "corp",
  SALES: "rep",
};

export const TYPE = {
  COMMUNITIES: "communities",
  DIVISIONS: "divisions",
  HOMES: "homes",
  WARRANTY: "warranty",
};

export const BUYERTYPES = {
  ADDITIONAL: "Additional User",
  BUYER: "Buyer",
  COBUYER: "Co Buyer",
};

export const activeColor = "rgba(0, 0, 0, 0.87)";
export const inActiveColor = "rgba(0, 0, 0, 0.54)";

export const ActiveIcon = {
  documents: <DocumentsIcon activeColor={activeColor} />,
  posts: <PhotosIcon activeColor={activeColor} />,
  timeline: <TimelineIcon activeColor={activeColor} />,
  warrantyDocuments: <WarrantyIcon activeColor={activeColor} />,
  dashboard: <DashboardIcon activeColor={activeColor} />,
  account: <DashboardIcon activeColor={activeColor} />,
  conversations: <MessagesIcon activeColor={activeColor} />,
  userWarranty: <UserWarrantyIcon activeColor={activeColor} />,
  admins: <AdminIcon activeColor={activeColor} />,
  announcements: <AnnouncementIcon activeColor={activeColor} />,
  communities: <CommunityIcon activeColor={activeColor} />,
  divisions: <DivisionIcon activeColor={activeColor} />,
  divisionMapper: <AccountTreeIcon activeColor={activeColor} />,
  homes: <HouseIcon activeColor={activeColor} />,
  homebuyers: <OwnerIcon activeColor={activeColor} />,
  settings: <ConfigurationIcon activeColor={activeColor} />,
  mortage: <MortgagesIcon activeColor={activeColor} />,
  appointments: <AppointmentsIcon activeColor={inActiveColor} />,
};

export const Icon = {
  documents: <DocumentsIcon activeColor={inActiveColor} />,
  posts: <PhotosIcon activeColor={inActiveColor} />,
  timeline: <TimelineIcon activeColor={inActiveColor} />,
  warrantyDocuments: <WarrantyIcon activeColor={inActiveColor} />,
  dashboard: <DashboardIcon activeColor={inActiveColor} />,
  account: <DashboardIcon activeColor={inActiveColor} />,
  conversations: <MessagesIcon activeColor={inActiveColor} />,
  userWarranty: <UserWarrantyIcon activeColor={inActiveColor} />,
  admins: <AdminIcon activeColor={inActiveColor} />,
  announcements: <AnnouncementIcon activeColor={inActiveColor} />,
  communities: <CommunityIcon activeColor={inActiveColor} />,
  divisions: <DivisionIcon activeColor={inActiveColor} />,
  divisionMapper: <AccountTreeIcon activeColor={inActiveColor} />,
  homes: <HouseIcon activeColor={inActiveColor} />,
  homebuyers: <OwnerIcon activeColor={inActiveColor} />,
  settings: <ConfigurationIcon activeColor={inActiveColor} />,
  mortage: <MortgagesIcon activeColor={inActiveColor} />,
  appointments: <AppointmentsIcon activeColor={inActiveColor} />,
};

export const imageExtentions = ["jpg", "jpeg", "png"];

export const APPOINTMENT_PHASES = [
  { id: "contract", name: "Contract" },
  { id: "finance", name: "Finance" },
  { id: "selections", name: "Selections" },
  { id: "construction", name: "Construction" },
  { id: "ownership", name: "Ownership" },
];

export const FILE_SIZE_TYPES = {
  B: "BYTES",
  KB: "KILO_BYTES",
  MB: "MEGA_BYTES",
  GB: "GIGA_BYTES",
};

export const LOCAL_STORAGE_KEYS = {
  SUBMITTED_WARRANTY: "submittedWarrantyDocId",
};

export const WARRANTY_TYPES = {
  SIXTY_DAYS: "60 days",
  ELEVEN_MONTHS: "11 months",
};
