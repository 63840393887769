import * as React from "react";
import { useListFilterContext } from "react-admin";
import { Switch, FormControlLabel } from "@material-ui/core";

export const MyConversationsFilter = () => {
  const { filterValues, displayedFilters, setFilters } = useListFilterContext();
  const handleChange = () => {
    const newFilterValues = { ...filterValues };
    if (typeof filterValues.myConversation !== "undefined") {
      delete newFilterValues.myConversation;
    } else {
      newFilterValues.myConversation = true;
    }
    setFilters(newFilterValues, displayedFilters);
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={
              typeof filterValues.myConversation !== "undefined" && filterValues.myConversation
            }
            defaultValue={false}
            onChange={handleChange}
            color="primary"
            name="myconversations_checked"
          />
        }
        label="My Conversations"
      />
    </div>
  );
};

MyConversationsFilter.defaultProps = { source: "myConversation" };
