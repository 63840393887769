import React from "react";
import { UserWarranty } from "./WarrantyForm";
import { Loading, useQuery } from "react-admin";

export const WarrantyFormWrapper = () => {
  const id = window.location.href.split("/").slice(-2)[0];

  const { loading, error, data } = useQuery({
    type: "getOne",
    resource: "userWarranty/draft",
    payload: { id },
  });

  if (loading) return <Loading />;

  return (
    <React.Fragment>
      {error && <div>Something went wrong, please try again or contact admin</div>}
      {data && <UserWarranty warrantyData={data} />}
    </React.Fragment>
  );
};
