import * as React from "react";
import {
  Datagrid,
  List,
  ImageField,
  DeleteButton,
  CreateButton,
  TopToolbar,
  useRedirect,
} from "react-admin";
import { Button } from "@material-ui/core";
import { PostPagination } from "../../../Components";
import { RECORDS_PER_PAGE } from "../../../Constants";

const ActionBar = (props) => {
  const redirect = useRedirect();
  return (
    <TopToolbar>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CreateButton label="upload image"/>
        <Button
          color="primary"
          onClick={() => {
            redirect("/assets/warranty/video");
          }}
        >
          + Upload Video
        </Button>
      </div>
    </TopToolbar>
  );
};

export const WarrantySampleImagesList = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      perPage={RECORDS_PER_PAGE}
      pagination={<PostPagination />}
      title={"Warranty Assets"}
      actions={<ActionBar {...props} />}
    >
      <Datagrid>
        <ImageField source="url" label="View" sortable={false} className="homeimg-wrapper" />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
