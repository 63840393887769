export const HouseIcon = ({ activeColor }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 330 330" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M325.606,179.387L250,103.787V40c0-8.284-6.716-15-15-15c-8.284,0-15,6.716-15,15v33.789l-44.4-44.397
        c-5.857-5.857-15.355-5.857-21.212,0.001L4.393,179.388c-5.858,5.857-5.858,15.355,0,21.213c2.929,2.929,6.767,4.394,10.606,4.394
        c3.839,0,7.678-1.465,10.606-4.394L50,176.207V290c0,8.284,6.716,15,15,15h70h60h70c8.284,0,15-6.716,15-15V176.209l24.394,24.393
        c5.857,5.857,15.355,5.856,21.213-0.001C331.465,194.742,331.464,185.245,325.606,179.387z M150,275v-60h30v60H150z M250,275h-40
        v-75c0-8.284-6.716-15-15-15h-60c-8.284,0-15,6.716-15,15v75H80V146.207l84.995-84.994L250,146.212V275z" fill={activeColor}/>
  </svg>
  );
};
