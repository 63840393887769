export const MessagesIcon = ({ activeColor }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 5C6.22464 5 5.80072 5.17559 5.48816 5.48816C5.17559 5.80072 5 6.22464 5 6.66667V25.5858L8.62623 21.9596C8.81376 21.772 9.06812 21.6667 9.33333 21.6667H25.3333C25.7754 21.6667 26.1993 21.4911 26.5118 21.1785C26.8244 20.8659 27 20.442 27 20V6.66667C27 6.22464 26.8244 5.80072 26.5118 5.48816C26.1993 5.17559 25.7754 5 25.3333 5H6.66667ZM4.07394 4.07394C4.76158 3.38631 5.69421 3 6.66667 3H25.3333C26.3058 3 27.2384 3.38631 27.9261 4.07394C28.6137 4.76157 29 5.69421 29 6.66667V20C29 20.9725 28.6137 21.9051 27.9261 22.5927C27.2384 23.2804 26.3058 23.6667 25.3333 23.6667H9.74755L4.70711 28.7071C4.42111 28.9931 3.99099 29.0787 3.61732 28.9239C3.24364 28.7691 3 28.4045 3 28V6.66667C3 5.69421 3.38631 4.76158 4.07394 4.07394Z"
        fill={activeColor}
      />
    </svg>
  );
};
