import "../../assets/css/theme.css";
import { useQuery } from "react-admin";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import React from "react";
import { BuyerContact, StartConversationButton } from "../../Components";

export const HomeExpand = ({ record }) => {
  const { id } = record;

  const {
    data,
    loading: isLoading,
    error: isError,
  } = useQuery({
    type: "getOne",
    resource: "homes/buyerDetails",
    payload: { id },
  });

  const tableHeadings = ["Name", "Email", "Type", "Contact Number"];
  const isConversationPage = window.location.href.search("conversation") !== -1;
  isConversationPage && tableHeadings.push("Conversation");

  return (
    <Card>
      <CardContent>
        {isLoading ? (
          <Typography variant="body2">Loading ...</Typography>
        ) : isError ? (
          <Typography variant="body2">Something went wrong ...</Typography>
        ) : data.buyers.length === 0 ? (
          <Typography variant="body2">{data.homeType ?? "No homebuyers"}</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {tableHeadings.map((heading, index) => {
                  return (
                    <TableCell key={index}>
                      <strong>{heading}</strong>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.buyers.map((homebuyer) => (
                  <TableRow key={homebuyer.id}>
                    <TableCell>{`${homebuyer.firstName} ${homebuyer.lastName}`}</TableCell>
                    <TableCell>{homebuyer.email}</TableCell>
                    <TableCell>{homebuyer.buyerType}</TableCell>
                    <TableCell>
                      <BuyerContact record={homebuyer} />
                    </TableCell>
                    {isConversationPage && (
                      <TableCell>
                        <StartConversationButton homeId={id} buyer={homebuyer} />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};
