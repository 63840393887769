import { fetchUtils } from "react-admin";
import { FirebaseAuthProvider } from "react-admin-firebase";
import jsonplaceholder from "ra-data-json-server";
import { firebaseConfig as config } from "../firebaseConfig";
import { BASE_URL } from "../Constants";
import { getIdToken, setTokenToLocalStore } from "../Utils";
import instance from "../Core/service";
import firebase from "../firebaseConfig";
export async function getUser() {
  return authProvider
    .checkAuth()
    .then((res) => {
      return res;
    })
    .catch((err) => console.log("error"));
}
const httpClient = async (url, options = {}) => {
  const token = await getIdToken();
  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json",
    });
  }

  // The most common usage of custom headers is for authentication.
  // fetchJson has built-on support for the Authorization token header:
  options.user = {
    authenticated: true,
    token: `Bearer ${token}`,
  };

  // add your own headers here
  // options.header.set('key', 'value')
  return fetchUtils.fetchJson(url, options);
};

const firebaseAuthProvider = FirebaseAuthProvider(config);
export const dataProvider = jsonplaceholder(BASE_URL, httpClient);
export const myDataProvider = {
  ...dataProvider,
  delete: (resource, params) => {
    if (resource === "assets/warranty") {
      return dataProvider.delete("documentsAdmin/warranty/assets/images", params);
    }
    return dataProvider.delete(resource, params);
  },
  getOne: (resource, params) => {
    if (resource === "envision") {
      return dataProvider.getOne("divisions/details", params);
    }
    return dataProvider.getOne(resource, params);
  },
  getList: (resource, params) => {
    if (resource === "report" || resource === "report/generate") {
      return dataProvider.getList(`homebuyers/${resource}`, params);
    }
    if (resource === "assets/warranty") {
      return dataProvider.getList("documentsAdmin/warranty/assets/images", params);
    }
    return dataProvider.getList(resource, params);
  },
  update: (resource, params) => {
    if (resource === "envision") {
      return dataProvider.update("divisions", params);
    }
    if (
      resource === "homes" ||
      resource === "mortage" ||
      resource === "timeline" ||
      resource === "documentsAdmin/homes" ||
      "documentsAdmin/communities" ||
      "documentsAdmin/divisions" ||
      "documentsAdmin/warranty"
    ) {
      return convertFileToBase64Update({ resource, params });
    } else {
      return dataProvider.update(resource, params);
    }
  },
  create: (resource, params) => {
    if (/(designCenter\/\d+)/.test(resource)) {
      return createDesignCenterImage({ resource, params });
    }
    if (resource === "assets/warranty") {
      return createDesignCenterImage({ resource: "documentsAdmin/warranty/assets/images", params });
    }
    if (
      !(
        resource.includes("documentsAdmin") && resource !== "documentsAdmin/warranty/assets/video"
      ) &&
      !resource.includes("warrantyDocuments") &&
      !resource.includes("posts")
    ) {
      return dataProvider.create(resource, params);
    }
    if (resource.includes("posts")) {
      return createPost({ resource, params });
    } else {
      return createDocument({ resource, params });
    }
  },
};
const convertFileToBase64Update = async ({ resource, params }) => {
  if (params.data.file) {
    return convertFileToBase64(params.data.file.rawFile)
      .then((base64Pictures) => ({
        src: base64Pictures,
        title: `${params.data.file.rawFile.name}`,
      }))
      .then((transformedNewPicture) => {
        const index = params.data.file.rawFile.name.lastIndexOf(".");
        return dataProvider.update(resource, {
          ...params,
          data: {
            ...params.data,
            file: {
              fileLocation: transformedNewPicture.src,
              fileExtension: params.data.file.rawFile.name.substring(index + 1),
              type: params.data.file.rawFile.type,
              name: params.data.file.rawFile.name,
            },
          },
        });
      })
      .catch((error) => {
        Promise.reject(error);
      });
  } else {
    return dataProvider.update(resource, {
      ...params,
    });
  }
};

export const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

const handleAuthLogin = async (params) => {
  const { username, password } = params;
  if (username && password) {
    try {
      const user = await firebase.auth().signInWithEmailAndPassword(username, password);
      console.log("HandleAuthLogin: user sucessfully logged in", { user });
      return user;
    } catch (e) {
      console.log("HandleAuthLogin: invalid credentials", { params });
      // eslint-disable-next-line no-throw-literal
      throw { message: e.message, code: e.code };
    }
  } else {
    return firebaseAuthProvider.getAuthUser();
  }
};

export const authProvider = {
  ...firebaseAuthProvider,
  login: async ({ username, password }) => {
    await handleAuthLogin({ username, password });
    const token = await setTokenToLocalStore();
    const isEmailVerified = token.claims.email_verified;
    if (!isEmailVerified) {
      await updateAdminInviteStatus();
    }
  },
  logout: async () => {
    await firebaseAuthProvider.logout();
    localStorage.clear("token");
    window.location.reload(true);
  },
};

const updateAdminInviteStatus = async () => {
  instance
    .get(`${BASE_URL}/adminInvite/accept`)
    .then((res) => {
      res.status === 200 ? console.log("invite accepted") : console.log("error");
    })
    .catch((e) => {
      console.log(e);
    });
};

const createWithRetry = async (apiCall, data, retries = 1) => {
  try {
    return await apiCall();
  } catch (error) {
    if (retries > 0) {
      return createWithRetry(apiCall, data, retries - 1);
    }
    error.extraData = data;
    throw error;
  }
};

const createDocument = async ({ resource, params }) => {
  try {
    const base64FilesPromises = params.data.documents.map((doc) =>
      convertFileToBase64(doc.file).then((base64Picture) => ({
        src: base64Picture,
        title: doc.title,
        rawFile: doc.file,
      }))
    );

    const base64Files = await Promise.all(base64FilesPromises);

    const results = [];
    const errors = [];

    await Promise.all(
      base64Files.map(async (transformedNewPicture) => {
        const rawFileName = transformedNewPicture.rawFile.name;
        const index = rawFileName.lastIndexOf(".");
        let dataObject = {
          list: params.data.list,
          documentType: params.data.documentType,
          title: transformedNewPicture.title,
          file: {
            fileLocation: transformedNewPicture.src,
            fileExtension: rawFileName.substring(index + 1),
            type: transformedNewPicture.rawFile.type,
            name: rawFileName,
          },
        };
        if (params?.data?.description) {
          dataObject = {
            ...dataObject,
            description: params.data.description,
          };
        }
        const apiCall = () =>
          dataProvider.create(resource, {
            ...params,
            data: dataObject,
          });

        try {
          const result = await createWithRetry(apiCall, dataObject);
          results.push(result);
        } catch (error) {
          errors.push(error);
        }
      })
    );

    if (errors.length > 0) {
      return Promise.reject(errors);
    }

    return results[0];
  } catch (error) {
    return Promise.reject(error);
  }
};

const createPost = ({ resource, params }) => {
  const images = params.data.images.map((image) => image.rawFile);
  return Promise.all(images.map(convertFileToBase64))
    .then((base64File) =>
      base64File.map((file64) => ({
        src: file64,
        title: `${params.data.title}`,
      }))
    )
    .then((transformedNewFiles) => {
      const images = getImages(transformedNewFiles, params.data.images);
      return dataProvider.create(resource, {
        ...params,
        data: {
          ...params.data,
          images,
        },
      });
    });
};

const createDesignCenterImage = ({ resource, params }) => {
  const image = params.data?.image;
  return convertFileToBase64(image.rawFile).then((base64File) => {
    return dataProvider.create(resource, {
      ...params,
      data: {
        ...params.data,
        image: {
          fileLocation: base64File,
          name: image.rawFile.name,
          type: image.rawFile.type,
        },
      },
    });
  });
};

export const getImages = (images, originalImages) => {
  const newImages = [];
  for (let i = 0; i < images.length; i++) {
    newImages.push({
      fileLocation: images[i].src,
      name: originalImages[i].rawFile.name,
      type: originalImages[i].rawFile.type,
    });
  }
  return newImages;
};
