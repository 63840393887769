import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  TextField,
  required,
  FileInput,
  FileField,
  useNotify,
  useRedirect,
} from "react-admin";
import { FILE_SIZE_TYPES } from "../../Constants";
import { validateFileSize, formatWord, maxLength } from "../../Utils";

const type = "warranty";

export const WarrantyDocumentCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const transform = (data) => {
    const { fileLocation, title, description } = data;

    return {
      documentType: type,
      documents: [{ title, file: fileLocation.rawFile }],
      description,
    };
  };

  const onSuccess = () => {
    notify("Document created", "success");
    redirect(`/documentsAdmin/${type}`);
  };

  const onFailure = (error) => {
    notify("Try again", "error");
  };

  return (
    <Create {...props} transform={transform} onSuccess={onSuccess} onFailure={onFailure}>
      <SimpleForm>
        <TextField source="Create Document" />
        <TextInput
          source="documentType"
          label="Document Type"
          defaultValue={formatWord(type)}
          disabled
        />
        <TextInput
          source="title"
          label="File Title"
          validate={[required(), maxLength(50)]}
          multiline={true}
        />
        <TextInput
          source="description"
          label="File Description"
          validate={[required(), maxLength()]}
          multiline={true}
        />
        <FileInput
          source="fileLocation"
          options={{ label: "Location" }}
          validate={[required(), validateFileSize(10, FILE_SIZE_TYPES.MB)]}
          placeholder={"Please input a File less than 10 MB"}
          label={"Attachment"}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
