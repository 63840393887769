import * as React from "react";
import { RECORDS_PER_PAGE } from "../../Constants";
import { HomesList } from "..";

export const WarrantyList = (props) => {
  return (
    <HomesList
      title="Warranty Submissions"
      sort={{ field: "submittedDate", order: "DESC" }}
      perPage={RECORDS_PER_PAGE}
      basePath={`/userWarranty`}
      resource={`homes`}
    />
  );
};
