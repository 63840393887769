/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Loading, TopToolbar, useRedirect, usePermissions } from "react-admin";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Button,
  Toolbar,
} from "@material-ui/core";
import { BASE_URL, ADMIN_ROLES } from "../../Constants";
import instance from "../../Core/service";
import { CustomDeletePopUp } from "../../Components";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const useStyles = makeStyles((theme) => ({
  widthSmall: {
    width: "160px",
  },
  widthLarge: {
    width: "300px",
  },
}));
export const DesignCenterImagesList = (props) => {
  const classes = useStyles(props);
  const redirect = useRedirect();
  const id = props.id;
  const [data, setData] = React.useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [isDelete, setIsDelete] = React.useState(false);
  const { permissions } = usePermissions();
  const isSalesAdmin = permissions && permissions.roles.includes(ADMIN_ROLES.SALES);

  const fetchData = async () => {
    instance
      .get(
        `${BASE_URL}/homes/designCenter/${id}?_end=${page * 10}&_order=DESC&_start=${
          (page - 1) * 10
        }`
      )
      .then((response) => response.data)
      .then((data) => {
        setData(data.data);
        setTotalRecords(data.totalRecords);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (isDelete) {
      fetchData();
      setIsDelete(false);
    }
  }, [isDelete]);

  React.useEffect(() => {
    fetchData();
  }, [page, id]);

  return (
    <React.Fragment>
      <TopToolbar>
        {!isSalesAdmin && <Button color="primary" onClick={() => redirect(`/designCenter/${id}/create`)}>
          + Upload Image
        </Button>}
      </TopToolbar>
      {!loading ? (
        data.length > 0 ? (
          <React.Fragment>
            <Table className="post-table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>View</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Caption</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Action</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((listData) => (
                    <TableRow key={listData.id}>
                      <TableCell className={classes.widthSmall}>
                        <img src={listData.url} alt="Pic" width={"300px"} />
                      </TableCell>
                      <TableCell>{listData.caption}</TableCell>
                      <TableCell>
                        {!isSalesAdmin && <CustomDeletePopUp
                          selectedIds={[listData.id]}
                          resource={`homes/designCenter/${id}`}
                          setIsDelete={setIsDelete}
                        />}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Pagination data={data} page={page} setPage={setPage} totalRecords={totalRecords} />
          </React.Fragment>
        ) : (
          <Box textAlign="center" m={5}>
            <Typography variant="h6">No photos available</Typography>
          </Box>
        )
      ) : (
        <Loading />
      )}
    </React.Fragment>
  );
};

const Pagination = ({ data, page, setPage, totalRecords }) => {
  const lastPage = Math.ceil(totalRecords / 10);
  return (
    <Toolbar className="post-pagination">
      <Button
        variant="text"
        color="primary"
        key="prev"
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
      >
        <ChevronLeft />
        Prev
      </Button>
      <Button
        variant="text"
        color="primary"
        key="next"
        onClick={() => setPage(page + 1)}
        disabled={page === lastPage || data.length === 0}
      >
        Next
        <ChevronRight />
      </Button>
    </Toolbar>
  );
};
