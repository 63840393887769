import { TextField } from "@material-ui/core";
import { warrantyFormStyles } from "./Styles";

export const HomeInfo = (props) => {
  const classes = warrantyFormStyles(props);
  const { warrantyData, updateObject, errorMessageState } = props;
  const { errorMessages, setErrorMessages } = errorMessageState;

  return (
    <div className={classes.formContainer}>
      <div className={classes.formRow}>
        <div className={classes.formCol3}>
          <div className={classes.formLabel}>Date of Close:</div>
          <TextField
            defaultValue={warrantyData?.homeInfo?.closeDate}
            className={classes.formInput}
            onChange={(e) => updateObject("homeInfo.closeDate", e.target.value)}
          />
        </div>
        <div className={classes.formCol3}>
          <div className={classes.formLabel}>Date Received:</div>
          <TextField
            className={classes.formInput}
            defaultValue={warrantyData?.dateReceived}
            onChange={(e) => updateObject("dateReceived", e.target.value)}
          />
        </div>
        <div className={classes.formCol3}>
          <div className={classes.formLabel}>Appointment Date:</div>{" "}
          <TextField
            className={classes.formInput}
            defaultValue={warrantyData?.appointmentDate}
            onChange={(e) => updateObject("appointmentDate", e.target.value)}
          />
        </div>
      </div>

      <div className={classes.formRow}>
        <div className={classes.formCol2}>
          <div className={classes.formLabel}>HomeOwner:</div>
          <TextField
            className={classes.formInput}
            defaultValue={warrantyData?.buyerInfo?.owner}
            onChange={(e) => updateObject("buyerInfo.owner", e.target.value)}
          />
        </div>
        <div className={classes.formCol2}>
          <div className={classes.formLabel}>Community/Homes:</div>
          <TextField
            className={classes.formInput}
            defaultValue={warrantyData?.homeInfo?.community?.name}
            onChange={(e) => updateObject("homeInfo.community.name", e.target.value)}
          />
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formColFull}>
          <div className={classes.formLabel}>Home Address:</div>
          <TextField
            className={classes.formInput}
            defaultValue={warrantyData?.homeInfo?.address}
            onChange={(e) => {
              if (e.target.value.length > 35) {
                setErrorMessages({
                  ...errorMessages,
                  homeAddress: "Home address too long (max 35 characters).",
                });
              } else {
                setErrorMessages({
                  ...errorMessages,
                  homeAddress: "",
                });
                updateObject("homeInfo.address", e.target.value);
              }
            }}
          />
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formColFull}>
          <div className={classes.formLabel}>Model & Elevation:</div>
          <TextField
            defaultValue={warrantyData?.homeInfo?.modelElevation}
            onChange={(e) => {
              if (e.target.value.length > 48) {
                setErrorMessages({
                  ...errorMessages,
                  model: "Model & Elevation too long (max 48 characters).",
                });
              } else {
                setErrorMessages({
                  ...errorMessages,
                  model: "",
                });
                updateObject("homeInfo.modelElevation", e.target.value);
              }
            }}
            className={classes.formInput}
          />
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formCol2}>
          <div className={classes.formLabel}>Home Phone:</div>
          <TextField
            className={classes.formInput}
            defaultValue={convertPhoneNumber(warrantyData?.buyerInfo?.homePhone)}
            onChange={(e) => updateObject("buyerInfo.homePhone", e.target.value)}
          />
        </div>
        <div className={classes.formCol2}>
          <div className={classes.formLabel}>Cell Phone:</div>
          <TextField
            className={classes.formInput}
            defaultValue={convertPhoneNumber(warrantyData?.buyerInfo?.cellPhone)}
            onChange={(e) => updateObject("buyerInfo.cellPhone", e.target.value)}
          />
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formCol2}>
          <div className={classes.formLabel}>Work Phone:</div>
          <TextField
            className={classes.formInput}
            defaultValue={convertPhoneNumber(warrantyData?.buyerInfo?.workPhone)}
            onChange={(e) => updateObject("buyerInfo.workPhone", e.target.value)}
          />
        </div>
        <div className={classes.formCol2}>
          <div className={classes.formLabel}>Cell Phone:</div>
          <TextField
            className={classes.formInput}
            defaultValue={convertPhoneNumber(warrantyData?.buyerInfo?.coBuyerCellPhone)}
            onChange={(e) => updateObject("buyerInfo.coBuyerCellPhone", e.target.value)}
          />
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formCol2}>
          <div className={classes.formLabel}>Email:</div>
          <TextField
            className={classes.emailInput}
            defaultValue={warrantyData?.buyerInfo?.mailId}
            onChange={(e) => updateObject("buyerInfo.mailId", e.target.value)}
          />
        </div>
        <div className={classes.formCol2}>
          <div className={classes.formLabel}>Email:</div>
          <TextField
            className={classes.emailInput}
            defaultValue={warrantyData?.buyerInfo?.coBuyerMailId}
            onChange={(e) => updateObject("buyerInfo.coBuyerMailId", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

const convertPhoneNumber = (phoneNumber) => {
  const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

  if (phoneRegex.test(phoneNumber)) {
    const digitsOnly = phoneNumber.replace(/\D/g, ""); // Remove non-digit characters
    return `${digitsOnly.substr(0, 3)}-${digitsOnly.substr(3, 3)}-${digitsOnly.substr(6)}`;
  } else {
    return phoneNumber;
  }
};
