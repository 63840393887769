import * as React from "react";
import {
  Button,
  useNotify,
  useRedirect,
  TopToolbar,
  CreateButton,
  usePermissions,
} from "react-admin";
import { changeAreaName, getName, redirectToMessagesTab } from "../Utils";
import { BASE_URL, BUYERTYPES, ADMIN_ROLES } from "../Constants";
import instance from "../Core/service";
import { MyConversationsFilter } from "../Pages/Conversations/Filters";

export const ConversationActions = (props) => {
  const { label, basePath } = props;
  const { permissions } = usePermissions();
  return (
    <TopToolbar>
      {permissions && !permissions.roles.includes(ADMIN_ROLES.SALES) && (
        <MyConversationsFilter alwaysOn />
      )}
      {permissions && permissions.allowConversation && (
        <CreateButton basePath={basePath} label={label} />
      )}
    </TopToolbar>
  );
};

export const StartConversationButton = ({ homeId, buyer }) => {
  const [isLoadingCreateConversation, setIsLoadingCreateConversation] = React.useState(false);

  const notify = useNotify();
  const redirect = useRedirect();

  const handleRowClick = async (buyer) => {
    const postBody = { homeId, buyerId: buyer.id };
    setIsLoadingCreateConversation(true);
    instance
      .post(`${BASE_URL}/conversations`, postBody)
      .then((data) => {
        redirect("/conversations");
        return data.data;
      })
      .then((data) => {
        notify(data.message, "info");
        redirectToMessagesTab(data);
      })
      .catch((e) => {
        console.log(e);
        notify("Sorry could not create conversation, please try again!", "error");
      })
      .finally(() => {
        setIsLoadingCreateConversation(false);
      });
  };

  return (
    <Button
      label="Start"
      disabled={isLoadingCreateConversation || buyer.buyerType === BUYERTYPES.ADDITIONAL}
      onClick={() => handleRowClick(buyer)}
    />
  );
};

export const ConversationHomeData = ({ record }) => {
  return (
    <React.Fragment>
      <div>{record.name}</div>
      <div>{`${changeAreaName(record.id, record.communityName)} ${changeAreaName(
        record.id,
        record.divisionName
      )}`}</div>
      <div>{record.address}</div>
      <div>{record.zip}</div>
      {record.lotNumber ? <div>Lot: {record.lotNumber}</div> : null}
      {record.block ? <div>Block: {record.block}</div> : null}
    </React.Fragment>
  );
};

export const ShowMessagesButtonField = ({ record, setRecord }) => {
  return (
    <Button
      label={"Show Messages"}
      onClick={() => {
        setRecord(record);
        redirectToMessagesTab(record);
      }}
    />
  );
};

export const ConversationBuyerData = ({ buyerData }) => {
  return (
    <>
      {getName(buyerData) ? <div>{getName(buyerData)}</div> : null}
      {buyerData?.email ? <div>{buyerData.email}</div> : null}
      {buyerData?.mobilePhone ? <div>{buyerData.mobilePhone}</div> : null}
      {buyerData?.homePhone ? <div>{buyerData.homePhone}</div> : null}
    </>
  );
};
