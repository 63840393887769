import * as React from "react";
import {
  required,
  ArrayInput,
  minLength,
  maxLength,
  SimpleFormIterator,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import {
  validateHomeDocumentCreation,
  validateCommunityDocumentCreation,
  validateDivisionDocumentCreation,
  changeAreaName,
  checkForDuplicateValues,
} from "../Utils";
import { BASE_URL } from "../Constants/index";
import instance from "../Core/service";
export const CustomHomesListInput = ({ role }) => {
  return (
    <ArrayInput
      source="list"
      label="Homes"
      validate={[
        required(),
        minLength(1, "Enter atleast one home"),
        maxLength(5, "Enter atmost 5 homes"),
        validateHomeDocumentCreation,
      ]}
    >
      <SimpleFormIterator>
        <FormDataConsumer>
          {({
            formData,
            scopedFormData, // The data for this item of the ArrayInput
            getSource,
            ...rest
          }) => {
            return (
              <React.Fragment>
                <RefInput source={getSource(`divisionId`)} />
                {scopedFormData && scopedFormData.divisionId && (
                  <CustomCommunitiesInput
                    formData={formData}
                    scopedFormData={scopedFormData}
                    getSource={getSource}
                    role={role}
                  />
                )}
                {scopedFormData && scopedFormData.communityId && (
                  <CustomHomesInput
                    formData={formData}
                    scopedFormData={scopedFormData}
                    getSource={getSource}
                  />
                )}
              </React.Fragment>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const CustomDivisionsListInput = ({ role }) => {
  return (
    <ArrayInput
      source="list"
      label="Divisions"
      validate={[
        required(),
        minLength(1, "Enter atleast one division"),
        maxLength(5, "Enter atmost 5 divisions"),
        validateDivisionDocumentCreation,
      ]}
    >
      <SimpleFormIterator>
        <FormDataConsumer>
          {({
            formData,
            scopedFormData, // The data for this item of the ArrayInput
            getSource,
            ...rest
          }) => {
            return (
              <React.Fragment>
                <RefInput source={getSource(`divisionId`)} />
              </React.Fragment>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const CustomCommunitiesListInput = ({ role }) => {
  return (
    <ArrayInput
      source="list"
      label="Communities"
      validate={[
        required(),
        minLength(1, "Enter atleast one community"),
        maxLength(5, "Enter atmost 5 communities"),
        validateCommunityDocumentCreation,
      ]}
    >
      <SimpleFormIterator>
        <FormDataConsumer>
          {({
            formData,
            scopedFormData, // The data for this item of the ArrayInput
            getSource,
            ...rest
          }) => {
            return (
              <React.Fragment>
                <RefInput source={getSource(`divisionId`)} />
                {scopedFormData && scopedFormData.divisionId && (
                  <CustomCommunitiesInput
                    formData={formData}
                    scopedFormData={scopedFormData}
                    getSource={getSource}
                    role={role}
                  />
                )}
              </React.Fragment>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const RefInput = (props) => {
  const { source, label = "divisions", onChange = () => {} } = props;
  const optionRenderer = (choice) => choice && changeAreaName(choice.id, choice.name);
  return (
    <ReferenceInput
      onChange={onChange}
      validate={[required()]}
      source={source}
      reference="divisions"
      label={label}
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteInput optionText={optionRenderer} />
    </ReferenceInput>
  );
};

export const CustomCommunitiesInput = (customProps) => {
  const [choices, setChoices] = React.useState([]);
  const [inputValue] = React.useState("");
  const [initialValue, setInitialValue] = React.useState("");
  const { scopedFormData, getSource } = customProps;
  const [loading, setLoading] = React.useState(true);
  const { role } = customProps;
  React.useEffect(() => {
    return () => {
      setInitialValue("");
      setChoices([]);
    };
  }, [scopedFormData.divisionId]);
  React.useEffect(() => {
    const fetchCommunities = async () => {
      const response = await instance.get(`${BASE_URL}/divisions/${scopedFormData.divisionId}`);
      if (response.status === 200) {
        let choices = await response.data;
        if (choices.length === 0) {
          setInitialValue("No records");
        } else {
          let finalChoices = [];
          if (role === "rep") {
            const communities = await instance
              .get(`${BASE_URL}/communities`)
              .then((response) => response.data);

            for (let i = 0; i < choices.length; i++) {
              // eslint-disable-next-line
              communities.forEach((community) => {
                if (community.id === choices[i].id) {
                  finalChoices.push(choices[i]);
                  return;
                }
              });
            }
          } else {
            finalChoices = choices;
          }
          setChoices(finalChoices);
        }
      }
      setLoading(false);
    };
    fetchCommunities();
    return () => {
      setLoading(true);
      setInitialValue("");
      setChoices([]);
    };
  }, [scopedFormData.divisionId, inputValue, role]);
  const optionRenderer = (choice) => choice && changeAreaName(choice.id, choice.name);

  return (
    <React.Fragment>
      <AutocompleteInput
        validate={[required()]}
        source={getSource(`communityId`)}
        choices={choices}
        optionText={optionRenderer}
        options={{ label: "Community" }}
        loading={loading}
        inputValue={initialValue}
      />
    </React.Fragment>
  );
};

export const CustomHomesInput = (customProps) => {
  const [choices, setChoices] = React.useState([]);
  const { scopedFormData, getSource } = customProps;
  const [inputValue] = React.useState("");
  const [initialValue, setInitialValue] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    return () => {
      setInitialValue("");
      setChoices([]);
    };
  }, [scopedFormData.divisionId, scopedFormData.communityId]);

  React.useEffect(() => {
    const fetchHomes = async () => {
      const response = await instance.get(
        `${BASE_URL}/communities/communityHomes/${scopedFormData.communityId}`
      );
      if (response.status === 200) {
        if (response.data === 0) {
          setInitialValue("No records");
        } else {
          setChoices(response.data);
        }
      }
      setLoading(false);
    };
    fetchHomes();
    return () => {
      setLoading(true);
      setInitialValue("");
      setChoices([]);
    };
  }, [scopedFormData.communityId, inputValue]);
  return (
    <React.Fragment>
      <AutocompleteInput
        validate={[required()]}
        source={getSource(`homeId`)}
        choices={choices}
        options={{ label: "Home" }}
        disabled={choices.length === 0}
        loading={loading}
        optionText={(record) => (record ? `${record.name}, ${record.address}` : "")}
        inputValue={initialValue}
        helperText={initialValue}
      />
    </React.Fragment>
  );
};

export const CustomAdminsListInput = () => {
  return (
    <ArrayInput
      source="list"
      label="Admins"
      validate={[
        required(),
        minLength(1, "Enter atleast one division"),
        maxLength(5, "Enter atmost 5 divisions"),
        checkForDuplicateValues("id"),
      ]}
    >
      <SimpleFormIterator>
        <FormDataConsumer>
          {({
            formData,
            scopedFormData, // The data for this item of the ArrayInput
            getSource,
            ...rest
          }) => {
            return (
              <React.Fragment>
                <AdminRefInput source={getSource(`id`)} />
              </React.Fragment>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const AdminRefInput = (props) => {
  const { source } = props;
  const optionRenderer = (choice) =>
    choice && choice.id ? `${choice.firstName} ${choice.lastName}` : "";

  return (
    <ReferenceInput
      validate={[required()]}
      source={source}
      reference="admins"
      perPage={500}
      label="admins"
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteInput optionText={optionRenderer} />
    </ReferenceInput>
  );
};
