import { warrantyFormStyles } from "./Styles";

export const FormNoteSection = (props) => {
  const classes = warrantyFormStyles(props);
  const { warrantyData } = props;
  const is60DaysWarranty = warrantyData?.warrantyType === "60 days";
  const isNorthShore =
    warrantyData?.homeInfo?.division?.id === "MHW-8" ||
    warrantyData?.homeInfo?.division?.id === "22";
  return (
    <>
      <div className={classes.doubleBorderDesc}>
        {isNorthShore ? "North Shore Builders Response Key:" : "William Ryan Homes Response Key:"}
        <ol className={classes.orderList}>
          {is60DaysWarranty ? (
            <li>
              Limited Warranty Item. This item to be completed within 30 days as part of the "Tune
              -Up" program
            </li>
          ) : null}
          <li>
            Limited Warranty Item. This item to be corrected as part of final 11-month review.
          </li>
          <li>
            {is60DaysWarranty ? "The item listed" : "This item"} is not covered under the warranty
            policy.
          </li>
        </ol>
      </div>
      <p>All legitimate warranty items listed above have been completed.</p>
    </>
  );
};
