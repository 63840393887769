import React, { useEffect } from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ArrayField,
  Datagrid,
  FunctionField,
  Button,
} from "react-admin";
import { getName, downloadData, getClosedDate, getFullDate } from "../../Utils";
import CloudDownload from "@material-ui/icons/CloudDownload";
import instance from "../../Core/service";
import { BASE_URL, LOCAL_STORAGE_KEYS } from "../../Constants";
import BackButton from "../../Components/BackButton";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonRight: {
    textAlign: "right",
  },
}));
export const UserWarrantyShow = (props) => {
  const classes = useStyles(props);
  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.SUBMITTED_WARRANTY);
  }, []);
  return (
    <Show {...props} title={"Show"}>
      <SimpleShowLayout>
        <BackButton className={classes.buttonRight}>GO BACK</BackButton>
        <TextField source="type" />
        <FunctionField
          label="Buyer Name"
          render={(record) => record.submittedBy && getName(record.submittedBy)}
        />
        {/* <DateField source="submittedDate" /> */}
        <FunctionField
          label="Submitted Date"
          render={(record) => {
            return <div>{getFullDate(record?.submittedDate)}</div>;
          }}
        />
        <TextField source="home.planNumber" label="Plan Number" />
        <TextField source="home.planDescription" label="Plan Description" />
        <TextField source="home.divisionName" label="Division" />
        <TextField source="home.communityName" label="Community" />
        <TextField source="home.lotNumber" label="Lot Number" />
        <FunctionField
          label="Closed Date"
          render={(record) => {
            return <div>{getClosedDate(record?.home?.closedDate)}</div>;
          }}
        />
        <TextField source="home.address" label="Address" />
        <ArrayField source="issues">
          <Datagrid>
            <TextField source="issueTitle" />
            <TextField source="issueDescription" />
            <DateField source="createdAt" />
            <FunctionField
              label="Download Attachments"
              render={(record) => {
                return (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={async () => {
                      await handleDownloadFiles(record);
                    }}
                  >
                    <CloudDownload />
                  </Button>
                );
              }}
            />
            <FunctionField
              label="Number of attachments"
              render={(record) => {
                return <div>{record.issueData.length}</div>;
              }}
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

const handleDownloadFiles = async (record) => {
  const { issueData } = record;
  const errors = [];
  await Promise.all(
    issueData.map(async (issue) => {
      const response = await instance.get(
        `${BASE_URL}/getDownloadUrl?fileName=${issue.fileLocation}`
      );
      if (response.status === 200) {
        downloadData({ url: response.data[0], type: issue.type, label: issue.name });
      } else {
        errors.push("Error while fetching document");
      }
    })
  );
};
