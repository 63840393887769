import * as React from "react";
import {
  ShowButton,
  useRedirect,
  useQuery,
  useNotify,
  useRefresh,
  usePermissions,
} from "react-admin";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { getName, getFullDate, redirectToEditWarrantyTab } from "../../Utils";
import { Button, Checkbox } from "@material-ui/core";
import instance from "../../Core/service";
import { ADMIN_ROLES, BASE_URL, WARRANTY_TYPES } from "../../Constants";
import { useState } from "react";
import { ConfirmationPopup, CustomDeletePopUp } from "../../Components";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  iconSize: { fontSize: "20px" },
  labelCheckbox: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
  },
  disabledCheckBox: {
    color: "#00000042",
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
  },
}));
export const WarrantyExpand = (props) => {
  const { record, id } = props;
  const {
    data,
    loading: isLoading,
    error: isError,
  } = useQuery({
    type: "getOne",
    resource: "userWarranty/homes",
    payload: { id },
  });

  const tableHeadings = ["Warranty Type", "Submmited Date", "Buyer Name", "Buyer Type", "Action"];
  return (
    <Card>
      <CardContent>
        {isLoading ? (
          <Typography variant="body2">Loading ...</Typography>
        ) : isError ? (
          <Typography variant="body2">Something went wrong ...</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {tableHeadings.map((heading, index) => {
                  return (
                    <TableCell key={index}>
                      <strong>{heading}</strong>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <WarrantyDetails
                type={WARRANTY_TYPES.SIXTY_DAYS}
                record={record}
                issue={
                  data.warranties.find((issue) => issue.type === WARRANTY_TYPES.SIXTY_DAYS) ?? null
                }
              />
              <WarrantyDetails
                type={WARRANTY_TYPES.ELEVEN_MONTHS}
                record={record}
                issue={
                  data.warranties.find((issue) => issue.type === WARRANTY_TYPES.ELEVEN_MONTHS) ??
                  null
                }
              />
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};

const WarrantyDetails = ({ type, issue, record }) => {
  const redirect = useRedirect();
  const newRecord = { record, id: issue?.id };
  const [isOpen, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const refresh = useRefresh();
  const isSixtyDaysByPassed = record?.isWarrantyByPassed?.sixtyDay ?? false;
  const isElevenMonthsByPassed = record?.isWarrantyByPassed?.elevenMonth ?? false;
  const isByPassed =
    type === WARRANTY_TYPES.SIXTY_DAYS ? isSixtyDaysByPassed : isElevenMonthsByPassed;
  const classes = useStyles();

  const notify = useNotify();
  const handleConfirm = async () => {
    const response = await instance.put(`${BASE_URL}/homes/warrantyBypass/${record.id}`, {
      isWarrantyByPassed: {
        sixtyDay: type === WARRANTY_TYPES.SIXTY_DAYS ? !isByPassed : isSixtyDaysByPassed,
        elevenMonth: type === WARRANTY_TYPES.ELEVEN_MONTHS ? !isByPassed : isElevenMonthsByPassed,
      },
    });
    if (response.status === 200) {
      refresh();
      setOpen(false);
      notify("Warranty Bypassed", "success");
    } else {
      notify("Error", "error");
    }
  };

  const { permissions } = usePermissions();
  const isCorpAdmin = permissions && permissions.roles.includes(ADMIN_ROLES.CORPORATE);
  const isOfficeAdmin = permissions && permissions.roles.includes(ADMIN_ROLES.OFFICE);

  return (
    <>
      <TableRow key={type}>
        <TableCell>{type}</TableCell>
        {issue ? (
          <>
            <TableCell>{getFullDate(issue.submittedDate)}</TableCell>
            <TableCell>{issue.submittedBy && getName(issue.submittedBy)}</TableCell>
            <TableCell>{issue.submittedBy && issue.submittedBy.type}</TableCell>
            <TableCell>
              <ShowWarrantyActions
                label="Action"
                onClick={() => redirect(`${issue.id}/show`)}
                record={newRecord}
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell> - </TableCell>
            <TableCell> - </TableCell>
            <TableCell> - </TableCell>
            <TableCell>
              <div
                className={
                  isCorpAdmin || isOfficeAdmin
                    ? classes.labelCheckbox
                    : `${classes.labelCheckbox} ${classes.disabledCheckBox}`
                }
              >
                <Checkbox
                  color="default"
                  style={{ color: isCorpAdmin ? "#3f51b5" : "" }}
                  checked={isByPassed}
                  onClick={handleClick}
                  disabled={!(isCorpAdmin || isOfficeAdmin)}
                />
                {isByPassed ? "Remove Extension" : "Extend Warranty"}
              </div>
            </TableCell>
          </>
        )}
      </TableRow>
      {isOpen ? (
        <ConfirmationPopup handleConfirm={handleConfirm} isOpen={isOpen} setOpen={setOpen} />
      ) : null}
    </>
  );
};

const ShowWarrantyActions = ({ record }) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const isSalesAdmin = permissions && permissions.roles.includes(ADMIN_ROLES.SALES);

  return (
    <div className={classes.actionButtons}>
      <ShowButton label="" record={record} />
      {!isSalesAdmin && <EditWarrantyButton record={record} />}
      {!isSalesAdmin && <CustomDeletePopUp
        selectedIds={[record.id]}
        resource={`userWarranty`}
        isDocumentPage={true}
      />}
    </div>
  );
};

export const EditWarrantyButton = ({ record }) => {
  const classes = useStyles();

  return (
    <Button
      onClick={() => {
        redirectToEditWarrantyTab(record.id);
      }}
    >
      <EditIcon color="primary" className={classes.iconSize} />
    </Button>
  );
};
