import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { BASE_URL, CHOOSE_ADMIN_ROLE } from "../../Constants";
import instance from "../../Core/service";
import { CustomDeletePopUp } from "../../Components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  subTableHeader: {
    background: "rgb(117 117 117 / 15%)",
    color: "#000",
    margin: "-16px -16px 10px -16px",
    padding: "10px 16px",
    textTransform: "capitalize",
    fontWeight: "600",
    fontSize: "14px",
  },
}));

export const DivisionMapperExpand = (props) => {
  const classes = useStyles();
  const { id: divisionId } = props;
  const { basePath } = props;
  const basePathName = basePath.split("/")[1];
  const [admins, setAdmins] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  React.useEffect(() => {
    const getDivisionMapperAdmins = async (id) => {
      setIsLoading(true);
      instance
        .get(`${BASE_URL}/${basePathName}/${id}`)
        .then((response) => {
          setAdmins(response.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsError(true);
          setIsLoading(false);
        });
    };
    getDivisionMapperAdmins(divisionId);
  }, [divisionId, basePathName]);

  const tableHeadings = ["First Name", "Last Name", "Email", "Role", "Designation", "Action"];
  const columns = ["firstName", "lastName", "email", "roles", "designation"];

  return (
    <Card>
      <CardContent>
        {isLoading ? (
          <Typography variant="body2">Loading ...</Typography>
        ) : isError ? (
          <Typography variant="body2">Something went wrong ...</Typography>
        ) : admins && admins.length ? (
          <>
            <div className={classes.subTableHeader}>Warranty Document Mapping</div>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeadings.map((heading, index) => {
                    return (
                      <TableCell key={index}>
                        <strong>{heading}</strong>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {admins &&
                  admins.map((admin) => (
                    <TableRow key={admin.id}>
                      {columns.map((col, index) => {
                        if (col === "roles") {
                          return (
                            <TableCell key={index}>
                              {CHOOSE_ADMIN_ROLE.find((adminRole) => adminRole.id === admin[col][0])
                                ?.name ?? ""}
                            </TableCell>
                          );
                        } else {
                          return <TableCell key={index}>{admin[col]}</TableCell>;
                        }
                      })}
                      <TableCell>
                        <CustomDeletePopUp
                          selectedIds={[admin.id]}
                          resource={`${basePathName}/${divisionId}`}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </>
        ) : (
          "No mapped admins"
        )}
      </CardContent>
    </Card>
  );
};
