import { warrantyFormStyles } from "./Styles";
import { TableHead } from "./TableHead";
import { TextField } from "@material-ui/core";

export const DomTable = (props) => {
  const classes = warrantyFormStyles(props);
  const { warrantyData, updateObject } = props;
  const isNorthShore =
    warrantyData?.homeInfo?.division?.id === "MHW-8" ||
    warrantyData?.homeInfo?.division?.id === "22";

  return (
    <table border="1" className={classes.tableFormInput}>
      <TableHead isNorthShore={isNorthShore} />
      <tbody>
        {warrantyData?.warrantyItems?.map((item, index) => {
          return (
            <tr key={index}>
              <td className="table-s-no">{index + 1}.</td>
              <td
                className="table-title"
                onClick={() => {
                  document.getElementById(`text-area-title-${index}`).focus();
                }}
              >
                <TextField
                  id={`text-area-title-${index}`}
                  defaultValue={item?.itemTitle}
                  multiline={true}
                  maxRows={1000}
                  onChange={(e) => updateObject(`warrantyItems.${index}.itemTitle`, e.target.value)}
                  className={`${classes.tableInput} textarea-front`}
                />
              </td>
              <td
                className="table-item"
                onClick={() => {
                  document.getElementById(`text-area-item-${index}`).focus();
                }}
              >
                <TextField
                  id={`text-area-item-${index}`}
                  defaultValue={item?.itemDescription}
                  multiline={true}
                  maxRows={1000}
                  onChange={(e) =>
                    updateObject(`warrantyItems.${index}.itemDescription`, e.target.value)
                  }
                  className={`${classes.tableInput} textarea-front`}
                />
              </td>
              <td
                className="table-response"
                onClick={() => {
                  document.getElementById(`text-area-wrh-${index}`).focus();
                }}
              >
                <TextField
                  id={`text-area-wrh-${index}`}
                  defaultValue={item?.wrhResponse}
                  multiline={true}
                  maxRows={1000}
                  onChange={(e) =>
                    updateObject(`warrantyItems.${index}.wrhResponse`, e.target.value)
                  }
                  className={`${classes.tableInput}`}
                />
              </td>
              <td
                className="table-trade"
                onClick={() => {
                  document.getElementById(`text-area-trade-${index}`).focus();
                }}
              >
                <TextField
                  id={`text-area-trade-${index}`}
                  multiline={true}
                  maxRows={1000}
                  defaultValue={item?.wrhTrade}
                  onChange={(e) => updateObject(`warrantyItems.${index}.wrhTrade`, e.target.value)}
                  className={`${classes.tableInput}`}
                />
              </td>
              <td
                className="table-status"
                onClick={() => {
                  document.getElementById(`text-area-status-${index}`).focus();
                }}
              >
                <TextField
                  id={`text-area-status-${index}`}
                  multiline={true}
                  maxRows={1000}
                  defaultValue={item?.completeInitial}
                  onChange={(e) =>
                    updateObject(`warrantyItems.${index}.completeInitial`, e.target.value)
                  }
                  className={`${classes.tableInput} `}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
