import { db } from "../firebaseConfig";

export const updateConversationMessageDateTime = async (conversationId, dateTime) => {
  const prevConversation = db.doc(`conversations/${conversationId}`);
  const conversationRef = await prevConversation.get();
  const conversationData = conversationRef.data();
  let updatedConversation = {
    ...conversationData,
    lastMessageDateTime: dateTime,
  };
  if (conversationData.firstMessageDateTime === "") {
    updatedConversation = { ...updatedConversation, firstMessageDateTime: dateTime };
  }
  await prevConversation.update(updatedConversation);
};

export const setMessage = async (messageObj, conversationId) => {
  const date = messageObj.dateTime.split("T")[0];
  const messageDocumentRef = db.doc(`conversations/${conversationId}/messages/${date}`);
  const messageDoc = await messageDocumentRef.get();
  if (messageDoc.exists) {
    const messageFromFirestore = messageDoc.data();
    messageFromFirestore.messages.push(messageObj);
    const messageFirestoreDocument = {
      ...messageFromFirestore,
      count: messageFromFirestore.count + 1,
    };
    await messageDocumentRef.update(messageFirestoreDocument);
  } else {
    const messageFirestoreDocument = {
      messages: [messageObj],
      createdAt: messageObj.dateTime,
      count: 1,
    };
    await messageDocumentRef.set(messageFirestoreDocument);
  }
};
