import * as React from "react";
import { ImageField, RichTextField } from "react-admin";
import { CustomEditButton } from "../../Components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import { getFullDate, getName } from "../../Utils";

const useStyles = makeStyles((theme) => ({
  widthCell: {
    width: "80px",
    padding: "16px 10px",
  },
}));

export const CheckListExpand = (props) => {
  const { record } = props;
  const { data } = record;
  const classes = useStyles(props);
  return (
    <Card>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Checklist Name</strong>
              </TableCell>
              <TableCell>
                <strong>Image</strong>
              </TableCell>
              <TableCell>
                <strong>Title</strong>
              </TableCell>
              <TableCell>
                <strong>Description</strong>
              </TableCell>
              <TableCell className={classes.widthCell}>
                <strong>Updated By</strong>
              </TableCell>
              <TableCell className={classes.widthCell}>
                <strong>Updated At</strong>
              </TableCell>
              <TableCell>
                <strong>Action</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((record) => (
                <TableRow key={record.id}>
                  <TableCell>{record.checkListName}</TableCell>
                  <TableCell>
                    <Image label="Image" record={record} className={"homeimg-wrapper"} />
                  </TableCell>
                  <TableCell>
                    {record.checkListTitle ? record.checkListTitle : "Not added yet"}
                  </TableCell>
                  <TableCell>
                    <Description label="Description" record={record} />
                  </TableCell>
                  <TableCell className={classes.widthCell}>
                    {record.updatedBy && record.updatedBy.firstName
                      ? getName(record.updatedBy)
                      : "Not updated yet"}
                  </TableCell>
                  <TableCell className={classes.widthCell}>
                    {record.updatedAt ? getFullDate(record.updatedAt) : "Not updated yet"}
                  </TableCell>
                  <TableCell>
                    <CustomEditButton
                      props={props}
                      record={record}
                      basePath="/timeline"
                      label="Action"
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

const Image = ({ record, className }) => {
  return record.checkListImage ? (
    <ImageField record={record} source="checkListImage" className={className} />
  ) : (
    <div>Not added yet</div>
  );
};

const Description = ({ record }) => {
  return record.checkListDescription ? (
    <RichTextField record={record} source="checkListDescription" />
  ) : (
    <div>Not added yet</div>
  );
};
