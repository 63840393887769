import * as React from "react";
import {
  TextField,
  Edit,
  SimpleForm,
  required,
  useNotify,
  useRedirect,
  ImageInput,
} from "react-admin";
import { PreviewImage, WriteToolbarWithoutValidation } from "../../Components";
import { validateFileAndCount } from "../../Utils";

export const HomeElevationURLEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify("Your elevation has been updated", "success");
    redirect("/homes");
  };

  const onFailure = (error) => {
    notify(error.body.error, "error");
  };
  const transform = (data) => {
    return { file: data.elevationURL };
  };
  return (
    <Edit
      {...props}
      mutationMode="optimistic"
      onSuccess={onSuccess}
      onFailure={onFailure}
      transform={transform}
      title={"Upload Elevation "}
    >
      <SimpleForm toolbar={<WriteToolbarWithoutValidation />}>
        <TextField source="Upload Elevation" />
        <ImageInput
          validate={[required(), async (image) => await validateFileAndCount([image])]}
          accept="image/png, image/jpeg,image/jpg"
          helperText="For better user experience, we recommend uploading an elevation image with dimensions 360 * 216 (5:3) or above"
          source="elevationURL"
          label="Elevation"
          placeholder={<p className="upload-link">Click here to upload an Image</p>}
        >
          <PreviewImage source="elevationURL" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};
