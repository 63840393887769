import * as React from "react";
import {
  Datagrid,
  TextField,
  List,
  FunctionField,
  Show,
  SimpleShowLayout,
  RichTextField,
} from "react-admin";
import { CustomCreateButton, CustomEditButton, PostPagination } from "../../Components";
import { ADMIN_ROLES, RECORDS_PER_PAGE, TYPE } from "../../Constants";
import { changeAreaName, getFullDate, getName } from "../../Utils";

export const AnnouncementList = (props) => {
  const { basePath, permissions } = props;
  const isNotSalesAdmin = permissions && !permissions.roles.includes(ADMIN_ROLES.SALES);
  let label;
  if (basePath.includes(TYPE.COMMUNITIES)) {
    label = "Community Name";
  } else {
    label = "Division Name";
  }

  return (
    <List
      {...props}
      sort={{ field: "createdAt", order: "desc" }}
      bulkActionButtons={false}
      actions={
        isNotSalesAdmin && <CustomCreateButton label="Create Announcement" basePath={basePath} />
      }
      pagination={<PostPagination />}
      perPage={RECORDS_PER_PAGE}
    >
      <Datagrid expand={DescriptionShow}>
        <FunctionField
          label={label}
          render={(record) => {
            return (
              <p>
                {basePath.includes(TYPE.COMMUNITIES)
                  ? changeAreaName(record?.community?.id, record?.community?.name)
                  : changeAreaName(record?.division?.id, record?.division?.name)}
              </p>
            );
          }}
        />
        <TextField source="title" sortable={false} />
        <FunctionField
          label="Created By"
          render={(record) => record.createdBy && getName(record.createdBy)}
        />
        <FunctionField
          label="Created On"
          render={(record) => {
            return record.createdAt && getFullDate(record.createdAt);
          }}
        />
        <FunctionField
          label="Updated By"
          render={(record) => record.updatedBy && getName(record.updatedBy)}
        />
        <FunctionField
          label="Updated On"
          render={(record) => {
            return record.updatedAt && getFullDate(record.updatedAt);
          }}
        />
        {isNotSalesAdmin && <CustomEditButton basePath={basePath} label="Action" />}
      </Datagrid>
    </List>
  );
};

const DescriptionShow = (props) => (
  <Show {...props} actions={false} title=" ">
    <SimpleShowLayout>
      <RichTextField source="description" />
    </SimpleShowLayout>
  </Show>
);
