import * as React from "react";
import {
  Create,
  SimpleForm,
  TextField,
  required,
  useNotify,
  useRedirect,
  ImageInput,
} from "react-admin";
import { PreviewImage } from "../../../Components";
import { validateFileAndCount } from "../../../Utils";

export const WarrantyImagesUpload = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("Images uploaded", "success");
    redirect(`/assets/warranty`);
  };
  const onFailure = () => {
    notify("Try again", "error");
  };

  return (
    <Create
      onSuccess={onSuccess}
      onFailure={onFailure}
      {...props}
      title={"Upload Warranty Form Snapshot"}
    >
      <SimpleForm>
        <TextField source="Upload images" />
        <ImageInput
          validate={[required(), async (image) => await validateFileAndCount([image])]}
          accept="image/png, image/jpeg,image/jpg"
          helperText="For better user experience, we recommend uploading an warranty sample image with dimensions 360 * 216 (5:3) or above"
          source="image"
          label="Image"
          placeholder={<p className="upload-link">Click here to upload an Image</p>}
        >
          <PreviewImage />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
