import { Toolbar, SaveButton } from "react-admin";
export const WriteToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton redirect="list" disabled={props.invalid || props.changed} />
    </Toolbar>
  );
};

export const WriteToolbarWithoutValidation = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton redirect="list" />
    </Toolbar>
  );
};
