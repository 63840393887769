import * as React from "react";
import { Datagrid, TextField, List } from "react-admin";
import { CustomEditButton, PostPagination } from "../../Components";
import { RECORDS_PER_PAGE } from "../../Constants";

export const SettingList = (props) => {
  return (
    <List
      {...props}
      sort={{ field: "field", order: "ASC" }}
      actions={false}
      bulkActionButtons={false}
      perPage={RECORDS_PER_PAGE}
      pagination={<PostPagination />}
    >
      <Datagrid>
        <TextField source="field" sortable={false} />
        <TextField source="value" sortable={false} />
        <CustomEditButton basePath="/settings" label="Action" />
      </Datagrid>
    </List>
  );
};
