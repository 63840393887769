// LoginPage.js
import React, { useEffect } from "react";
import { Login } from "react-admin";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";
import ForgotPasswordButton from "./ForgotPassword";
import "../../assets/css/theme.css";
import { makeStyles } from "@material-ui/core";
import LoginForm from "./LoginForm";
import { WilliamRyanIcon } from "../../assets/icons/WilliamRyanLogo";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "#/",
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.EmailAuthProvider],
};

const SignInScreen = () => (
  <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
);
const useStyles = makeStyles((theme) => ({
  logoPadding: {
    padding: "0 16px",
  },
}));
const CustomLoginForm = (props) => {
  const { location } = props;
  const { search } = location;
  useEffect(() => {
    if (search.length) {
      const warrantyDocId = search?.split("?wdId=")[1];
      if (warrantyDocId) localStorage.setItem("submittedWarrantyDocId", warrantyDocId);
    }
  }, [search]);

  const classes = useStyles(props);
  return (
    <div>
      <div className={classes.logoPadding}>
        <WilliamRyanIcon />
      </div>
      <LoginForm {...props} />
      <ForgotPasswordButton {...props} />
      <SignInScreen />
    </div>
  );
};

export const CustomLoginPage = (props) => (
  <Login {...props} className="admin-login">
    <CustomLoginForm {...props} />
  </Login>
);
