import * as React from "react";
import { List, Datagrid, TextField, TextInput, EditButton } from "react-admin";
import { PostPagination, CustomSeePostsButton } from "../../Components";
import { RECORDS_PER_PAGE, TYPE } from "../../Constants";
import { DivisionExpand } from "./ListExpand";

export const DivisionList = (props) => {
  const { actions } = props;
  const isPostDivisionPage = window.location.href.search(TYPE.DIVISIONS) !== -1;
  const isEnvisionPage = window.location.href.search("envision") !== -1;
  const filters = [<TextInput label="Search" source="q" placeholder="Name" alwaysOn />];
  return (
    <div>
      <List
        {...props}
        sort={{ field: "name", order: "ASC" }}
        actions={actions ? actions : false}
        bulkActionButtons={false}
        perPage={RECORDS_PER_PAGE}
        pagination={<PostPagination />}
        filters={filters}
      >
        <Datagrid expand={!isEnvisionPage ? DivisionExpand : undefined}>
          <TextField source="id" label="Division Id" sortable={false} />
          <TextField source="name" label="Division Name" sortable={false} />
          <TextField source="abbr" label="Division Abbreviation" sortable={false} />
          {actions && isPostDivisionPage && (
            <CustomSeePostsButton type={TYPE.DIVISIONS} label="Action" />
          )}
          {isEnvisionPage && <TextField source="envisionUrl" label="Envision url" sortable={false} />}
          {isEnvisionPage && <EditButton label="Edit Url" basePath="/envision"/>}
        </Datagrid>
      </List>
    </div>
  );
};
