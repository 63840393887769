import * as React from "react";
import {
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { WriteToolbarWithoutValidation } from "../../Components";
import { maxLength } from "../../Utils";

export const AnnouncementEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [index] = React.useState(props.basePath.lastIndexOf("/"));
  const [type] = React.useState(props.basePath.substring(index + 1));
  const onSuccess = () => {
    notify("Your changes has been updated", "success");
    redirect(`/announcementsAdmin/${type}`);
  };

  const onFailure = (error) => {
    notify(error.body.error, "error");
  };

  return (
    <Edit
      {...props}
      mutationMode="optimistic"
      onSuccess={onSuccess}
      onFailure={onFailure}
      title={"Update Annoucement"}
    >
      <SimpleForm toolbar={<WriteToolbarWithoutValidation />}>
        <TextField source="Edit Announcement" />
        <TextInput source="announcementType" label="Announcement Type" disabled />
        <TextInput
          source="title"
          label=" Announcement Title"
          validate={[required(), maxLength(40)]}
          multiline={true}
        />
        <RichTextInput
          source="description"
          toolbar={[["bold", "italic", "underline", "link"]]}
          label=" Announcement Description"
          fullWidth={false}
          validate={[required(), maxLength()]}
          multiline={true}
        />
      </SimpleForm>
    </Edit>
  );
};
