import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  makeStyles
} from "@material-ui/core";
import { useLogout } from "react-admin";
const useStyles = makeStyles(
  (theme) => ({
    footer: {
      justifyContent: "center",
    }
  })
);
export const LogoutConfirmationPopup = ({ popupState }) => {
  const classes = useStyles();
  const { isConfirmationOpen, setIsConfirmationOpen } = popupState;
  const logout = useLogout();

  const handleClose = () => {
    setIsConfirmationOpen(false);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  return (
    <Dialog
      open={isConfirmationOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"You are about to log out"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Are you sure you want to log out?
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button onClick={handleClose} variant="contained" color="secondary">CANCEL</Button>
        <Button onClick={handleLogout} variant="outlined">LOG OUT</Button>
      </DialogActions>
    </Dialog>
  );
};
