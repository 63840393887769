export const AdminIcon = ({ activeColor }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={activeColor} 
        d="M6.18833 20.855C7.37606 19.6673 8.98697 19 10.6667 19H21.3333C23.0131 19 24.624 19.6673 25.8117 20.855C26.9994 22.0427 27.6667 23.6536 27.6667 25.3333V28C27.6667 28.5523 27.219 29 26.6667 29C26.1144 29 25.6667 28.5523 25.6667 28V25.3333C25.6667 24.1841 25.2101 23.0819 24.3975 22.2692C23.5848 21.4565 22.4826 21 21.3333 21H10.6667C9.51741 21 8.41521 21.4565 7.60255 22.2692C6.78989 23.0819 6.33334 24.1841 6.33334 25.3333V28C6.33334 28.5523 5.88563 29 5.33334 29C4.78106 29 4.33334 28.5523 4.33334 28V25.3333C4.33334 23.6536 5.0006 22.0427 6.18833 20.855Z"
      />
      <path
        fill={activeColor}
        d="M16 5C13.6068 5 11.6667 6.9401 11.6667 9.33333C11.6667 11.7266 13.6068 13.6667 16 13.6667C18.3932 13.6667 20.3333 11.7266 20.3333 9.33333C20.3333 6.9401 18.3932 5 16 5ZM9.66666 9.33333C9.66666 5.83553 12.5022 3 16 3C19.4978 3 22.3333 5.83553 22.3333 9.33333C22.3333 12.8311 19.4978 15.6667 16 15.6667C12.5022 15.6667 9.66666 12.8311 9.66666 9.33333Z"
      />
    </svg>
  );
};
