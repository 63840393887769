import instance from "../../../Core/service";
import { BASE_URL } from "../../../Constants";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { warrantyFormStyles } from "./Styles";
import { HomeInfo } from "./HomeInfo";
import { SignatureSection } from "./SignatureSection";
import { FormNoteSection } from "./FormNoteSection";
import { FormActions } from "./FormActions";
import { PrintTable } from "./PrintTable";
import { DomTable } from "./DomTable";

const marginTop = "20px";
const marginRight = "40px";
const marginBottom = "30px";
const marginLeft = "40px";

const getPageMargins = () => {
  return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
};

const updateObjectByKeyValue = (object, key, value) => {
  const updatedObject = { ...object };

  const keys = key.split(".");
  let currentObj = updatedObject;

  for (let i = 0; i < keys.length - 1; i++) {
    const currentKey = keys[i];
    if (currentObj[currentKey] === undefined) {
      currentObj[currentKey] = {};
    }
    currentObj = currentObj[currentKey];
  }

  const lastKey = keys[keys.length - 1];
  if (Array.isArray(currentObj[lastKey])) {
    const index = parseInt(lastKey, 10);
    currentObj[lastKey] = [...currentObj[lastKey]];
    currentObj[lastKey][index] = value;
  } else {
    currentObj[lastKey] = value;
  }

  return updatedObject;
};

export const UserWarranty = (props) => {
  const classes = warrantyFormStyles(props);
  const { warrantyData } = props;
  const [warrantyDataObject, setWarrantyDataObject] = useState(warrantyData);
  const [responseMessage, setResponseMessage] = useState("");
  const [isDraftSaved, setIsDraftSaved] = useState(false);
  const [isPDFSaved, setIsPDFSaved] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});

  const saveDraft = async () => {
    setIsDraftSaved(true);
    const response = await instance.post(`${BASE_URL}/userWarranty/draft`, warrantyDataObject);
    if (response.status === 200) {
      setWarrantyDataObject(response.data);
      setResponseMessage("Draft saved successfully", "success");
    } else {
      setResponseMessage("Sorry could not save draft, please contact your admin", "error");
    }
    setIsDraftSaved(false);
  };

  useEffect(() => {
    if (responseMessage) {
      setTimeout(() => setResponseMessage(""), 2000);
    }
  }, [responseMessage, setResponseMessage]);

  const updateObject = (key, value) => {
    setWarrantyDataObject(updateObjectByKeyValue(warrantyDataObject, key, value));
  };

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const is60DaysWarranty = warrantyData?.warrantyType === "60 days";
  const isNorthShore =
    warrantyData?.homeInfo?.division?.id === "MHW-8" ||
    warrantyData?.homeInfo?.division?.id === "22";
  return (
    <div>
      <div className={classes.containerForm}>
        <form id="pdf" ref={componentRef}>
          <style>{getPageMargins()}</style>
          <div className={classes.headerSection}>
            {isNorthShore ? (
              <img
                alt="North shore builders"
                src="https://lirp.cdn-website.com/fde52370/dms3rep/multi/opt/northshore+copy-313w.png"
                className={classes.logo}
              />
            ) : (
              <img
                alt="North shore builders"
                src="https://lirp.cdn-website.com/f54dccf6/dms3rep/multi/opt/Logo-240w.png"
                className={classes.logo}
              />
            )}
            <h1>{is60DaysWarranty ? `60 day "Tune Up"` : "11 month"} Warranty Request</h1>
          </div>
          <HomeInfo
            errorMessageState={{ errorMessages, setErrorMessages }}
            warrantyData={warrantyData}
            updateObject={updateObject}
          />
          <p className={classes.descTest1}>
            Please list below any warranty requests that you would like consider for correction
            under our {is60DaysWarranty ? `60 day "Tune Up"` : "11 month"} warranty.
          </p>
          <div className="print-table">
            <PrintTable warrantyData={warrantyData} updateObject={updateObject} />
          </div>
          <div className="form-table">
            <DomTable warrantyData={warrantyData} updateObject={updateObject} />
          </div>
          <FormNoteSection warrantyData={warrantyData} />
          <SignatureSection />
        </form>
      </div>
      <FormActions
        fileName={`${warrantyData?.homeInfo?.id}-${warrantyData?.buyerInfo?.owner}-${warrantyData?.warrantyType}`}
        saveDraft={saveDraft}
        loadingStates={{
          draft: { isDraftSaved, setIsDraftSaved },
          pdf: { isPDFSaved, setIsPDFSaved },
        }}
        responseMessage={responseMessage}
        errorMessages={errorMessages}
        handlePrint={handlePrint}
      />
    </div>
  );
};
