import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  TextField,
  required,
  useNotify,
  useRedirect,
  ImageInput,
} from "react-admin";
import { TYPE } from "../../Constants";
import {
  PreviewImage,
  CustomDivisionsListInput,
  CustomHomesListInput,
  CustomCommunitiesListInput,
} from "../../Components";
import { formatWord, validateFileAndCount, maxLength } from "../../Utils";
import { TypeMapping } from "../Documents/Edit";

export const PostsCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [index] = React.useState(props.basePath.lastIndexOf("/"));
  const [type] = React.useState(props.basePath.substring(index + 1));

  const { permissions } = props;
  const role = permissions && permissions.roles[0];

  const onSuccess = () => {
    notify("Post created", "success");
    redirect(`/posts/${type}`);
  };
  const onFailure = () => {
    notify("Try again", "error");
  };

  const transform = (values) => {
    return {
      ...values,
      postType: type,
    };
  };
  return (
    <Create title={`Create ${TypeMapping.get(type)} Post`} onSuccess={onSuccess} onFailure={onFailure} transform={transform} {...props}>
      <SimpleForm>
        <TextField source="Create Post" />
        <TextInput source="postType" label="Post Type" defaultValue={formatWord(type)} disabled />
        <TextInput
          source="title"
          label="Title"
          validate={[required(), maxLength(30)]}
          multiline={true}
        />
        <TextInput
          source="description"
          label="Description"
          validate={[required(), maxLength()]}
          multiline={true}
        />
        <ImageInput
          validate={[required(), async (images) => await validateFileAndCount(images, 1, 5)]}
          accept="image/png, image/jpeg,image/jpg"
          helperText="You can upload up to 5 images.
          For a better user experience, we recommend uploading images with dimensions 630 * 420 or 3:2"
          multiple={true}
          source="images"
          label="Images"
          placeholder={<p className="upload-link">Click here to upload Images (upto 5 Images)</p>}
        >
          <PreviewImage />
        </ImageInput>
        {type === TYPE.HOMES ? (
          <CustomHomesListInput role={role} />
        ) : type === TYPE.DIVISIONS ? (
          <CustomDivisionsListInput role={role} />
        ) : (
          <CustomCommunitiesListInput role={role} />
        )}
      </SimpleForm>
    </Create>
  );
};
