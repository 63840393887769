import * as React from "react";
import { Resource } from "react-admin";
import { ADMIN_ROLES } from "../Constants";
import {
  AdminsList,
  AdminEdit,
  AdminCreate,
  HomesList,
  HomeBuyersList,
  DivisionList,
  CommunitiesList,
  MortageList,
  MortageEdit,
  SettingEdit,
  SettingList,
  DocumentList,
  DocumentCreate,
  AnnouncementList,
  AnnouncementCreate,
  AnnouncementEdit,
  ConversationList,
  ConversationCreate,
  HomeElevationURLEdit,
  TimelineList,
  CheckListEdit,
  PostsCreate,
  SpecificPostsList,
  PostCommunitiesList,
  PostDivisionsList,
  PostHomesList,
  DocumentEdit,
  WarrantyList,
  UserWarrantyShow,
  // AppointmentCreate,
  // AppointmentEdit,
  // AppointmentsList,
  DivisionMapperList,
  DivisionMapperCreate,
  DesignCenterHomesList,
  DesignCenterImagesList,
  DesignCenterImagesUpload,
  DesignCenterVideoUpload,
  WarrantyVideoUpload,
  WarrantySampleImagesList,
  WarrantyImagesUpload,
  WarrantyDocumentCreate,
} from "../Pages/index";
import { BuyersActivityList } from "../Pages/BuyersReport/List";
import { EnvisionList } from "../Pages/Envision/List";
import { EnvisionEdit } from "../Pages/Envision/Edit";

export const handlePermissions = (permissions) => {
  const isSuperAdmin = permissions?.roles.includes(ADMIN_ROLES.SUPER);
  const isCorpAdmin = permissions?.roles.includes(ADMIN_ROLES.CORPORATE);
  const isOfficeAdmin = permissions?.roles.includes(ADMIN_ROLES.OFFICE);
  const isSalesAdmin = permissions?.roles.includes(ADMIN_ROLES.SALES);

  return [
    // HOME BUYERS
    !isSuperAdmin && (
      <Resource name="homebuyers" options={{ label: "Home Buyers" }} list={HomeBuyersList} />
    ),

    // BUYERS REPORT
    !isSuperAdmin && (
      <Resource name="report" options={{ label: "Buyers Report" }} list={BuyersActivityList} />
    ),

    // HOMES
    !isSuperAdmin && (
      <Resource
        name="homes"
        options={{ label: "Homes" }}
        list={HomesList}
        edit={HomeElevationURLEdit}
      />
    ),

    // ADMINS
    !isSalesAdmin && (
      <Resource
        name="admins"
        options={{ label: "Admins" }}
        list={AdminsList}
        edit={isSuperAdmin && AdminEdit}
        create={isSuperAdmin && AdminCreate}
      />
    ),

    // DIVISIONS
    <Resource name="divisions" options={{ label: "Divisions" }} list={DivisionList} />,

    // ENVISION
    (isSuperAdmin || isCorpAdmin) && (
      <Resource
        name="envision"
        options={{ label: "Envision" }}
        list={EnvisionList}
        edit={EnvisionEdit}
      />
    ),
    // DESIGN CENTER
    !isSuperAdmin && (
      <Resource
        name="designCenter"
        options={{ label: "Design Center" }}
        list={DesignCenterHomesList}
        show={DesignCenterImagesList}
        edit={DesignCenterImagesUpload}
        create={DesignCenterVideoUpload}
      />
    ),

    // DIVISIONS
    isSuperAdmin && (
      <Resource
        name="divisionMapper"
        options={{ label: "Division Mapper" }}
        list={DivisionMapperList}
        create={DivisionMapperCreate}
      />
    ),

    // COMMUNITIES
    !isOfficeAdmin && (
      <Resource name="communities" options={{ label: "Communities" }} list={CommunitiesList} />
    ),

    // TIMELINE
    isCorpAdmin && (
      <Resource
        name="timeline"
        options={{ label: "Timeline" }}
        list={TimelineList}
        edit={CheckListEdit}
      />
    ),

    // SETTINGS
    isCorpAdmin && (
      <Resource
        name="settings"
        options={{ label: "Configuration" }}
        list={SettingList}
        edit={SettingEdit}
      />
    ),

    // MORTGAGES
    isCorpAdmin && (
      <Resource
        name="mortage"
        options={{ label: "Mortgages" }}
        list={MortageList}
        edit={MortageEdit}
      />
    ),

    // Appointments
    // isCorpAdmin && (
    //   <Resource
    //     name="appointments"
    //     options={{ label: "Appointments" }}
    //     list={AppointmentsList}
    //     edit={AppointmentEdit}
    //     create={AppointmentCreate}
    //   />
    // ),

    // Conversations
    !isSuperAdmin && (
      <Resource
        name="conversations"
        options={{ label: "Conversations" }}
        list={ConversationList}
        create={permissions.allowConversation && ConversationCreate}
      />
    ),

    //Admin Documnet Homes
    (isCorpAdmin || isOfficeAdmin || isSalesAdmin) && (
      <Resource
        name="documentsAdmin/homes"
        options={{ label: "Home Documents" }}
        list={DocumentList}
        create={DocumentCreate}
        edit={DocumentEdit}
      />
    ),

    //Admin Document Communities
    (isCorpAdmin || isOfficeAdmin || isSalesAdmin) && (
      <Resource
        name="documentsAdmin/communities"
        options={{ label: "Community Documents" }}
        list={DocumentList}
        create={DocumentCreate}
        edit={DocumentEdit}
      />
    ),

    // Admin Document Divisions
    (isCorpAdmin || isOfficeAdmin || isSalesAdmin) && (
      <Resource
        name="documentsAdmin/divisions"
        options={{ label: "Division Documents" }}
        list={DocumentList}
        create={DocumentCreate}
        edit={DocumentEdit}
      />
    ),

    // Admin Announcements Divisions
    !isSuperAdmin && (
      <Resource
        name="announcementsAdmin/divisions"
        options={{ label: "Division Announcements" }}
        list={AnnouncementList}
        create={!isSalesAdmin && AnnouncementCreate}
        edit={!isSalesAdmin && AnnouncementEdit}
      />
    ),

    // Admin Announcements Communities
    !isSuperAdmin && (
      <Resource
        name="announcementsAdmin/communities"
        options={{ label: "Community Announcements" }}
        list={AnnouncementList}
        create={!isSalesAdmin && AnnouncementCreate}
        edit={!isSalesAdmin && AnnouncementEdit}
      />
    ),

    // Warranty Submissions
    !isSuperAdmin && (
      <Resource
        name="userWarranty"
        options={{ label: "Warranty Submissions" }}
        list={WarrantyList}
        show={UserWarrantyShow}
      />
    ),

    // Admin Warranty Documents
    !isSuperAdmin && (
      <Resource
        name="documentsAdmin/warranty"
        options={{ label: "Warranty Documents" }}
        list={DocumentList}
        create={isCorpAdmin && WarrantyDocumentCreate}
        edit={DocumentEdit}
      />
    ),

    // Warranty Assets
    isCorpAdmin && (
      <Resource
        name="assets/warranty"
        options={{ label: "Warranty Assets" }}
        list={WarrantySampleImagesList}
        create={WarrantyImagesUpload}
        edit={WarrantyVideoUpload}
      />
    ),

    // Admin Post Division
    !isSuperAdmin && (
      <Resource
        name="posts/divisions"
        options={{ label: "Divisions" }}
        list={isSalesAdmin ? PostCommunitiesList : PostDivisionsList}
        create={!isSalesAdmin && PostsCreate}
        edit={SpecificPostsList}
      />
    ),

    // Admin Post Communities
    !isSuperAdmin && (
      <Resource
        name="posts/communities"
        options={{ label: "Communities" }}
        list={isOfficeAdmin ? PostDivisionsList : PostCommunitiesList}
        create={PostsCreate}
        edit={SpecificPostsList}
      />
    ),

    // Admin Post Homes
    !isSuperAdmin && (
      <Resource
        name="posts/homes"
        options={{ label: "Homes" }}
        list={PostHomesList}
        create={PostsCreate}
        edit={SpecificPostsList}
      />
    ),
  ];
};
