export const OwnerIcon = ({ activeColor }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 512.415 512.415" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M467.914,445.093l-32.021-8l-1.344-5.355c8.32-9.173,14.208-20.885,16.661-33.024c5.696-2.944,9.835-8.533,10.667-15.253
				l2.325-18.56c0.704-5.632-1.024-11.307-4.757-15.552c-1.152-1.323-2.475-2.475-3.904-3.435l0.555-11.307l1.963-1.963
				c5.483-5.845,12.928-18.325,1.152-36.331c-5.589-8.576-17.109-18.773-40.171-18.773c-6.784,0-22.123,0-37.013,9.344
				c-43.904,1.536-49.067,25.195-49.067,43.072c0,3.52,0.619,10.112,1.237,15.616c-1.579,1.003-3.029,2.219-4.288,3.648
				c-3.776,4.288-5.547,9.984-4.843,15.68l2.325,18.56c0.875,6.955,5.291,12.715,11.797,15.552
				c2.411,11.627,7.979,22.891,15.765,31.829l-1.557,6.272l-32.021,8c-25.963,6.464-44.075,29.675-44.075,56.427
				c0,5.888,4.779,10.667,10.667,10.667H501.3c5.888,0,10.667-4.8,10.667-10.688C511.967,474.789,493.855,451.578,467.914,445.093z" fill={activeColor}/>
			<path d="M272.074,426.874H181.3c-5.888,0-10.667-4.779-10.667-10.667V277.541c0-5.888,4.779-10.667,10.667-10.667h187.84
				c1.643-0.235,2.965-0.683,4.693-0.853c1.301-0.128,2.56-0.469,3.733-1.045c17.835-8.768,35.008-8.768,41.472-8.768
				c4.843,0,9.664,0.448,15.232,1.429c1.963,0.384,4.331-0.299,6.464-1.429h60.8c6.229-0.192,10.901-4.693,10.88-10.667
				c0-3.861-2.048-7.232-5.12-9.109l-59.307-59.307V10.874c0-5.888-4.779-10.667-10.667-10.667h-85.333
				c-5.888,0-10.667,4.779-10.667,10.667v59.584l-59.584-59.584c-13.717-13.739-37.696-13.76-51.477,0L3.124,238.01
				c-3.051,3.051-3.968,7.637-2.304,11.627c1.6,3.968,5.504,6.571,9.813,6.571h53.333v245.333c0,5.888,4.779,10.667,10.667,10.667
				H245.3c5.739,0,10.688-4.523,10.667-10.283c-0.107-21.888,8.597-41.963,23.253-56.533
				C286.132,438.522,281.823,426.874,272.074,426.874z" fill={activeColor}/>
    </svg>
  );
};
