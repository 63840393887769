import * as React from "react";
import { Datagrid, TextField, List, TextInput, FunctionField, useNotify } from "react-admin";
import {
  PostPagination,
  BuyerContact,
  ResendInviteBuyer,
} from "../../Components";
import { RECORDS_PER_PAGE, BASE_URL } from "../../Constants";
import { getFullDate } from "../../Utils";
import { HomeBuyerExpand } from "./ListExpand";
import { makeStyles, Tooltip } from "@material-ui/core";
import EyeIcon from "@material-ui/icons/Visibility";
import instance from "../../Core/service";

const useStyles = makeStyles((theme) => ({
  customBtn: {
    color: "#3f51b5",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "600",
    cursor: "pointer",
    padding: "5px 5px 5px 0",
    display: "inline-block",
    "&:hover": {
      background: "rgb(239 239 239 / 90%)",
    },
  },
}));

export const HomeBuyersList = (props) => {
  const classes = useStyles();
  const notify = useNotify();

  const handleImpersonation = (email) => {
    instance
      .get(`${BASE_URL}/homebuyers/customToken/${email}`)
      .then((res) => {
        res.status === 200
          ? window.open(
              `${process.env.REACT_APP_VIEW_URL}/login/?token=${res.data.token}`,
              "_blank"
            )
          : notify("Internal Server Error", "error");
      })
      .catch((e) => {
        notify("Internal Server Error", "error");
      });
  };
  const filters = [<TextInput label="Search" source="q" placeholder="Name,Email" alwaysOn />];
  return (
    <List
      {...props}
      sort={{ field: "firstName", order: "ASC" }}
      actions={false}
      bulkActionButtons={false}
      perPage={RECORDS_PER_PAGE}
      pagination={<PostPagination />}
      filters={filters}
    >
      <Datagrid expand={HomeBuyerExpand}>
        <TextField source="lastName" sortable={false} label="Last Name" />
        <TextField source="firstName" sortable={false} label="First Name" />
        <TextField source="email" sortable={false} label="Email" />
        <FunctionField
          label="Last Activity"
          render={(record) => {
            return record.lastLogin ? (
              `${getFullDate(record.lastLogin)}`
            ) : (
              <div className={classes.customBtn}>
                <ResendInviteBuyer record={record} />
              </div>
            );
          }}
        />
        <TextField source="buyerType" sortable={false} label="Buyer Type" />
        <FunctionField
          label="Contact Number"
          render={(record) => <BuyerContact record={record} />}
        />
        {/* {process.env.REACT_APP_IMPERSONATION === "true" && (
          <FunctionField
            className={classes.iconAlign}
            label="Impersonate Buyer"
            render={(record) => {
              return (
                <EyeIcon
                  style={{ color: "#3f51b5" }}
                  onClick={() => handleImpersonation(record.id)}
                />
              );
            }}
          />
        )} */}
        <FunctionField
          className={classes.iconAlign}
          label="Action"
          render={(record) => {
            return (
              <React.Fragment>
                {process.env.REACT_APP_IMPERSONATION === "true" && (
                  <Tooltip title="Impersonate Buyer">
                    <EyeIcon
                      style={{ color: "#3f51b5" }}
                      onClick={() => handleImpersonation(record.id)}
                    />
                  </Tooltip>
                )}
              </React.Fragment>
            );
          }}
        />
      </Datagrid>
    </List>
  );
};
