import { makeStyles } from "@material-ui/core";

export const warrantyFormStyles = makeStyles((theme) => ({
  containerForm: {
    maxWidth: "1024px",
    width: "100%",
    margin: "auto",
    padding: "20px",
  },
  "@page": {
    margin: "2cm",
  },
  // "@media print": {
  //   "#pdf": {
  //     display: "block",
  //     overflow: "visible",
  //   },
  // },
  ".MuiFormControl-root": {
    overflow: "visible",
  },

  headerSection: {
    display: "flex",
    gap: 70,
    alignItems: "center",
  },
  logo: {
    maxWidth: 200,
  },
  formLabel: {
    fontSize: "16px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  formContainer: {
    border: "1px solid #000",
    padding: 20,
    margin: "20px 0px",
  },
  flex: {
    display: "flex",
  },
  formRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
    gap: 20,
  },
  formCol3: {
    display: "flex",
    width: "33%",
    alignItems: "center",
    gap: 20,
  },
  formCol2: {
    display: "flex",
    width: "50%",
    alignItems: "center",
    gap: 10,
  },
  formColFull: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    gap: 10,
  },
  formInput: {
    width: "100%",
    "& .MuiInput-input": {
      fontSize: "14px",
      padding: "0 0 5px 0",
      marginLeft: "5px",
      with: "100%",
      height: "20px",
    },
  },
  emailInput: {
    width: "100%",
    "& .MuiInput-input": {
      fontSize: "14px",
      padding: "0 0 5px 0",
      marginLeft: "5px",
      with: "100%",
      height: "20px",
    },
  },

  doubleBorderDesc: {
    borderStyle: "double",
    padding: 10,
    fontSize: 14,
    marginTop: 30,
  },
  orderList: {
    paddingLeft: 15,
  },
  signatureBox: {
    display: "flex",
    justifyContent: "space-between",
    margin: "30px 0",
  },
  signatureBoxInput: {
    borderTop: "1px solid #000",
    padding: 5,
    width: "40%",
    textAlign: "center",
  },
  tableInput: {
    width: "100%",
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none",
    },
  },
  tableFormInput: {
    "& .MuiTableCell-head": {
      padding: "0 10px",
      lineHeight: "20px",
      borderRight: "1px solid #000",
      borderTop: "1px solid #000",
    },
    "& .MuiTableCell-head:nth-child(1)": {
      borderLeft: "1px solid",
    },
    // "& .MuiTableCell-head:nth-child(2)": {
    //   borderTop: "0px solid",
    // },
    "& .MuiTableCell-body": {
      padding: "5px 10px",
      lineHeight: "20px",
      verticalAlign: "top",
      borderRight: "1px solid #000",
      overflow: "visible",
    },
    "& .MuiTableCell-body:nth-child(1)": {
      borderRight: "1px solid",
      borderLeft: "1px solid",
    },

    "& .MuiInputBase-multiline": {
      padding: "0",
    },
    "& .MuiTableCell-root": {
      borderColor: "#000",
    },
  },
  footerAction: {
    margin: "auto",
    padding: "10px",
    position: "fixed",
    bottom: 0,
    background: "#f5f5f5",
    width: "100%",
    display: "flex",
    gap: "20px",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  footerNotification: {
    width: "100%",
    margin: "5px 0",
    textAlign: "center",
    color: "#3f51b5",
  },
  errorNotification: {
    width: "100%",
    margin: "5px 0",
    textAlign: "center",
    color: "red",
  },
}));
