import axios from "axios";
import imageCompression from "browser-image-compression";

export const getName = (adminDataObject) => {
  return adminDataObject?.firstName && adminDataObject?.lastName
    ? `${adminDataObject.firstName} ${adminDataObject.lastName}`
    : "";
};

export const formatWord = (word) => `${word[0].toLocaleUpperCase()}${word.substring(1)}`;

export const getFullDate = (date) => {
  const newDate = date?.split("T")[0];
  return getClosedDate(newDate);
};

export const getClosedDate = (date) => {
  const split = date?.split("-");
  if (split?.length > 1) return `${split[1]}/${split[2]}/${split[0]}`;
  return "";
};

export const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const addDays = (date, days) => {
  const prevDate = new Date(date);
  prevDate.setDate(prevDate.getDate() + days);
  return getFullDate(prevDate.toISOString());
};

export const hasDuplicates = (array) => {
  return new Set(array).size !== array.length;
};

export const redirectToMessagesTab = (conversationObj) => {
  const baseURL = window.location.href.split("?")[0];
  const URL = `${baseURL}/${conversationObj.id}/messages`;
  window.open(URL, "_blank").focus();
};

export const redirectToEditWarrantyTab = (warrantyObjectId) => {
  const baseURL = window.location.href.split("?")[0];
  const URL = `${baseURL}/${warrantyObjectId}/edit`;
  window.open(URL, "_blank").focus();
};

export const downloadData = async (issue) => {
  const { url, type, label } = issue;
  const response = await axios.get(url, { responseType: "blob" });
  const blob = new Blob([response.data], { type });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = label;
  link.click();
  URL.revokeObjectURL(link.href);
};

export const changeAreaName = (id = "", name = "") => {
  let val = name;
  if (id.length > 3 && id.substring(0, 3) === "MHW") {
    val = `MHW - ${name}`;
  }
  return val;
};

export const resizeImage = async (file, maxSizeInMb) => {
  const options = {
    maxSizeMB: maxSizeInMb,
    useWebWorker: true, 
  }
  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error('Image compression error:', error);
    return null;
  }
}
