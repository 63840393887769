import { useEffect } from "react";
import { Title, useRedirect } from "react-admin";
import { LOCAL_STORAGE_KEYS } from "../../Constants";
import { Permissions } from "./Permissions";
import { TotalRecords } from "./TotalRecords";

export const Dashboard = () => {
  const isWarrantyDocId = localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMITTED_WARRANTY);
  const redirect = useRedirect();
  useEffect(() => {
    const redirectToWarrantySubmissionPage = (id) => redirect(`/userWarranty/${id}/show`);
    if (isWarrantyDocId) {
      redirectToWarrantySubmissionPage(isWarrantyDocId);
    }
  }, [isWarrantyDocId, redirect]);

  return (
    <div>
      <Title title="Dashboard" />
      <TotalRecords />
      <Permissions />
    </div>
  );
};
