import * as React from "react";
import { List, Datagrid, TextField, TextInput } from "react-admin";
import { PostPagination, SeePostsButton } from "../../Components";
import { RECORDS_PER_PAGE } from "../../Constants";

export const CommunitiesList = (props) => {
  const { type, actions } = props;
  const filters = [<TextInput label="Search" source="q" placeholder="Name" alwaysOn />];
  return (
    <List
      {...props}
      sort={{ field: "name", order: "ASC" }}
      actions={type ? actions : false}
      bulkActionButtons={false}
      perPage={RECORDS_PER_PAGE}
      pagination={<PostPagination />}
      filters={filters}
    >
      <Datagrid>
        <TextField source="id" label="Community Id" sortable={false} />
        <TextField source="division.name" label="Division Name" sortable={false} />
        <TextField source="name" label="Community Name" sortable={false} />
        <TextField source="abbr" label="Community Abbreviation" sortable={false} />
        <TextField source="city" sortable={false} />
        <TextField source="stateName" label="State" sortable={false} />
        <TextField source="county" sortable={false} />
        <TextField source="zip" sortable={false} />
        {type && <SeePostsButton type={type} label="Action" />}
      </Datagrid>
    </List>
  );
};
