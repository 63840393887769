import * as React from "react";
import { Datagrid, List, TextField, FunctionField } from "react-admin";
import { getClosedDate, getName } from "../../Utils";
import { RECORDS_PER_PAGE } from "../../Constants";
import {
  PostPagination,
  ShowMessagesButtonField,
  ConversationHomeData,
  ConversationActions,
  ConversationBuyerData,
} from "../../Components";

export const ConversationList = ({ permissions, ...props }) => {
  const [conversationRecord, setConversationRecord] = React.useState({});

  const rowStyle = (record) => {
    if (conversationRecord) {
      return {
        backgroundColor: record.id === conversationRecord.id ? "rgba(0, 0, 0, 0.04)" : "white",
      };
    } else return {};
  };

  return (
    <List
      {...props}
      sort={{ field: "lastMessageDateTime", order: "desc" }}
      bulkActionButtons={false}
      actions={<ConversationActions label="Start Conversation" basePath="/conversations" />}
      pagination={<PostPagination />}
      perPage={RECORDS_PER_PAGE}
      filterDefaultValues={{ myConversation: true }}
    >
      <Datagrid rowStyle={rowStyle}>
        <TextField source="adminData.designation" label="Admin Designation" sortable={false} />
        <FunctionField label="Admin" render={(record) => getName(record.adminData)} />
        <FunctionField
          label="Buyer"
          render={(record) => {
            return <ConversationBuyerData buyerData={record.buyerData} />;
          }}
        />
        <FunctionField
          label="Closed Date"
          render={(record) => {
            return <div>{getClosedDate(record?.homeData?.closedDate)}</div>;
          }}
        />
        <TextField source="buyerData.buyerType" label="Buyer Type" sortable={false} />
        <FunctionField
          label="Home Address"
          render={(record) => {
            return <ConversationHomeData record={record.homeData} />;
          }}
        />
        <ShowMessagesButtonField
          label="Message"
          setRecord={setConversationRecord}
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};
