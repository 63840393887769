import { UserMenu, Layout, AppBar, useGetIdentity } from "react-admin";
import { UserIntitalsImage } from "./UserInitialsImage";
import { Avatar } from "@material-ui/core";
import { CustomMenu } from "../Menu/Menu";
import { CustomChangePasswordButton } from "../Pages";

const MyUserMenu = (props) => {
  const { identity } = useGetIdentity();
  const getUserImage = () => {
    if (identity && identity.fullName) {
      const name = identity.fullName.split(" ");
      return <UserIntitalsImage firstName={name[0]} lastName={name[name.length - 1]} />;
    } else {
      return <Avatar />;
    }
  };
  return (
    <UserMenu {...props} icon={getUserImage()}>
      <CustomChangePasswordButton />
    </UserMenu>
  );
};

const MyAppBar = (props) => <AppBar {...props} userMenu={<MyUserMenu />} />;

export const CustomLayout = (props) => <Layout {...props} appBar={MyAppBar} menu={CustomMenu} />;
