import { authProvider } from "../Providers";

export const getIdToken = async () => {
  const tokenFromLocalStorage = localStorage.getItem("token");
  let token = JSON.parse(tokenFromLocalStorage) || {};
  const currDateTime = new Date();
  const expirationTime = token ? new Date(token.expirationTime) : new Date();
  token = checkUserSessionTimeOut(token);
  if (currDateTime > expirationTime) {
    token = await setTokenToLocalStore();
  }
  return token.token;
};

export const setTokenToLocalStore = async () => {
  const user = await authProvider.getAuthUser();
  const token = await user.getIdTokenResult(true);
  localStorage.setItem("token", JSON.stringify(token));
  return token;
};

const checkUserSessionTimeOut = (token, hoursBeforeSessionOut = 12) => {
  const currDateTime = new Date();
  const authTime = token ? new Date(token.authTime) : new Date();
  const hoursSinceLogin = Math.abs(currDateTime - authTime) / 3.6e6;
  return hoursSinceLogin >= hoursBeforeSessionOut ? "" : token;
};
