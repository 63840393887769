import * as React from "react";
import { Create, SimpleForm, TextInput, TextField, useNotify, useRedirect } from "react-admin";
import { CustomAdminsListInput, RefInput } from "../../Components";
import { BASE_URL } from "../../Constants";
import instance from "../../Core/service";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  adminList:{
    display: "flex",
    flexWrap: "nowrap",
    gap: "15px",
    width: "100%",    
  }, 
  adminListItem: {
    padding: "4px 8px",
    border: "1px solid #d9d9d9",
    background: "#eeeeee",
    borderRadius: "14px",
    color:"#919191"
  }
}));

export const DivisionMapperCreate = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const { basePath } = props;
  const basePathName = basePath.split("/")[1];
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [admins, setAdmins] = React.useState([]);
  const onSuccess = () => {
    notify("Mapping created", "success");
    redirect(`/divisionMapper`);
  };
  const onFailure = () => {
    notify("Try again", "error");
  };

  const transform = (values) => {
    return {
      ...values,
      list: values.list.map((a) => a.id),
    };
  };

  const handleOnChange = async (value) => {
    if (value) {
      setIsLoading(true);
      instance
        .get(`${BASE_URL}/${basePathName}/${value}`)
        .then((response) => {
          setAdmins(response.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsError(true);
          setIsLoading(false);
        });
    }
  };

  return (
    <Create onSuccess={onSuccess} onFailure={onFailure} transform={transform} {...props}>
      <SimpleForm>
        <TextField source="Create Mapping" />
        <TextInput
          source="mappingType"
          label="Division Mapping Type"
          defaultValue="Warranty"
          disabled
        />
        <RefInput source={"divisionId"} label="Division" onChange={handleOnChange} />
        {isLoading ? (
          <Typography variant="body2">Loading ...</Typography>
        ) : isError ? (
          <Typography variant="body2">Something went wrong while getting admins...</Typography>
        ) : admins.length ? (
          <Typography className={classes.adminList}>
            {admins.map((admin) => (
              <Typography className={classes.adminListItem}>
                {admin.firstName} {admin.lastName}
              </Typography>
            ))}
          </Typography>
        ) : null}
        <CustomAdminsListInput />
      </SimpleForm>
    </Create>
  );
};
