export const UserWarrantyIcon = ({ activeColor }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 71.68 71.68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="22.848" y="6.495" width="0.896" height="4.48"/>
			<rect x="35.392" y="6.495" width="0.896" height="4.48"/>
			<path fill={activeColor} d="M64.96,6.495h-4.257v4.48h4.257c1.345,0,2.239,0.896,2.239,2.239v49.279c0,1.346-0.896,2.24-2.239,2.24H6.72
				c-1.344,0-2.24-0.896-2.24-2.24V13.215c0-1.345,0.896-2.239,2.24-2.239h4.479v-4.48H6.72C2.911,6.495,0,9.407,0,13.216v49.279
				c0,3.809,2.911,6.721,6.72,6.721h58.24c3.808,0,6.72-2.912,6.72-6.721V13.216C71.68,9.632,68.544,6.495,64.96,6.495z"/>
			<rect x="47.936" y="6.495" width="0.672" height="4.48"/>
			<path fill={activeColor} d="M54.656,15.233c2.017,0,3.584-1.568,3.584-3.584V6.048c0-2.017-1.567-3.584-3.584-3.584
				c-2.016,0-3.584,1.567-3.584,3.584v5.601C51.072,13.441,52.641,15.233,54.656,15.233z"/>
			<path fill={activeColor} d="M42.111,15.233c2.018,0,3.584-1.568,3.584-3.584V6.048c0-2.017-1.566-3.584-3.584-3.584
				c-2.016,0-3.584,1.567-3.584,3.584v5.601C38.527,13.441,40.096,15.233,42.111,15.233z"/>
			<path fill={activeColor} d="M29.568,15.233c2.017,0,3.584-1.568,3.584-3.584V6.048c0-2.017-1.567-3.584-3.584-3.584
				c-2.016,0-3.584,1.567-3.584,3.584v5.601C25.984,13.441,27.552,15.233,29.568,15.233z"/>
			<path fill={activeColor} d="M17.024,15.233c2.017,0,3.584-1.568,3.584-3.584V6.048c0-2.017-1.567-3.584-3.584-3.584
				c-2.016,0-3.584,1.567-3.584,3.584v5.601C13.44,13.441,15.008,15.233,17.024,15.233z"/>
			<path fill={activeColor} d="M35.168,57.569c10.525,0,19.04-8.514,19.04-19.041s-8.515-19.039-19.04-19.039c-10.528,0-19.04,8.512-19.04,19.039
				S24.64,57.569,35.168,57.569z M21.504,31.136l1.344,0.672l0.448-0.672l-1.345-0.672c1.345-2.017,2.912-3.809,5.152-5.151
				l0.672,1.344l0.672-0.448l-0.672-1.344c2.017-1.12,4.48-1.792,6.944-2.017v1.567h0.896v-1.567c2.465,0,4.929,0.673,6.943,2.017
				l-0.672,1.344l0.672,0.448l0.672-1.344c2.018,1.344,3.81,3.136,5.151,5.151l-1.343,0.672l0.447,0.672l1.345-0.672
				c1.119,2.017,1.792,4.479,2.017,6.943h-1.568v0.896h1.568c0,2.463-0.672,4.928-2.016,6.943l-1.346-0.673L47.04,45.92l1.344,0.672
				c-1.344,2.017-3.136,3.808-5.151,5.15l-0.672-1.343l-0.672,0.449l0.672,1.344c-2.017,1.119-4.479,1.791-6.943,2.016V52.64H34.72
				v1.567c-2.464,0-4.928-0.672-6.944-2.017l0.672-1.344l-0.672-0.447l-0.672,1.345c-2.016-1.345-3.808-3.136-5.152-5.15
				l1.345-0.672l-0.448-0.673l-1.344,0.673c-1.12-2.019-1.792-4.479-2.017-6.944h1.568v-0.896h-1.568
				C19.712,35.616,20.384,33.153,21.504,31.136z"/>
			<path fill={activeColor} d="M35.168,26.656v11.872H47.04C47.04,32.032,41.664,26.656,35.168,26.656z"/>
    </svg>
  );
};
