import React from "react";
import { Typography } from "@material-ui/core";
import { HomesList } from "../Homes/List";

export const ConversationCreate = () => {
  return (
    <React.Fragment>
      <Typography variant="body1">
        Expand a home then one of its buyer to initiate conversation
      </Typography>
      <HomesList basePath="/conversations/homes" resource="homes" title="Create Conversation" />
    </React.Fragment>
  );
};
