import React, { Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    justifyContent: "center",
  },
}));

export const ConfirmationPopup = (props) => {
  const classes = useStyles(props);
  const { title, titleText, handleConfirm, isOpen, setOpen } = props;

  const handleDialogClose = () => setOpen(false);

  return (
    <Fragment>
      <Dialog
        open={isOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title ? title : "Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {titleText ? titleText : "Please confirm that you want to perform this action."}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button onClick={handleDialogClose} variant="contained" color="secondary">
            No, Cancel
          </Button>
          <Button onClick={handleConfirm} variant="outlined">
            Yes, Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
