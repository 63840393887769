import React from "react";
import { RouteWithoutLayout } from "react-admin";
import { MessageWrapper } from "./Pages/Messages/MessageWrapper";
import { PostEditWrapper } from "./Pages/Posts/PostHelper";
import { Route } from "react-router-dom";
import { WarrantyFormWrapper } from "./Pages/Warranty/WarrantyFormWrapper";

export const routes = [
  <RouteWithoutLayout
    exact
    path="/conversations/:conversationId/messages"
    component={MessageWrapper}
  />,
  <RouteWithoutLayout
    exact
    path="/userWarranty/:userWarrantyId/edit"
    component={WarrantyFormWrapper}
  />,
  <Route exact path="/posts/:type/editPost/:postId" component={PostEditWrapper} />,
];
