import React, { useState, useEffect, useRef } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  TextField,
  required,
  useNotify,
  useRedirect,
} from "react-admin";
import { TYPE } from "../../Constants";
import {
  CustomDivisionsListInput,
  CustomHomesListInput,
  CustomCommunitiesListInput,
} from "../../Components";
import { validateDocumentFileSize, formatWord, maxLength } from "../../Utils";
import { FILE_SIZE_TYPES } from "../../Constants";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@material-ui/icons/RemoveCircleOutline";

export const DocumentCreate = (props) => {
  const { basePath, permissions } = props;
  const notify = useNotify();
  const redirect = useRedirect();
  const index = basePath.lastIndexOf("/");
  const type = basePath.substring(index + 1);
  const role = permissions && permissions.roles[0];

  const [selectedFiles, setSelectedFiles] = useState([]);
  const selectedFilesRef = useRef(selectedFiles);

  useEffect(() => {
    selectedFilesRef.current = selectedFiles;
  }, [selectedFiles]);

  const transform = (data) => {
    return {
      list: data.list,
      documentType: type,
      documents: selectedFilesRef?.current?.map((file) => ({
        ...file,
        title: data[`title_${file.id}`],
      })),
    };
  };

  const onSuccess = () => {
    notify("Document created", "success");
    redirect(`/documentsAdmin/${type}`);
  };

  const onFailure = (error) => {
    if (Array.isArray(error) && error.length) {
      const fileNames = error.map((err) => err.extraData?.file?.name).join(", ");
      notify(
        `Following files were not uploaded: ${fileNames}.\n Please upload them again.`,
        "error"
      );
    } else {
      notify("Try again", "error");
    }
  };

  return (
    <Create {...props} transform={transform} onSuccess={onSuccess} onFailure={onFailure}>
      <SimpleForm>
        <TextField source="Create Document" />
        <TextInput
          source="documentType"
          label="Document Type"
          defaultValue={formatWord(type)}
          disabled
        />
        <CustomFileInput
          validate={validateDocumentFileSize(20, FILE_SIZE_TYPES.MB, "File exceeds 20 MB")}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
        {type === TYPE.HOMES && <CustomHomesListInput role={role} />}
        {type === TYPE.DIVISIONS && <CustomDivisionsListInput role={role} />}
        {type === TYPE.COMMUNITIES && <CustomCommunitiesListInput role={role} />}
      </SimpleForm>
    </Create>
  );
};

const CustomFileInput = ({ validate, selectedFiles, setSelectedFiles }) => {
  const notify = useNotify();

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files).map((file) => ({
      file,
      id: uuidv4(),
    }));
    const validationError = validate([...files.map(({ file }) => file), ...selectedFiles]);
    if (validationError) {
      notify(validationError, "error");
    } else {
      const newSelectedFiles = [...selectedFiles, ...files];
      setSelectedFiles(newSelectedFiles);
    }
    event.target.value = "";
  };

  const handleFileRemove = (fileId) => {
    const updatedFiles = selectedFiles.filter((file) => file.id !== fileId);
    setSelectedFiles(updatedFiles);
  };

  return (
    <>
      <div class="upload-btn-wrapper">
        <input id="upload" type="file" multiple onChange={handleFileChange} />
        <label for="upload">Please input files less than 20 MB</label>
      </div>
      <div className="uploaded-docs-wrapper">
        {selectedFiles.map(({ file, id }, index) => (
          <div key={id} className="uploaded-docs">
            <span style={{ marginLeft: 8 }}>{index + 1}.</span>
            <span className="truncate">{file.name}</span>
            <TextInput
              source={`title_${id}`}
              label={`Title`}
              validate={[required(), maxLength(50)]}
              multiline={true}
              style={{ marginLeft: 8 }}
            />
            <DeleteIcon
              label="REMOVE"
              style={{ color: "#3f51b6", marginLeft: "auto" }}
              onClick={() => handleFileRemove(id)}
            />
          </div>
        ))}
      </div>
    </>
  );
};
