import React, { Fragment } from "react";
import { useRedirect } from "react-admin";
import { TYPE, Icon } from "../../Constants";
import { formatWord } from "../../Utils";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Avatar,
  makeStyles,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  flexItem: {
    display: "flex",
    flex: "0 0 31%",
    flexDirection: "column",
    margin: "0 10px 15px",
  },
  flexInnerItem: {
    display: "flex",
    flex: "1 0 25%",
    flexDirection: "column",
    margin: "0 10px 15px",
  },
  iconGroup: {
    marginTop: "auto",
    fontSize: "13px",
  },
  iconRedirect: {
    cursor: "pointer",
    color: "#3f51b5",
    fontSize: "12px",
  },
  iconBg: {
    background: "#ebebeb",
  },
  iconTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export const CardWithoutCount = (props) => {
  const classes = useStyles(props);
  const redirect = useRedirect();
  const { page } = props;

  return (
    <Fragment>
      <Card variant="outlined" className={classes.flexItem}>
        <CardContent>
          <Typography component="div" className={classes.iconTitle}>
            <Typography variant="h5">{page.name}</Typography>
            <Avatar className={classes.iconBg}>{Icon[page.id]}</Avatar>
          </Typography>
        </CardContent>
        <Grid container spacing={1}>
          {cardsWithoutCountData[page.id].map((type, index) => {
            return (
              <Typography component="div" className={classes.flexInnerItem} key={index}>
                <CardContent>
                  <Typography variant="body1">{formatWord(type.name)}</Typography>
                </CardContent>
                <CardActions className={classes.iconGroup}>
                  <Button className={classes.iconRedirect} onClick={() => redirect(type.id)}>
                    View
                  </Button>
                </CardActions>
              </Typography>
            );
          })}
        </Grid>
      </Card>
    </Fragment>
  );
};

export const CardWithCount = (props) => {
  const classes = useStyles(props);
  const redirect = useRedirect();
  const { page } = props;
  return (
    <Card variant="outlined" className={classes.flexItem}>
      <CardContent>
        <Typography component="div" className={classes.iconTitle}>
          <Typography variant="h5">{formatWord(page.id)}</Typography>
          <Avatar className={classes.iconBg}>{Icon[page.id]}</Avatar>
        </Typography>
        <Typography variant="h6">{page.totalCount}</Typography>
      </CardContent>
      <CardActions className={classes.iconGroup}>
        <Button className={classes.iconRedirect} onClick={() => redirect(page.id)}>
          View
        </Button>
      </CardActions>
    </Card>
  );
};

const cardsWithoutCountData = {
  userWarranty: [{ id: "userWarranty", name: "Warranty Submissions" }],
  warrantyDocuments: [{ id: `documentsAdmin/${TYPE.WARRANTY}`, name: "Warranty" }],
  documents: [
    { id: `documentsAdmin/${TYPE.HOMES}`, name: "Home" },
    { id: `documentsAdmin/${TYPE.DIVISIONS}`, name: "Division" },
    { id: `documentsAdmin/${TYPE.COMMUNITIES}`, name: "Community" },
  ],
  posts: [
    { id: `posts/${TYPE.HOMES}`, name: "Home" },
    { id: `posts/${TYPE.DIVISIONS}`, name: "Division" },
    { id: `posts/${TYPE.COMMUNITIES}`, name: "Community" },
  ],
  announcements: [
    { id: `announcementsAdmin/${TYPE.DIVISIONS}`, name: "Division" },
    { id: `announcementsAdmin/${TYPE.COMMUNITIES}`, name: "Community" },
  ],
};
