import { Avatar } from "@material-ui/core";

export const UserIntitalsImage = (props) => {
  const { firstName, lastName, admin } = props;
  const generateInitials = () => {
    let initialsString = "";
    initialsString += firstName ? firstName[0] : "";
    initialsString += lastName ? lastName[0] : "";
    return initialsString.toUpperCase();
  };
  return <Avatar className={admin ? "msg-avtar-active" : "msg-avtar"}>{generateInitials()}</Avatar>;
};
