import * as React from "react";
import { Typography, Card, CardContent,makeStyles } from "@material-ui/core";
const useStyles = makeStyles(
  (theme) => ({
    titleBlock: {
      marginBottom: "10px",
      display: "block"
    }
  })
);
export const DescriptionExpand = (props) => {
  const { record } = props;
  const classes = useStyles(props);
  return (
    <Card>
      <CardContent>
        <Typography variant="body1" className={classes.titleBlock}>
          <strong>Description : </strong>
        </Typography>

        <Typography variant="body2">{record.description}</Typography>
      </CardContent>
    </Card>
  );
};
