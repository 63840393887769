import * as React from "react";
import {
  useNotify,
  useRedirect,
  ImageInput,
  useRefresh,
  ImageField,
  useUpdate,
  Loading,
  SimpleForm,
  TextInput,
  required,
  Title,
} from "react-admin";
import { convertFileToBase64, getImages } from "../../Providers";
import { validateFileAndCount, maxLength } from "../../Utils";
import { PostEditWriteToolbar } from "./PostHelper";
import { TYPE } from "../../Constants";
import { TypeMapping } from "../Documents/Edit";

export const PostEdit = (props) => {
  const { postData, postType } = props;
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const [update, { loading, loaded, error }] = useUpdate();
  const handlePostEditSave = async (updatedData) => {
    await updatePostService(updatedData, postData, update);
  };
  if (loaded) {
    notify("Your changes has been updated", "success");
    const redirectLink = getRedirectLink(postData, postType);
    redirect(redirectLink);
    refresh();
  }
  if (error) {
    notify("Some error occured", "error");
  }
  if (loading) return <Loading />;

  return (
    <React.Fragment>
      <Title title={`Update ${TypeMapping.get(postType)} Post`}/>
      <SimpleForm
        save={async (data) => {
          await handlePostEditSave(data);
        }}
        record={postData}
        toolbar={<PostEditWriteToolbar disabled={loading} />}
      >
        <TextInput disabled label="id" source="id" />
        <TextInput
          multiline={true}
          label="Title"
          validate={[required(), maxLength(30)]}
          source="title"
        />
        <TextInput
          multiline={true}
          label="Description"
          validate={[required(), maxLength()]}
          source="description"
        />
        <ImageInput
          validate={[required(), async (images) => await validateFileAndCount(images, 1, 5)]}
          accept="image/png, image/jpeg,image/jpg"
          helperText="You can upload up to 5 images.
          For a better user experience, we recommend uploading images with dimensions 630 * 420 or 3:2"
          multiple={true}
          source="images"
          label="Images"
          placeholder={<p className="upload-link">Click here to upload Images (upto 5 Images)</p>}
        >
          <ImageField source="image" title="name" />
        </ImageInput>
      </SimpleForm>
    </React.Fragment>
  );
};

const getRedirectLink = (postData, postType) => {
  let id = "";
  if (postType === TYPE.DIVISIONS) {
    id = postData.division.id;
  } else if (postType === TYPE.HOMES) {
    id = postData.home.id;
  } else {
    id = postData.community.id;
  }
  return `/posts/${postType}/${id}`;
};

const updatePostService = async (updatedData, postData, update) => {
  const areImagesChanged = checkUpdateImages(postData.images, updatedData.images);
  let updatePostBody = {
    title: updatedData.title,
    description: updatedData.description,
  };
  if (areImagesChanged) {
    const imagesNotChangedArray = [];
    const newImagesArray = [];
    updatedData.images.forEach((updatedImage) => {
      if (updatedImage.rawFile) {
        newImagesArray.push(updatedImage);
      } else {
        postData.images.forEach((image) => {
          if (image.name === updatedImage.name) {
            imagesNotChangedArray.push(updatedImage);
          }
        });
      }
    });
    const images = newImagesArray.map((image) => image.rawFile);
    let newBase64Images = [];
    await Promise.all(images.map(convertFileToBase64)).then((base64File) => {
      const base64Files = base64File.map((file64) => ({
        src: file64,
      }));
      newBase64Images = getImages(base64Files, newImagesArray);
    });
    updatePostBody = {
      ...updatePostBody,
      images: newBase64Images,
      previousImages: imagesNotChangedArray,
    };
  }
  update("posts", postData.id, updatePostBody, postData);
};

const checkUpdateImages = (prevImages, currentImages) => {
  if (prevImages.length !== currentImages.length) {
    return true;
  } else {
    for (let i = 0; i < currentImages.length; i++) {
      if (prevImages[i] !== currentImages[i]) {
        return true;
      }
    }
  }
  return false;
};
