import axios from "axios";
import { BASE_URL } from "../Constants";
import { getIdToken } from "../Utils";

const defaultOptions = {
  baseURL: BASE_URL,
  headers: {
    accept: "application/json",
  },
};
const instance = axios.create(defaultOptions);

instance.interceptors.request.use(async (config) => {
  const token = await getIdToken();
  config.headers.authorization = `Bearer ${token}`;
  return config;
});

export default instance;
