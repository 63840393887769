import * as React from "react";
import { DivisionList } from "../Divisions/List";

export const EnvisionList = () => {
  return (
    <DivisionList
      title="Envision"
      hasCreate={false}
      basePath={`/posts/divisions`}
      resource={`divisions`}
    />
  );
};
