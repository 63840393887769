import { ADMIN_ROLES, MAPPED_ADMINS } from "../../Constants";
import permissionsJSON from "../../Constants/permissions.json";
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import ShortTextIcon from "@material-ui/icons/ShortText";
import { usePermissions } from "react-admin";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  headingMargin: {
    margin: "25px 0",
    display: "flex",
    alignItems: "center",
  },
  iconMargin: {
    marginRight: "8px",
  },
}));

export const Permissions = (props) => {
  const classes = useStyles(props);
  const { permissions } = usePermissions();
  const mappedPermissions = getMappedPermissions(permissions?.roles);

  return (
    <div>
      <Typography variant="h4" className={classes.headingMargin}>
        Permissions
      </Typography>
      {mappedPermissions &&
        mappedPermissions.map((role, index) => <PermissionsList role={role} key={index} />)}
    </div>
  );
};

const PermissionsList = (props) => {
  const { role } = props;
  const [collapsed, setCollapsed] = useState(true);
  const classes = useStyles(props);
  const permissionList = permissionsJSON[role];

  return (
    <div>
      <Typography variant="h6" className={classes.headingMargin}>
        {collapsed ? (
          <ExpandMore className={classes.iconMargin} onClick={() => setCollapsed(!collapsed)} />
        ) : (
          <ExpandLess className={classes.iconMargin} onClick={() => setCollapsed(!collapsed)} />
        )}
        {MAPPED_ADMINS[role]}
      </Typography>
      {!collapsed && (
        <List>
          {permissionList.map((permission, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <ShortTextIcon />
              </ListItemIcon>
              <ListItemText>{permission}</ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};
const getMappedPermissions = (roles) => {
  if (roles) {
    let map = [];
    if (roles.includes(ADMIN_ROLES.SUPER)) {
      map = [ADMIN_ROLES.SUPER, ADMIN_ROLES.CORPORATE, ADMIN_ROLES.OFFICE, ADMIN_ROLES.SALES];
    } else if (roles.includes(ADMIN_ROLES.CORPORATE)) {
      map = [ADMIN_ROLES.CORPORATE, ADMIN_ROLES.OFFICE, ADMIN_ROLES.SALES];
    } else if (roles.includes(ADMIN_ROLES.OFFICE)) {
      map = [ADMIN_ROLES.OFFICE, ADMIN_ROLES.SALES];
    } else if (roles.includes(ADMIN_ROLES.SALES)) {
      map = [ADMIN_ROLES.SALES];
    }
    return map;
  }
};
