import * as React from "react";
import { Datagrid, TextField, List } from "react-admin";
import { RECORDS_PER_PAGE } from "../../Constants";
import { CustomCreateButton, PostPagination } from "../../Components";
import { DivisionMapperExpand } from "./ListExpand";

export const DivisionMapperList = ({ permissions, ...props }) => {
  return (
    <div>
      <List
        {...props}
        sort={{ field: "name", order: "ASC" }}
        title="Division Mapping List"
        bulkActionButtons={false}
        perPage={RECORDS_PER_PAGE}
        pagination={<PostPagination />}
        resource={`divisions`}
        actions={<CustomCreateButton label="Create Mapping" basePath="/divisionMapper" />}
      >
        <Datagrid expand={DivisionMapperExpand}>
          <TextField source="id" label="Division Id" sortable={false} />
          <TextField source="name" label="Division Name" sortable={false} />
          <TextField source="abbr" label="Division Abbreviation" sortable={false} />
        </Datagrid>
      </List>
    </div>
  );
};
