import { useNotify } from "react-admin";
import { BASE_URL } from "../Constants";
import instance from "../Core/service";

export const BuyerAddress = (props) => {
  const { record } = props;
  return (
    <div>
      {record.street} {record.city} {record.state} {record.zip}
    </div>
  );
};

export const BuyerContact = (props) => {
  const { record } = props;
  return (
    <div>
      <div>{record.workphone}</div>
      <div>{record.mobilephone}</div>
      <div>{record.homephone}</div>
    </div>
  );
};

export const ResendInviteBuyer = (props) => {
  const { record } = props;
  const notify = useNotify();
  const handleResendInvite = async () => {
    instance
      .post(`${BASE_URL}/homebuyers/resendInvite/${record.id}`)
      .then((res) => {
        res.status === 200
          ? notify("Success Send", "success")
          : notify(
              "We were unable to process your request. Please try again after some time. If the problem persists, please contact the administrator.",
              "error"
            );
      })
      .catch((e) => {
        notify("Internal Server Error", "error");
      });
  };

  return <div onClick={handleResendInvite}>Resend Invite</div>;
};
