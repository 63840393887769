import * as React from "react";
import { Datagrid, TextField, List, FunctionField } from "react-admin";
import { RECORDS_PER_PAGE } from "../../Constants";
import {
  PostPagination,
  CustomDeletePopUp,
  CustomCreateButton,
  CustomEditButton,
} from "../../Components";
import { makeStyles } from "@material-ui/core/styles";
import { formatWord, getFullDate } from "../../Utils";
const useStyles = makeStyles((theme) => ({
  iconAlign: {
    display: "flex",
    alignItems: "center",
  },
}));
export const AppointmentsList = (props) => {
  const classes = useStyles();
  const { basePath, resource } = props;
  return (
    <List
      {...props}
      sort={{ field: "updatedAt", order: "desc" }}
      bulkActionButtons={<CustomDeletePopUp />}
      actions={<CustomCreateButton label="Create Appointment" basePath={basePath} />}
      perPage={RECORDS_PER_PAGE}
      pagination={<PostPagination />}
    >
      <Datagrid>
        <TextField source="title" sortable={false} />
        <TextField source="description" sortable={false} />
        <FunctionField label="Phase" render={(record) => record && formatWord(record.phase)} />
        <TextField source="calenderDays" sortable={false} />
        <TextField source="updatedBy" sortable={false} />
        <FunctionField 
        label="Updated At"
        render={(record) => {
          return (
            record.updatedAt && getFullDate(record.updatedAt)
          );
        }}
        />
        <FunctionField
          className={classes.iconAlign}
          label="Action"
          render={(record) => {
            return (
              <React.Fragment>
                {<CustomEditButton record={record} label="Action" />}
                {
                  <CustomDeletePopUp
                    isDocumentPage={true}
                    selectedIds={[record.id]}
                    resource={resource}
                  />
                }
              </React.Fragment>
            );
          }}
        />
      </Datagrid>
    </List>
  );
};
