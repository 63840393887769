import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  TextField,
  required,
  useNotify,
  useRedirect,
} from "react-admin";
import { CustomDivisionsListInput } from "../../Components";

export const DesignCenterVideoUpload = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { permissions } = props;
  const role = permissions && permissions.roles[0];

  const onSuccess = () => {
    notify("Video uploaded", "success");
    redirect(`/designCenter`);
  };
  const onFailure = () => {
    notify("Try again", "error");
  };

  return (
    <Create onSuccess={onSuccess} onFailure={onFailure} {...props} resource="homes/designCenter" title="Upload Video">
      <SimpleForm>
        <TextField source="Upload Video" />
        <CustomDivisionsListInput role={role} />
        <TextInput source="video" label="Video link" validate={[required()]} multiline={true} />
      </SimpleForm>
    </Create>
  );
};
