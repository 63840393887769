import * as React from "react";
import {
  Edit,
  SimpleForm,
  required,
  TextInput,
  ImageInput,
  useNotify,
  useRedirect,
} from "react-admin";
import { PreviewImage, WriteToolbarWithoutValidation } from "../../Components";
import RichTextInput from "ra-input-rich-text";
import "../../assets/css/loader.css";
import { maxLength, validateFileAndCount } from "../../Utils";

export const CheckListEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const transform = (data) => {
    let returnObject = {
      id: data.id,
      checkListName: data.checkListName,
      checkListTitle: data.checkListTitle,
      checkListDescription: data.checkListDescription,
    };
    if (data.checkListImage && data.checkListImage.rawFile) {
      returnObject = {
        ...returnObject,
        file: data.checkListImage,
      };
    }
    return returnObject;
  };

  const onSuccess = () => {
    redirect("/timeline");
    notify("Your changes has been updated", "success");
  };

  const onFailure = (error) => {
    notify(error.body.error, "error");
  };

  return (
    <Edit
      title={"Edit Check List"}
      {...props}
      mutationMode="optimistic"
      onSuccess={onSuccess}
      onFailure={onFailure}
      transform={transform}
    >
      <SimpleForm toolbar={<WriteToolbarWithoutValidation />}>
        <TextInput
          multiline={true}
          disabled={true}
          source="checkListName"
          label="Name"
          validate={required()}
        />
        <TextInput
          multiline={true}
          label="Title"
          source="checkListTitle"
          validate={[required(), maxLength(50)]}
        />
        <RichTextInput
          source="checkListDescription"
          toolbar={[["bold", "italic", "underline", "link"]]}
          label="Description"
          fullWidth={false}
          validate={[required(), maxLength()]}
        />
        <ImageInput
          helperText="For a better user experience, we recommend uploading image with dimensions 630 * 420 (3:2)"
          validate={[required(), async (image) => await validateFileAndCount([image])]}
          accept="image/png, image/jpeg,image/jpg"
          source="checkListImage"
          label="Image"
          placeholder={<p className="upload-link">Click here to upload an Image</p>}
        >
          <PreviewImage source="checkListImage" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};
