import React, { Fragment, useState } from "react";
import { useDeleteMany, useRefresh, useNotify, useUnselectAll } from "react-admin";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    justifyContent: "center",
  },
}));

export const CustomDeletePopUp = (props) => {
  const classes = useStyles(props);
  const { selectedIds, resource, setIsDelete, isDocumentPage } = props;
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();

  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const [deleteMany] = useDeleteMany(resource, selectedIds, {
    onSuccess: () => {
      setIsDelete && setIsDelete(true);
      refresh();
      notify("Deleted", "success");
      unselectAll(resource);
    },
    onFailure: (error) => {
      notify(error?.body?.message ?? "Error Deleting", "warning");
    },
  });

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => {
    setOpen(false);
    unselectAll(resource);
  };

  const handleConfirm = () => {
    deleteMany();
    setOpen(false);
  };

  return (
    <Fragment>
      <DeleteIcon
        label="Delete"
        style={isDocumentPage && { color: "#3f51b5" }}
        onClick={handleClick}
      />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button onClick={handleDialogClose} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} variant="outlined">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
