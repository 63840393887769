import { warrantyFormStyles } from "./Styles";

export const SignatureSection = (props) => {
  const classes = warrantyFormStyles(props);

  return (
    <div>
      <div className={classes.signatureBox}>
        <p className={classes.signatureBoxInput}>Homeowner's Signature</p>
        <p className={classes.signatureBoxInput}>Today's Date</p>
      </div>
      <div className={classes.signatureBox}>
        <p className={classes.signatureBoxInput}>Warranty Manager's Signature</p>
        <p className={classes.signatureBoxInput}>Today's Date</p>
      </div>
    </div>
  );
};
