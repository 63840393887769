import * as React from "react";
import { Datagrid, TextField, FunctionField, BooleanField, List, TextInput } from "react-admin";
import { ADMIN_ROLES, RECORDS_PER_PAGE, CHOOSE_ADMIN_ROLE } from "../../Constants";
import {
  CustomCreateButton,
  CustomEditButton,
  PostPagination,
  ResendInvite,
} from "../../Components";
import { changeAreaName } from "../../Utils";

export const AdminsList = ({ permissions, ...props }) => {
  const isSuperAdmin = permissions && permissions.roles.includes(ADMIN_ROLES.SUPER);
  const filters = [<TextInput label="Search" source="q" placeholder="Name, Email" alwaysOn />];
  
  return (
    <div>
      <List
        {...props}
        sort={{ field: "email", order: "ASC" }}
        actions={isSuperAdmin && <CustomCreateButton label="Create Admin" basePath="/admins" />}
        pagination={<PostPagination />}
        bulkActionButtons={false}
        perPage={RECORDS_PER_PAGE}
        filters={filters}
      >
        <Datagrid>
          <TextField source="firstName" sortable={false} label="First Name" />
          <TextField source="lastName" sortable={false} label="Last Name" />
          <TextField source="email" sortable={false} label="Email" />
          <TextField source="designation" sortable={false} label="Designation" />
          <FunctionField
            label="Roles"
            render={(record) => {
              const rolesList = record.roles.map((role) => {
                const roleFind = CHOOSE_ADMIN_ROLE.find((adminRole) => adminRole.id === role);
                return roleFind.name;
              });
              return rolesList.join([", "]);
            }}
          />
          <FunctionField
            label="Divisions"
            render={(record) => {
              if (record.roles.includes(ADMIN_ROLES.CORPORATE)) {
                return "All";
              }
              if (record.roles.includes(ADMIN_ROLES.SALES)) {
                const communitiesName = record.communities.map(
                  (community) =>
                    community.division &&
                    changeAreaName(community.division.id, community.division.name)
                );
                return communitiesName.join([", "]);
              }
              const divisionsName = record.divisions.map((division) =>
                changeAreaName(division.id, division.name)
              );
              return divisionsName.join([", "]);
            }}
          />
          <FunctionField
            label="Communities"
            render={(record) => {
              if (
                record.roles.includes(ADMIN_ROLES.CORPORATE) ||
                record.roles.includes(ADMIN_ROLES.OFFICE)
              ) {
                return "All";
              }
              const communitiesName = record.communities.map((community) =>
                changeAreaName(community.id, community.name)
              );
              return communitiesName.join([", "]);
            }}
          />
          <BooleanField source="conversationFlag" sortable={false} label="Allow Conversation" />
          <BooleanField source="status" sortable={false} label="Status" />
          <FunctionField
            label="Invite Status"
            render={(record) => {
              return <ResendInvite record={record} permissions={permissions} />;
            }}
          />
          {isSuperAdmin && <CustomEditButton basePath="/admins" label="Action" />}
        </Datagrid>
      </List>
    </div>
  );
};
