import "../../assets/css/theme.css";
import { usePermissions, useQuery } from "react-admin";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { HomAddress, DefaultElevationImage, CustomDeletePopUp } from "../../Components";
import instance from "../../Core/service";
import { ADMIN_ROLES, BASE_URL } from "../../Constants";
import { changeAreaName } from "../../Utils";

export const HomeBuyerExpand = (props) => {
  const { basePath, record } = props;
  const { permissions } = usePermissions();

  const { id, lastName, firstName, email, buyerType } = record;
  const isCorpAdmin = permissions && permissions.roles.includes(ADMIN_ROLES.CORPORATE);

  const {
    data,
    loading: isLoading,
    error: isError,
  } = useQuery({
    type: "getOne",
    resource: "homebuyers/homes",
    payload: { id },
  });

  const tableHeadings = [
    "Elevation",
    "Plan Description",
    "Plan Numer",
    "Home Address",
    "Division",
    "Community",
  ];
  if (isCorpAdmin) tableHeadings.push("Remove Ref");

  const buyerHeadings = ["Last Name", "First Name", "Email", "Buyer Type", "Remove Ref"];

  return (
    <Card>
      <CardContent>
        {isLoading ? (
          <Typography variant="body2">Loading ...</Typography>
        ) : isError ? (
          <Typography variant="body2">Something went wrong ...</Typography>
        ) : data.totalRecords === 0 ? (
          <>
            {isCorpAdmin ? (
              <Table>
                <TableHeading tableHeadings={buyerHeadings} />
                <TableBody>
                  <TableRow>
                    <TableCell>{lastName}</TableCell>
                    <TableCell>{firstName}</TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>{buyerType}</TableCell>
                    <TableCell>
                      <CustomDeletePopUp
                        isDocumentPage={true}
                        selectedIds={[`${id}`]}
                        resource={basePath}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              <Typography variant="body2">No homes</Typography>
            )}
          </>
        ) : (
          <Table>
            <TableHeading tableHeadings={tableHeadings} />
            <TableBody>
              {data &&
                data.data.map((home) => (
                  <TableRow key={home.id}>
                    <TableCell>
                      {home.elevationURL ? (
                        home.elevationURL && <CustomElevation elevationURL={home.elevationURL} />
                      ) : (
                        <DefaultElevationImage />
                      )}
                    </TableCell>
                    <TableCell>{home.planDescription}</TableCell>
                    <TableCell>{home.planNumber}</TableCell>
                    <TableCell>
                      <HomAddress record={home} />
                    </TableCell>
                    <TableCell>{changeAreaName(home.division.id, home.division.name)}</TableCell>
                    <TableCell>{changeAreaName(home.community.id, home.community.name)}</TableCell>
                    {isCorpAdmin && (
                      <TableCell>
                        <CustomDeletePopUp
                          isDocumentPage={true}
                          selectedIds={[`${id}/${home.id}`]}
                          resource={basePath}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};

const TableHeading = ({ tableHeadings }) => {
  return (
    <TableHead>
      <TableRow>
        {tableHeadings?.map((heading, index) => {
          return (
            <TableCell key={index}>
              <strong>{heading}</strong>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
export const CustomElevation = (props) => {
  const [tempUrl, setTempUrl] = useState();
  const [error, setError] = useState("");

  const fetchMyAPI = useCallback(async () => {
    const response = await instance.get(
      `${BASE_URL}/getDownloadUrl?fileName=${props.elevationURL}`
    );
    if (response.status === 200) {
      setTempUrl(response.data[0]);
    } else {
      setError("Error while fetching elevation");
    }
  }, [props.elevationURL]);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  return (
    <div style={{ display: "flex" }}>
      {tempUrl && (
        <img src={tempUrl} title="image" alt="ElevationImage" className="homeimg-wrapper" />
      )}
      {error && <div>{error}</div>}
    </div>
  );
};
