import * as React from "react";
import {
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
} from "react-admin";
import { WriteToolbarWithoutValidation } from "../../Components";

export const EnvisionEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify("Your changes have been updated", "success");
    redirect(`/envision`);
  };
  const onFailure = (error) => {
    notify(error.message, "error");
  };

  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      onSuccess={onSuccess}
      onFailure={onFailure}
      title="Update Envision Url"   
    >
      <SimpleForm toolbar={<WriteToolbarWithoutValidation/>}>
        <TextField source="Edit Envision Url" />
        <TextInput source="envisionUrl" label="Envision Url" validate={[required()]} multiline={true} />
      </SimpleForm>
    </Edit>
  );
};
