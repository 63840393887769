export const DivisionIcon = ({ activeColor }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M499.684,187.554l-72.414-72.405l-18.099,18.099c-1.664,1.664-3.849,2.5-6.033,2.5s-4.369-0.836-6.033-2.5
        c-3.337-3.337-3.337-8.738,0-12.066l18.099-18.099l-72.405-72.414c-3.336-3.328-8.738-3.328-12.066,0l-81.98,81.98V8.533
        c0-4.719-3.814-8.533-8.533-8.533h-102.4v25.6c0,4.71-3.814,8.533-8.533,8.533c-4.71,0-8.533-3.823-8.533-8.533V0h-102.4
        C13.643,0,9.82,3.814,9.82,8.533v494.933c0,4.71,3.823,8.533,8.533,8.533h102.4v-25.6c0-4.719,3.823-8.533,8.533-8.533
        c4.719,0,8.533,3.814,8.533,8.533V512h102.4c4.719,0,8.533-3.823,8.533-8.533v-52.924L499.684,199.62
        C503.012,196.292,503.012,190.891,499.684,187.554z M137.82,437.035c0,4.71-3.814,8.533-8.533,8.533
        c-4.71,0-8.533-3.823-8.533-8.533v-32.922c0-4.719,3.823-8.533,8.533-8.533c4.719,0,8.533,3.814,8.533,8.533V437.035z
        M137.82,354.748c0,4.719-3.814,8.533-8.533,8.533c-4.71,0-8.533-3.814-8.533-8.533v-32.913c0-4.719,3.823-8.533,8.533-8.533
        c4.719,0,8.533,3.814,8.533,8.533V354.748z M137.82,272.452c0,4.719-3.814,8.533-8.533,8.533c-4.71,0-8.533-3.814-8.533-8.533
        v-32.905c0-4.719,3.823-8.533,8.533-8.533c4.719,0,8.533,3.814,8.533,8.533V272.452z M137.82,190.174
        c0,4.719-3.814,8.533-8.533,8.533c-4.71,0-8.533-3.814-8.533-8.533v-32.922c0-4.71,3.823-8.533,8.533-8.533
        c4.719,0,8.533,3.823,8.533,8.533V190.174z M137.82,107.887c0,4.719-3.814,8.533-8.533,8.533c-4.71,0-8.533-3.814-8.533-8.533
        V74.974c0-4.719,3.823-8.533,8.533-8.533c4.719,0,8.533,3.814,8.533,8.533V107.887z M258.319,284.1l-12.066,12.066
        c-1.664,1.664-3.849,2.5-6.033,2.5c-2.185,0-4.361-0.836-6.033-2.5c-3.328-3.337-3.328-8.738,0-12.066l12.066-12.066
        c3.336-3.337,8.738-3.337,12.066,0C261.655,275.362,261.655,280.764,258.319,284.1z M316.346,226.074l-23.211,23.211
        c-1.664,1.664-3.849,2.5-6.033,2.5c-2.176,0-4.361-0.836-6.033-2.5c-3.328-3.337-3.328-8.738,0-12.075l23.211-23.202
        c3.337-3.337,8.738-3.337,12.066,0C319.682,217.335,319.682,222.737,316.346,226.074z M374.364,168.055l-23.211,23.211
        c-1.664,1.664-3.849,2.5-6.033,2.5s-4.361-0.836-6.033-2.5c-3.328-3.337-3.328-8.738,0-12.066l23.211-23.211
        c3.337-3.337,8.73-3.337,12.066,0C377.7,159.326,377.7,164.719,374.364,168.055z" fill={activeColor}/>
    </svg>
  );
};
