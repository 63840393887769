import * as React from "react";
import {
  TextField,
  Create,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { TYPE } from "../../Constants";
import {
  CustomCommunitiesListInput,
  CustomDivisionsListInput,
  WriteToolbarWithoutValidation,
} from "../../Components";
import { formatWord, maxLength } from "../../Utils";

export const AnnouncementCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [index] = React.useState(props.basePath.lastIndexOf("/"));
  const [type] = React.useState(props.basePath.substring(index + 1));
  const onSuccess = () => {
    notify("Announcement created", "success");
    redirect(`/announcementsAdmin/${type}`);
  };
  const onFailure = (error) => {
    notify(error.body.error, "error");
  };
  const transform = (data) => {
    return {
      ...data,
      announcementType: type,
    };
  };
  const { permissions } = props;
  const role = permissions && permissions.roles[0];
  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure} transform={transform}>
      <SimpleForm toolbar={<WriteToolbarWithoutValidation />}>
        <TextField source="Create Announcement" />
        <TextInput
          source="announcementType"
          label="Announcement Type"
          defaultValue={formatWord(type)}
          disabled
        />
        <TextInput
          source="title"
          label=" Announcement Title"
          validate={[required(), maxLength(40)]}
          multiline={true}
        />
        <RichTextInput
          source="description"
          toolbar={[["bold", "italic", "underline", "link"]]}
          label="Announcement Description"
          fullWidth={false}
          validate={[required(), maxLength()]}
        />
        {type === TYPE.DIVISIONS ? (
          <CustomDivisionsListInput role={role} />
        ) : (
          <CustomCommunitiesListInput role={role} />
        )}
      </SimpleForm>
    </Create>
  );
};
