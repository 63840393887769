import * as React from "react";
import { CustomCreateButton } from "../../Components";
import { TYPE, ADMIN_ROLES } from "../../Constants";
import { HomesList } from "../Homes/List";
import { DivisionList } from "../Divisions/List";
import { CommunitiesList } from "../Communities/List";

export const PostDivisionsList = (props) => {
  const index = props.basePath.lastIndexOf("/");
  const type = props.basePath.substring(index + 1);
  return (
    <DivisionList
      title="Division Posts"
      hasCreate={false}
      basePath={`/posts/divisions`}
      resource={`divisions`}
      actions={<CustomCreateButton basePath={type} label="Create Post" />}
    />
  );
};

export const PostCommunitiesList = (props) => {
  const { permissions } = props;
  const isSalesAdmin = permissions && permissions.roles.includes(ADMIN_ROLES.SALES);
  const index = props.basePath.lastIndexOf("/");
  const type = props.basePath.substring(index + 1);

  return (
    <CommunitiesList
      title="Community Posts"
      type={type}
      hasCreate={false}
      basePath={`/posts/communities`}
      resource={`communities`}
      actions={
        isSalesAdmin && type === TYPE.DIVISIONS ? (
          false
        ) : (
          <CustomCreateButton basePath={`communities`} label="Create Post" />
        )
      }
    />
  );
};

export const PostHomesList = () => {
  return (
    <HomesList
      title="Home Posts"
      hasCreate={false}
      basePath={`/posts/homes`}
      resource={`homes`}
      actions={<CustomCreateButton basePath={`homes`} label="Create Post" />}
    />
  );
};
