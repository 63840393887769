import * as React from "react";
import {
  Edit,
  SimpleForm,
  required,
  TextInput,
  ImageInput,
  useNotify,
  useRedirect,
} from "react-admin";
import { PreviewImage, WriteToolbarWithoutValidation } from "../../Components";
import { maxLength, validateFileAndCount } from "../../Utils";
import "../../assets/css/loader.css";

export const MortageEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const transform = (data) => {
    let returnObject = {
      id: data.id,
      link: data.link,
      title: data.title,
      description: data.description,
    };
    if (data.logo && data.logo.rawFile) {
      returnObject = {
        ...returnObject,
        file: data.logo,
      };
    }
    return returnObject;
  };

  const onSuccess = ({ data }) => {
    redirect("/mortage");
    notify("Your changes has been updated", "success");
  };

  const onFailure = (error) => {
    notify(error.body.error, "error");
  };

  return (
    <Edit
      title={"Edit Mortage"}
      {...props}
      mutationMode="optimistic"
      onSuccess={onSuccess}
      onFailure={onFailure}
      transform={transform}
    >
      <SimpleForm toolbar={<WriteToolbarWithoutValidation />}>
        <TextInput source="title" validate={[required(), maxLength(30)]} />
        <TextInput source="description" multiline={true} validate={[required(), maxLength()]} />
        <TextInput source="link" validate={required()} />
        <ImageInput
          validate={[required(), async (image) => await validateFileAndCount([image])]}
          accept="image/png, image/jpeg,image/jpg"
          source="logo"
          label="Logo"
          helperText="For a better user experience, we recommend uploading image with dimensions 130 * 86 (3:2)"
          placeholder={<p className="upload-link">Click here to upload an Image</p>}
        >
          <PreviewImage source="logo" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};
