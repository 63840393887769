import * as React from "react";
import { Admin } from "react-admin";
import { authProvider, myDataProvider } from "./Providers/index";
import { CustomLoginPage, CustomLogoutButton, Dashboard } from "./Pages/index";
import { CustomLayout } from "./Components";
import { routes } from "./routes";
import { handlePermissions } from "./Utils";

const App = () => {
  return (
    <Admin
      dataProvider={myDataProvider}
      authProvider={authProvider}
      loginPage={CustomLoginPage}
      layout={CustomLayout}
      logoutButton={CustomLogoutButton}
      customRoutes={routes}
      dashboard={Dashboard}
    >
      {(permissions) => handlePermissions(permissions)}
    </Admin>
  );
};

export default App;
