import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import instance from "../../Core/service";
import { BASE_URL } from "../../Constants";
import { validateEmail } from "../../Utils";
import { useNotify } from "react-admin";

export default function AlertDialog() {
  const notify = useNotify();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEmail("");
  };

  const handleForgetPassword = async (email) => {
    const response = await instance.post(`${BASE_URL}/user/forgetPassword/${email}`);
    if (response.status === 200) {
      notify(response.data.message, "success");
    }
    return false;
  };

  const handleSubmit = async () => {
    if (email.length) {
      try {
        if (validateEmail(email)) {
          await handleForgetPassword(email);
          setOpen(false);
        } else {
          notify("Invalid Email", "error");
        }
      } catch (error) {
        const status = error.response.status;
        if (status === 404 || status === 403) {
          const message = error.response.data.error;
          notify(message, "error");
        } else {
          notify("Please try again", "error");
        }
      }
    }
  };

  const handleOnChange = (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Forgot Password?
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Send Password Reset Instruction</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Instruction will be sent to the Following Email
          </DialogContentText>
          <TextField
            id="outlined-basic"
            label="Email"
            type="email"
            variant="outlined"
            style={{ width: "100%" }}
            onChange={handleOnChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Send Email
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
