import "../../assets/css/theme.css";
import {
  Datagrid,
  TextField,
  ImageField,
  List,
  FunctionField,
  Button,
  useRecordContext,
  TextInput,
  ShowButton,
} from "react-admin";
import React from "react";
import Icon from "@material-ui/icons/ControlPoint";
import { HomeExpand } from "./ListExpand";
import { RECORDS_PER_PAGE, SPRINT_URL, TYPE } from "../../Constants";
import {
  PostPagination,
  HomAddress,
  DefaultElevationImage,
  CustomSeePostsButton,
} from "../../Components";
import { WarrantyExpand } from "../Warranty/ListExpand";
import { changeAreaName } from "../../Utils";

export const HomesList = (props) => {
  const { basePath, actions, sort, perPage } = props;
  const isPostPage = basePath.includes("posts");
  const isUserWarrantyPage = basePath.includes("userWarranty");
  const isDesignCenterPage = basePath.includes("designCenter");
  const filters = [
    <TextInput
      label="Search"
      source="q"
      placeholder="Name, Division, Community, Buyer Name, Buyer Email"
      alwaysOn
    />,
  ];
  return (
    <div>
      <List
        {...props}
        sort={sort ? sort : { field: "submittedDate", order: "DESC" }}
        actions={actions ? actions : false}
        bulkActionButtons={false}
        perPage={perPage ? perPage : RECORDS_PER_PAGE + 25}
        pagination={<PostPagination />}
        filters={filters}
      >
        <Datagrid expand={!isDesignCenterPage ? (isUserWarrantyPage ? WarrantyExpand : HomeExpand) : undefined}>
          <CustomElevation label="Elevation" />
          <TextField source="planDescription" label="Plan Description" sortable={false} />
          <TextField source="planNumber" label="Plan Number" sortable={false} />
          <FunctionField
            label="Home Address"
            render={(record) => {
              return <HomAddress record={record} />;
            }}
          />
          <FunctionField
            label="Division"
            render={(record) => {
              return <p>{changeAreaName(record.division.id, record.division.name)}</p>;
            }}
          />
          <FunctionField
            label="Community"
            render={(record) => {
              return <p>{changeAreaName(record.community.id, record.community.name)}</p>;
            }}
          />
          {isPostPage && <CustomSeePostsButton type={TYPE.HOMES} label="Action" />}
          {isDesignCenterPage && <ShowButton label="Images" />}
        </Datagrid>
      </List>
    </div>
  );
};

const CustomElevation = (props) => {
  const record = useRecordContext(props);
  const { basePath } = props;
  const isHomePage = basePath === `/${TYPE.HOMES}`;
  return (
    <div style={{ display: "flex" }}>
      {record && record.elevationURL ? (
        <ImageField
          source="elevationURL"
          label="Elevation"
          sortable={false}
          className="homeimg-wrapper"
        />
      ) : (
        <DefaultElevationImage />
      )}
      {isHomePage && (
        <Button
          onClick={() => {
            window.location.href = `${SPRINT_URL}/#/homes/${record.id}`;
          }}
        >
          <Icon />
        </Button>
      )}
    </div>
  );
};
