import { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { ChangePasswordPopup } from "./ChangePasswordPopup";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

export const CustomChangePasswordButton = () => {
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);

  const handleChangePasswordClick = () => {
    setIsChangePasswordOpen(true);
  };

  return (
    <>
      <MenuItem onClick={handleChangePasswordClick}>
        <LockOutlinedIcon /> Change Password
      </MenuItem>
      {isChangePasswordOpen ? (
        <ChangePasswordPopup popupState={{ isChangePasswordOpen, setIsChangePasswordOpen }} />
      ) : null}
    </>
  );
};
