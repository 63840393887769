import { hasDuplicates, resizeImage } from ".";
import { FILE_SIZE_TYPES } from "../Constants";

export const maxLength =
  (max = 700) =>
  (value) =>
    value && value.length > max ? `**Maximum character allowed is ${max}` : undefined;

const bytesInOneUnit = {
  [FILE_SIZE_TYPES.B]: 1,
  [FILE_SIZE_TYPES.KB]: 1024,
  [FILE_SIZE_TYPES.MB]: 1024 * 1024,
  [FILE_SIZE_TYPES.GB]: 1024 * 1024 * 1024,
};

export const validateFileSize = (
  maxSize = 500,
  sizeType = FILE_SIZE_TYPES.KB,
  errorMessage = "File size exceeds the maximum limit"
) => {
  return (uploadingFile) => {
    if (
      uploadingFile &&
      uploadingFile.rawFile &&
      uploadingFile.rawFile.size > maxSize * bytesInOneUnit[sizeType]
    ) {
      return errorMessage;
    }
    return undefined;
  };
};

export const validateFileAndCount = async (images, maxSizeInMb = 0.5, maxCount = 1) => {
  const validateSize = validateFileSize(maxSizeInMb, FILE_SIZE_TYPES.MB);
  if (images) {
    if (images.length > maxCount) {
      return `Only ${maxCount} images can be uploaded in one post`;
    }
    for (let i = 0; i < images.length; i++) {
      const sizeError = validateSize(images[i]);
      if (sizeError) {
        images[i].rawFile = await resizeImage(images[i].rawFile, maxSizeInMb);
        if (validateSize(images[i])) {
          return sizeError;
        }
      }
    }
  }
  return undefined;
};
export const validateCommunityDocumentCreation = (values) => {
  if (values && values.length >= 2) {
    const communities = values.map((value) => (value ? value.communityId : ""));
    const status = hasDuplicates(communities);
    if (status) return "Please enter different communities";
  }
  return undefined;
};
export const validateDivisionDocumentCreation = (values) => {
  if (values && values.length >= 2) {
    const divisions = values.map((value) => (value ? value.divisionId : ""));
    const status = hasDuplicates(divisions);
    if (status) return "Please enter different divisions";
  }
  return undefined;
};

export const validateHomeDocumentCreation = (values) => {
  if (values && values.length >= 2) {
    const homes = values.map((value) => (value ? value.homeId : ""));
    const status = hasDuplicates(homes);
    if (status) return "Please enter different homes";
  }
  return undefined;
};

export const validateFileAddition = (values) => {
  if (values && values.length >= 2) {
    const files = values.map((value) =>
      value ? (value.location && value.location !== null ? value.location.title : "") : ""
    );
    const status = hasDuplicates(files);
    if (status) return "Please input different files";
  }
  return undefined;
};

export const validateEmail = (input) => {
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (input.match(validRegex)) {
    return true;
  } else {
    return false;
  }
};

export const isValidPassword = (password) => {
  const MIN_LENGTH = 8;
  const MAX_LENGTH = 20;
  const PASSWORD_REGEX =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?!.*\s).{8,20}$/;
  // Password must contain at least one digit, one lowercase and one uppercase letter, one special character, and must not contain whitespace.

  if (password.length < MIN_LENGTH || password.length > MAX_LENGTH) {
    return false;
  }

  if (!PASSWORD_REGEX.test(password)) {
    return false;
  }

  return true;
};

export const checkForDuplicateValues = (key, message = "Please enter different values") => {
  return (values) => {
    if (values && values.length >= 2) {
      const keys = values.map((value) => (value ? value[key] : ""));
      const status = hasDuplicates(keys);
      if (status) return message;
    }
    return undefined;
  };
};

export const validateDocumentFileSize = (
  maxSize = 25,
  sizeType = FILE_SIZE_TYPES.MB,
  errorMessage = "Some files exceeds the maximum limit"
) => {
  return (uploadingFiles) => {
    const fileCount = uploadingFiles?.length;
    if (fileCount < 1 || fileCount > 20) {
      return "Number of files must be between 1 and 20";
    }
    if (fileCount) {
      for (let file of uploadingFiles) {
        if (file && file.size > maxSize * bytesInOneUnit[sizeType]) {
          return errorMessage;
        }
      }
    }
    return undefined;
  };
};
