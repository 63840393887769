export const PhotosIcon = ({ activeColor }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 5C5.74619 5 5 5.74619 5 6.66667V25.3333C5 26.2538 5.74619 27 6.66667 27H25.3333C26.2538 27 27 26.2538 27 25.3333V6.66667C27 5.74619 26.2538 5 25.3333 5H6.66667ZM3 6.66667C3 4.64162 4.64162 3 6.66667 3H25.3333C27.3584 3 29 4.64162 29 6.66667V25.3333C29 27.3584 27.3584 29 25.3333 29H6.66667C4.64162 29 3 27.3584 3 25.3333V6.66667Z"
        fill={activeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3333 10.3334C10.7811 10.3334 10.3333 10.7811 10.3333 11.3334C10.3333 11.8857 10.7811 12.3334 11.3333 12.3334C11.8856 12.3334 12.3333 11.8857 12.3333 11.3334C12.3333 10.7811 11.8856 10.3334 11.3333 10.3334ZM8.33334 11.3334C8.33334 9.67652 9.67649 8.33337 11.3333 8.33337C12.9902 8.33337 14.3333 9.67652 14.3333 11.3334C14.3333 12.9902 12.9902 14.3334 11.3333 14.3334C9.67649 14.3334 8.33334 12.9902 8.33334 11.3334Z"
        fill={activeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6262 12.6263C21.0167 12.2357 21.6499 12.2357 22.0404 12.6263L28.7071 19.2929C29.0976 19.6835 29.0976 20.3166 28.7071 20.7071C28.3166 21.0977 27.6834 21.0977 27.2929 20.7071L21.3333 14.7476L7.37376 28.7071C6.98324 29.0977 6.35007 29.0977 5.95955 28.7071C5.56903 28.3166 5.56903 27.6835 5.95955 27.2929L20.6262 12.6263Z"
        fill={activeColor}
      />
    </svg>
  );
};
