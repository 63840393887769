export const TableHead = ({ isNorthShore }) => {
  return (
    <thead>
      <tr>
        <th className="table-s-no"></th>
        <th className="table-title">Title</th>
        <th className="table-item">Items:</th>
        <th className="table-response" align="center">
          {isNorthShore ? "NSB" : "WRH"} <br /> Response
        </th>
        <th className="table-trade" align="center">
          {isNorthShore ? "NSB" : "WRH"} <br /> Trade
        </th>
        <th className="table-status" align="center">
          Complete <br /> Initial
        </th>
      </tr>
    </thead>
  );
};
