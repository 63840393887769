import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNotify } from "react-admin";
import "../../assets/css/loader.css";
import { Divider, Chip, Typography, TextField, makeStyles, Button } from "@material-ui/core";
import { MessageByDate } from "./MessagesByDate";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "../../firebaseConfig";
import { updateConversationMessageDateTime, setMessage } from "../../Core/conversation";
import { getFullDate } from "../../Utils";

const useStyles = makeStyles((theme) => ({
  megMain: {
    background: "#fafafa",
    position: "relative",
    display: "flex",
    maxWidth: "950px",
    flexDirection: "column",
    padding: "1em",
    margin: "0 auto",
    height: "calc(100vh - 80px)",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  megScroll: {
    overflow: "auto",
    marginBottom: "20px",
  },
  megHolder: {
    display: "flex",
    flexDirection: "column",
  },
  megDivider: {
    position: "relative",
    display: "inline-block",
    width: "100%",
  },
  dividerMargin: {
    margin: "35px 0",
  },
  dividerDate: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    background: "rgb(242, 242, 242)",
    color: "#585858",
  },
  megForm: {
    display: "grid",
    gridTemplateColumns: "1fr 96px",
    marginTop: "auto",
    alignItems: "flex-end",
  },
  formButton: {
    height: "40px",
    background: "rgb(85, 137, 199)",
    color: "rgb(255, 255, 255)",
    "&:hover": {
      background: "rgb(85, 137, 199)",
      color: "rgb(255, 255, 255)",
    },
  },
}));

export const Messages = ({ conversationRecord }) => {
  const { id: conversationId, isAdminAllowedToMessage, adminData, buyerData } = conversationRecord;

  const messagesRef = db.collection(`conversations/${conversationId}/messages`);
  const [realTimeMessages, isLoadingMessages, isLoadingMessagesError] =
    useCollectionData(messagesRef);
  // eslint-disable-next-line no-unused-vars
  const [scrollPositionFromBottom, setScrollPositionFromBottom] = useState(0);

  useEffect(() => {
    if (realTimeMessages) {
      setMessages(realTimeMessages);
    }
  }, [realTimeMessages]);

  const classes = useStyles();
  const notify = useNotify();

  const [isLoadingSendMessages, setIsLoadingSendMessages] = useState(false);
  const [messages, setMessages] = useState([]);
  const messageRef = useRef();

  useEffect(() => {
    const messagesScroll = document.getElementById("messages_scroll");
    if (messagesScroll) {
      let scrollPosition = messagesScroll.scrollHeight;
      messagesScroll.scrollTop = scrollPosition;
    }
  }, [scrollPositionFromBottom, messages]);

  const adminName = useMemo(() => `${adminData.firstName} ${adminData.lastName}`, [adminData]);

  const buyerName = useMemo(() => `${buyerData.firstName} ${buyerData.lastName}`, [buyerData]);

  const handleSendMessage = async () => {
    if (messageRef && messageRef.current && messageRef.current.value.trim().length > 0) {
      try {
        setIsLoadingSendMessages(true);
        const messageObj = {
          from: "admin",
          message: messageRef.current.value.trim(),
          dateTime: new Date().toISOString(),
        };
        messageRef.current.value = "";
        await setMessage(messageObj, conversationId);
        await updateConversationMessageDateTime(conversationId, messageObj.dateTime);
      } catch (error) {
        notify("Sorry could not send message, please try again!", "error");
      } finally {
        setIsLoadingSendMessages(false);
      }
    }
  };

  return (
    <div className={classes.megMain}>
      <Typography variant="body1">
        <strong>
          {isAdminAllowedToMessage
            ? `Your conversation with ${buyerName}`
            : `${adminName}'s conversation with ${buyerName}`}
        </strong>
      </Typography>
      {isLoadingMessages ? (
        <div className={"loader"}></div>
      ) : (
        <div id="messages_scroll" className={classes.megScroll}>
          {messages ? (
            <div>
              {messages.map((data) => {
                const messages = data.messages;
                const date = data.createdAt && getFullDate(data.createdAt);
                return (
                  <div className={classes.megHolder}>
                    {/* Date Seperator */}
                    <Typography component="div" className={classes.megDivider}>
                      <Divider className={classes.dividerMargin} />
                      <Chip label={date} className={classes.dividerDate} />
                    </Typography>
                    <MessageByDate messages={messages} record={conversationRecord} />
                  </div>
                );
              })}
            </div>
          ) : isLoadingMessagesError ? (
            <React.Fragment>
              <Typography variant="body2">Oops!</Typography>
              <Typography variant="body2">
                Something went wrong. Try again later or contact admin
              </Typography>
            </React.Fragment>
          ) : (
            <Typography variant="h6">No messages yet. Start chat?</Typography>
          )}
        </div>
      )}
      {isAdminAllowedToMessage ? (
        <div className={classes.megForm}>
          <TextField
            id="standard-basic"
            label="Message"
            multiline={true}
            maxRows={4}
            inputRef={messageRef}
          />
          <Button
            disabled={isLoadingMessages || isLoadingSendMessages}
            onClick={() => handleSendMessage()}
            variant="contained"
            className={classes.formButton}
          >
            Send
          </Button>
        </div>
      ) : (
        <div className={classes.megForm}>
          You no longer have access to this home. Contact super admin in case you need help.
        </div>
      )}
    </div>
  );
};
