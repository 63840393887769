import * as React from "react";
import { Loading, useQuery, SaveButton } from "react-admin";
import { Toolbar } from "@material-ui/core";
import { PostEdit } from "./Edit";
export const PostEditWriteToolbar = (props) => {
  return (
    <Toolbar>
      <SaveButton
        disabled={props.disabled}
        handleSubmitWithRedirect={() => props.handleSubmitWithRedirect()}
      />
    </Toolbar>
  );
};

export const PostEditWrapper = () => {
  const id = window.location.href.split("/").slice(-1)[0];
  const type = window.location.href.split("/").slice(-3)[0];

  const { loading, error, data } = useQuery({
    type: "getOne",
    resource: "posts",
    payload: { id },
  });

  if (loading) return <Loading />;

  return (
    <React.Fragment>
      {error && <div>Something went wrong, please try again or contact admin</div>}
      {data && <PostEdit postData={data} postType={type} />}
    </React.Fragment>
  );
};
