export const DashboardIcon = ({ activeColor }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 5C5.74619 5 5 5.74619 5 6.66667V25.3333C5 26.2538 5.74619 27 6.66667 27H25.3333C26.2538 27 27 26.2538 27 25.3333V6.66667C27 5.74619 26.2538 5 25.3333 5H6.66667ZM3 6.66667C3 4.64162 4.64162 3 6.66667 3H25.3333C27.3584 3 29 4.64162 29 6.66667V25.3333C29 27.3584 27.3584 29 25.3333 29H6.66667C4.64162 29 3 27.3584 3 25.3333V6.66667Z"
        fill={activeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 11.4477 3.44772 11 4 11H28C28.5523 11 29 11.4477 29 12C29 12.5523 28.5523 13 28 13H4C3.44772 13 3 12.5523 3 12Z"
        fill={activeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11C12.5523 11 13 11.4477 13 12V28C13 28.5523 12.5523 29 12 29C11.4477 29 11 28.5523 11 28V12C11 11.4477 11.4477 11 12 11Z"
        fill={activeColor}
      />
    </svg>
  );
};
