import * as React from "react";
import { Datagrid, TextField, List, FunctionField, TextInput } from "react-admin";
import { DescriptionExpand } from "./ListExpand";
import { ADMIN_ROLES, RECORDS_PER_PAGE, TYPE } from "../../Constants";
import {
  PostPagination,
  CustomDeletePopUp,
  CustomCreateButton,
  CustomEditButton,
} from "../../Components";
import { changeAreaName, getFullDate, getName } from "../../Utils";
import EyeIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  iconAlign: {
    display: "flex",
    alignItems: "center",
  },
}));
export const DocumentList = (props) => {
  const classes = useStyles();
  const { permissions, basePath } = props;
  const isHomeSpecific = basePath.includes(TYPE.HOMES);
  const isDivisionSpecific = basePath.includes(TYPE.DIVISIONS);
  const isCommunitySpecific = basePath.includes(TYPE.COMMUNITIES);
  const isWarrantySpecific = basePath.includes(TYPE.WARRANTY);
  const isCorpAdmin = permissions && permissions.roles.includes(ADMIN_ROLES.CORPORATE);
  const isSalesAdmin = permissions && permissions.roles.includes(ADMIN_ROLES.SALES);

  const condition = ((isCorpAdmin && isWarrantySpecific) || !isWarrantySpecific) && !isSalesAdmin;

  const handleViewFile = (fileLocation) => {
    window.open(fileLocation, "_blank");
  };

  const filters = [
    <TextInput
      label="Search"
      source="q"
      placeholder={
        isCommunitySpecific
          ? "Title, Community"
          : isDivisionSpecific
          ? "Title, Division"
          : isHomeSpecific
          ? "Title, Community, Home Name"
          : ""
      }
      alwaysOn
    />,
  ];
  return (
    <List
      {...props}
      sort={{ field: "uploadedAt", order: "desc" }}
      bulkActionButtons={condition && <CustomDeletePopUp />}
      actions={condition && <CustomCreateButton label="Create Document" basePath={basePath} />}
      perPage={RECORDS_PER_PAGE}
      empty={condition ? undefined : false}
      pagination={<PostPagination />}
      filters={!isWarrantySpecific && filters}
    >
      <Datagrid expand={isWarrantySpecific && DescriptionExpand}>
        {isHomeSpecific && <TextField source="home.name" label="Home Name" sortable={false} />}
        {isHomeSpecific && (
          <TextField source="home.address" label="Home Address" sortable={false} />
        )}
        {isDivisionSpecific && (
          <FunctionField
            label="Division Name"
            render={(record) => {
              return <p>{changeAreaName(record?.division?.id, record?.division?.name)}</p>;
            }}
          />
        )}
        {isCommunitySpecific && (
          <FunctionField
            label="Community Name"
            render={(record) => {
              return <p>{changeAreaName(record?.community?.id, record?.community?.name)}</p>;
            }}
          />
        )}
        <TextField source="title" sortable={false} />
        {isHomeSpecific && (
          <FunctionField
            label="Community Name"
            render={(record) => {
              return <p>{changeAreaName(record?.community?.id, record?.community?.name)}</p>;
            }}
          />
        )}
        <FunctionField
          label="Uploaded On"
          render={(record) => {
            return record.uploadedAt && getFullDate(record.uploadedAt);
          }}
        />
        <FunctionField
          label="Uploaded By"
          render={(record) => record.uploadedBy && getName(record.uploadedBy)}
        />
        <FunctionField
          className={classes.iconAlign}
          label="Action"
          render={(record) => {
            return (
              <React.Fragment>
                <EyeIcon
                  style={{ color: "#3f51b5" }}
                  onClick={() => handleViewFile(record.fileLocation)}
                />
                {condition && <CustomEditButton record={record} label="Action" />}
                {condition && (
                  <CustomDeletePopUp
                    isDocumentPage={true}
                    selectedIds={[record.id]}
                    resource={basePath}
                  />
                )}
              </React.Fragment>
            );
          }}
        />
      </Datagrid>
    </List>
  );
};
