import { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ExitIcon from "@material-ui/icons/PowerSettingsNew";
import { LogoutConfirmationPopup } from "./LogoutConfirmationPopup";

export const CustomLogoutButton = () => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleClick = () => {
    setIsConfirmationOpen(true);
  };

  return (
    <>
      <MenuItem onClick={handleClick}>
        <ExitIcon /> Logout
      </MenuItem>
      {isConfirmationOpen ? (
        <LogoutConfirmationPopup popupState={{ isConfirmationOpen, setIsConfirmationOpen }} />
      ) : null}
    </>
  );
};
