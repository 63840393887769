import { warrantyFormStyles } from "./Styles";
import { TableHead } from "./TableHead";

export const PrintTable = (props) => {
  const classes = warrantyFormStyles(props);
  const { warrantyData } = props;
  const isNorthShore =
    warrantyData?.homeInfo?.division?.id === "MHW-8" ||
    warrantyData?.homeInfo?.division?.id === "22";

  return (
    <table border="1" className={classes.tableFormInput}>
      <TableHead isNorthShore={isNorthShore} />
      <tbody>
        {warrantyData?.warrantyItems?.map((item, index) => {
          return (
            <tr key={index}>
              <td className="table-s-no">{index + 1}.</td>
              <td className="table-title">{item?.itemTitle}</td>
              <td className="table-item">{item?.itemDescription}</td>
              <td className="table-response">{item?.wrhResponse}</td>
              <td className="table-trade">{item?.wrhTrade}</td>
              <td className="table-status">{item?.completeInitial}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
