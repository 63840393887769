import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  TextField,
  required,
  email,
  AutocompleteInput,
  BooleanInput,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import { CHOOSE_ADMIN_ROLE } from "../../Constants";
import { WriteToolbar, AdminForm } from "../../Components";

export const AdminCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onFailure = (error) => {
    notify(error.body.error, "error");
  };

  const onSuccess = () => {
    notify(`Admin created successfully`, "success");
    redirect("/admins");
    refresh();
  };

  const transform = (data) => {
    const roles = [];
    roles.push(data.roles);
    return {
      ...data,
      roles,
    };
  };

  return (
    <Create {...props} onFailure={onFailure} onSuccess={onSuccess} transform={transform}>
      <SimpleForm toolbar={<WriteToolbar />}>
        <TextField source="Create Admin" />
        <TextInput label="Email Address" source="email" validate={[required(), email()]} />
        <TextInput source="firstName" validate={[required()]} />
        <TextInput source="lastName" validate={[required()]} />
        <AutocompleteInput validate={[required()]} source="roles" choices={CHOOSE_ADMIN_ROLE} />
        <AdminForm props={props} />
        <TextInput source="designation" validate={[required()]} />
        <BooleanInput label="Allow Conversation" source="conversationFlag" defaultValue={true} />
      </SimpleForm>
    </Create>
  );
};
