import * as React from "react";
import {
  required,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  FormDataConsumer,
  useNotify,
  Button,
} from "react-admin";
import { ADMIN_ROLES, BASE_URL } from "../Constants";
import instance from "../Core/service";
import { changeAreaName } from "../Utils";

export const AdminForm = (props) => {
  const { changedState } = props;
  return (
    <FormDataConsumer props={props}>
      {({ formData, ...rest }) => {
        if (formData.roles && formData.roles.includes(ADMIN_ROLES.CORPORATE)) {
          formData.divisions = [];
          formData.communities = [];
          return null;
        } else if (formData.roles && formData.roles.includes(ADMIN_ROLES.SALES)) {
          formData.divisions = [];
          return <RefArrayInput changedState={changedState} type={"communities"} />;
        } else if (formData.roles && formData.roles.includes(ADMIN_ROLES.OFFICE)) {
          formData.communities = [];
          return <RefArrayInput changedState={changedState} type={"divisions"} />;
        }
      }}
    </FormDataConsumer>
  );
};

export const ResendInvite = (props) => {
  const { record, permissions } = props;
  const notify = useNotify();
  const handleResendInvite = async () => {
    instance
      .get(`${BASE_URL}/adminInvite/resend/${record.id}`)
      .then((res) => {
        res.status === 200
          ? notify("Success Send", "success")
          : notify("Internal Server Error", "error");
      })
      .catch((e) => {
        notify("Internal Server Error", "error");
      });
  };

  return (
    <Button
      disabled={
        record.inviteStatus || (permissions && !permissions.roles.includes(ADMIN_ROLES.SUPER))
      }
      label={record.inviteStatus ? "Accepted" : "Resend Invite"}
      onClick={handleResendInvite}
    />
  );
};

const RefArrayInput = (props) => {
  const { type, changedState } = props;
  const optionRenderer = (choice) => choice && changeAreaName(choice.id, choice.name);

  return (
    <ReferenceArrayInput
      validate={[required()]}
      onChange={() => {
        changedState && changedState.setChanged(false);
      }}
      perPage={500}
      source={type}
      reference={type}
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteArrayInput optionText={optionRenderer} />
    </ReferenceArrayInput>
  );
};
