import * as React from "react";
import { Datagrid, ImageField, List, UrlField, TextField } from "react-admin";
import { CustomEditButton } from "../../Components";

export const MortageList = (props) => {
  return (
    <List
      pagination={false}
      {...props}
      sort={{ field: "link", order: "ASC" }}
      actions={false}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="title" sortable={false} label="Title" />
        <TextField source="description" sortable={false} label="Description" />
        <UrlField source="link" label="Link" sortable={false} />
        <ImageField
          source="logo"
          label="Logo"
          title="logo"
          sortable={false}
          className={"homeimg-mortage"}
        />
        <CustomEditButton basePath="/mortage" label="Action" />
      </Datagrid>
    </List>
  );
};
