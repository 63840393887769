import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  TextField,
  required,
  useNotify,
  useRedirect,
} from "react-admin";

export const WarrantyVideoUpload = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("Video uploaded", "success");
    redirect(`/assets/warranty`);
  };
  const onFailure = () => {
    notify("Try again", "error");
  };

  return (
    <Create
      onSuccess={onSuccess}
      onFailure={onFailure}
      {...props}
      title={"Upload warranty video"}
      resource="documentsAdmin/warranty/assets/video"
    >
      <SimpleForm>
        <TextField source="Upload Video" />
        <TextInput source="video" label="Video link" validate={[required()]} multiline={true} />
      </SimpleForm>
    </Create>
  );
};
