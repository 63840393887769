import { usePermissions } from "react-admin";
import { warrantyFormStyles } from "./Styles";
import { Button } from "@material-ui/core";
import { ADMIN_ROLES } from "../../../Constants";

export const FormActions = (props) => {
  const classes = warrantyFormStyles(props);
  const { handlePrint, saveDraft, responseMessage, loadingStates, errorMessages } = props;
  const { draft, pdf } = loadingStates;
  const { isDraftSaved } = draft;
  const { isPDFSaved } = pdf;
  const { permissions } = usePermissions();
  const isSalesAdmin = permissions && permissions.roles.includes(ADMIN_ROLES.SALES);

  return (
    <div className={classes.footerAction}>
      {responseMessage ? <div className={classes.footerNotification}>{responseMessage}</div> : null}
      {checkValidation(errorMessages) ? (
        <div className={classes.errorNotification}>{checkValidation(errorMessages)}</div>
      ) : null}
      <CustomButton
        isDisabled={isPDFSaved || checkValidation(errorMessages) ? true : false}
        handleOnClick={handlePrint}
        text={"Export PDF"}
      />
      <CustomButton
        isDisabled={
          isSalesAdmin ? true : isDraftSaved || checkValidation(errorMessages) ? true : false
        }
        handleOnClick={saveDraft}
        text={"Save Draft"}
      />
    </div>
  );
};

const CustomButton = ({ isDisabled, handleOnClick, text }) => {
  return (
    <Button
      disabled={isDisabled}
      onClick={handleOnClick}
      type="button"
      variant="contained"
      color="primary"
    >
      {text}
    </Button>
  );
};

const checkValidation = (obj) => {
  for (let key in obj) {
    if (obj[key] !== "") {
      return obj[key];
    }
  }
  return "";
};
