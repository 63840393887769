import * as React from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import { Button, CardActions, CircularProgress, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate, useLogin, useNotify, useSafeSetState } from "ra-core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import instance from "../../Core/service";
import { BASE_URL } from "../../Constants";
import { validateEmail } from "../../Utils";

const useStyles = makeStyles(
  (theme) => ({
    form: {
      padding: "0 1em 1em 1em",
    },
    input: {
      marginTop: "1em",
      position: "relative",
    },
    svgIcon: {
      position: "absolute",
      right: "0",
      bottom: "0",
      cursor: "pointer",
    },
    button: {
      width: "100%",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: "RaLoginForm" }
);

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);
const LoginForm = (props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);
  const [hidePassword, setHidePassword] = React.useState(true);
  const handlePasswordToggle = () => setHidePassword(!hidePassword);

  const validate = (values) => {
    const errors = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = translate("Username can not be blank");
    }
    if (!values.password) {
      errors.password = translate("Password can not be blank");
    }
    return errors;
  };

  const isUserLocked = async (email) => {
    if (validateEmail(email)) {
      const response = await instance.get(`${BASE_URL}/user/checkLockedUser/${email}`);
      if (response.status === 200) {
        if (response.data.status === "USER_NOT_BLOCKED") {
          return response.data;
        } else {
          notify(response.data.message, "error");
        }
      } else {
        notify("Please try again", "error");
      }
      return false;
    }
    notify("Email Invalid", "error");
    return false;
  };

  const updateCount = async (email, count) => {
    let url = `${BASE_URL}/user/updateLoginAttempt/${email}/${count}`;
    const response = await instance.put(url);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  };

  const submit = async (values) => {
    setLoading(true);
    const isUserUnlocked = await isUserLocked(values.username);
    if (isUserUnlocked) {
      login(values, redirectTo)
        .then(async () => {
          setLoading(false);
          if (isUserUnlocked.userAttempts !== 1) {
            await updateCount(values.username, 0);
          }
        })
        .catch(async (error) => {
          if (error.code === "auth/too-many-requests") {
            error && error.message && notify(error.message, "error");
            setLoading(false);
            return;
          }
          const response = await updateCount(values.username, 1);
          if (response.message) {
            notify(response.message, "error");
            setLoading(false);
            return;
          }
          setLoading(false);
          error && error.message && notify(error.message, "error");
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form className="login-input" onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                id="username"
                name="username"
                component={Input}
                label={translate("ra.auth.username")}
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="password"
                name="password"
                component={Input}
                label={translate("ra.auth.password")}
                type={hidePassword && "password"}
                disabled={loading}
                autoComplete="current-password"
              />
              {!hidePassword && (
                <VisibilityIcon className={classes.svgIcon} onClick={handlePasswordToggle} />
              )}
              {hidePassword && (
                <VisibilityOff className={classes.svgIcon} onClick={handlePasswordToggle} />
              )}
            </div>
          </div>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
            >
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              {translate("ra.auth.sign_in")}
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
};

export default LoginForm;
