import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  required,
  AutocompleteInput,
  BooleanInput,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { WriteToolbar, AdminForm } from "../../Components";
import { CHOOSE_ADMIN_ROLE } from "../../Constants";

const WithProps = ({ children, ...props }) => children(props);

export const AdminEdit = ({ record, ...props }) => {
  const [changed, setChanged] = React.useState(true);
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify("Your changes has been updated", "success");
    redirect("/admins");
    refresh();
  };

  const onFailure = (error) => {
    notify(error.body.error, "error");
  };

  return (
    <Edit
      {...props}
      mutationMode="optimistic"
      onSuccess={onSuccess}
      onFailure={onFailure}
      title={"Update Admin"}
    >
      <WithProps>
        {({ record, ...props }) => (
          <SimpleForm record={record} {...props} toolbar={<WriteToolbar changed={changed} />}>
            <TextField source="Edit Admin" />
            <TextInput
              onChange={() => setChanged(false)}
              disabled={record.inviteStatus === true}
              source="email"
              validate={[required()]}
            />
            <TextInput
              source="firstName"
              onChange={() => setChanged(false)}
              validate={[required()]}
            />
            <TextInput
              source="lastName"
              onChange={() => setChanged(false)}
              validate={[required()]}
            />
            <TextInput
              source="designation"
              onChange={() => setChanged(false)}
              validate={[required()]}
            />
            <AutocompleteInput
              validate={[required()]}
              source="roles[0]"
              label={"Roles"}
              choices={CHOOSE_ADMIN_ROLE}
              onChange={() => setChanged(false)}
            />
            <AdminForm changedState={{ changed, setChanged }} props={props} />
            <BooleanInput
              onChange={() => setChanged(false)}
              label="Allow Conversation"
              source="conversationFlag"
              defaultValue={true}
            />
            <BooleanInput
              onChange={() => setChanged(false)}
              disabled={record.inviteStatus === false}
              label="Status"
              source="status"
              defaultValue={false}
            />
            <BooleanInput
              label="Invite Status"
              source="inviteStatus"
              defaultValue={false}
              disabled
            />
          </SimpleForm>
        )}
      </WithProps>
    </Edit>
  );
};
