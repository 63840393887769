/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Loading, useRedirect, usePermissions, Title } from "react-admin";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import EditIcon from "@material-ui/icons/Edit";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Button,
  Toolbar,
} from "@material-ui/core";
import { ADMIN_ROLES, BASE_URL, TYPE } from "../../Constants";
import instance from "../../Core/service";
import { CustomDeletePopUp } from "../../Components";
import { getFullDate } from "../../Utils";
import { TypeMapping } from "../Documents/Edit";

const useStyles = makeStyles((theme) => ({
  widthSmall: {
    width: "160px",
  },
  widthLarge: {
    width: "300px",
  },
}));
export const SpecificPostsList = (props) => {
  const classes = useStyles(props);
  const redirect = useRedirect();
  const permissions = usePermissions();
  const role = permissions.loading === false ? permissions.permissions.roles[0] : "";
  const [index] = React.useState(props.basePath.lastIndexOf("/"));
  const [type] = React.useState(props.basePath.substring(index + 1));
  const [postType] = React.useState(
    type === TYPE.DIVISIONS
      ? TYPE.DIVISIONS
      : type === TYPE.COMMUNITIES
      ? TYPE.COMMUNITIES
      : type === TYPE.HOMES
      ? TYPE.HOMES
      : ""
  );
  const id = props.id;
  const [data, setData] = React.useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [isDelete, setIsDelete] = React.useState(false);

  const fetchData = async () => {
    instance
      .get(
        `${BASE_URL}/posts/${postType}/${id}?_end=${page * 10}&_order=DESC&_start=${
          (page - 1) * 10
        }`
      )
      .then((response) => response.data)
      .then((data) => {
        setData(data.data);
        setTotalRecords(data.totalRecords);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (isDelete) {
      fetchData();
      setIsDelete(false);
    }
  }, [isDelete]);

  React.useEffect(() => {
    fetchData();
  }, [page, id, postType]);

  return (
    <React.Fragment>
      <Title title={`${TypeMapping.get(type)} Posts`}/>
      {!loading ? (
        data.length > 0 ? (
          <React.Fragment>
            <Table className="post-table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Title</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Description</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Uploaded At</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Uploaded By</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Photos</strong>
                  </TableCell>
                  {permissions &&
                  role === ADMIN_ROLES.SALES &&
                  postType === TYPE.DIVISIONS ? null : (
                    <TableCell>
                      <strong>Action</strong>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((listData) => (
                    <TableRow key={listData.id}>
                      <TableCell>{listData.title}</TableCell>
                      <TableCell className={classes.widthLarge}>{listData.description}</TableCell>
                      <TableCell>{listData.createdAt && getFullDate(listData.createdAt)}</TableCell>
                      <TableCell>{`${listData.createdBy.firstName} ${listData.createdBy.lastName}`}</TableCell>
                      <TableCell className={classes.widthSmall}>
                        {listData.images.length > 1 ? (
                          <Carousel showThumbs={false} showStatus={false}>
                            {listData.images.map((image, index) => (
                              <img key={index} src={image} alt="Posts" />
                            ))}
                          </Carousel>
                        ) : (
                          <img className={"first-slide"} src={listData.images[0]} alt="Posts" />
                        )}
                      </TableCell>
                      {permissions &&
                      role === ADMIN_ROLES.SALES &&
                      postType === TYPE.DIVISIONS ? null : (
                        <TableCell>
                          <CustomDeletePopUp
                            selectedIds={[listData.id]}
                            resource={`posts/${postType}`}
                            setIsDelete={setIsDelete}
                          />
                          <EditIcon onClick={() => redirect(`editPost/${listData.id}`)}>
                            Edit
                          </EditIcon>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <PostPagination data={data} page={page} setPage={setPage} totalRecords={totalRecords} />
          </React.Fragment>
        ) : (
          <Box textAlign="center" m={5}>
            <Typography variant="h6">No posts available</Typography>
          </Box>
        )
      ) : (
        <Loading />
      )}
    </React.Fragment>
  );
};

const PostPagination = ({ data, page, setPage, totalRecords }) => {
  const lastPage = Math.ceil(totalRecords / 10);
  return (
    <Toolbar className="post-pagination">
      <Button
        variant="text"
        color="primary"
        key="prev"
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
      >
        <ChevronLeft />
        Prev
      </Button>
      <Button
        variant="text"
        color="primary"
        key="next"
        onClick={() => setPage(page + 1)}
        disabled={page === lastPage || data.length === 0}
      >
        Next
        <ChevronRight />
      </Button>
    </Toolbar>
  );
};
