import * as React from "react";
import { Datagrid, TextField, List } from "react-admin";
import { RECORDS_PER_PAGE } from "../../Constants";
import { CheckListExpand } from "./ListExpand";

export const TimelineList = (props) => {
  return (
    <React.Fragment>
      <List
        {...props}
        pagination={false}
        sort={{ field: "name", order: "ASC" }}
        actions={false}
        bulkActionButtons={false}
        perPage={RECORDS_PER_PAGE}
      >
        <Datagrid expand={CheckListExpand}>
          <TextField source="name" label="Project Phases" sortable={false} />
        </Datagrid>
      </List>
    </React.Fragment>
  );
};
