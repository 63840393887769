import * as React from "react";
import { CustomCreateButton } from "../../Components";
import { HomesList } from "../Homes/List";
import { usePermissions } from "react-admin";
import { ADMIN_ROLES } from "../../Constants";

export const DesignCenterHomesList = () => {
  const { permissions } = usePermissions();
  const videoAllowed = permissions && !permissions.roles.includes(ADMIN_ROLES.SALES);
  return (
    <HomesList
      title="Design Center"
      hasCreate={false}
      basePath={`/designCenter`}
      resource={`homes`}
      actions={
        videoAllowed ? <CustomCreateButton basePath={`designCenter`} label="Upload Video" /> : null
      }
    />
  );
};
