import * as React from "react";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";
import { isValidPassword } from "../../Utils";
import { BASE_URL } from "../../Constants";
import instance from "../../Core/service";
import { useNotify } from "react-admin";
import Input from "@material-ui/core/Input";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "calc(100% - 24px);",
  },
  footer: {
    justifyContent: "center",
  },
  customError: {
    color: "red",
    marginLeft: "15px",
    fontSize: "11px",
  },
}));

const PasswordField = ({
  passwords,
  identificationKey,
  isTabPressed,
  setIsTabPressed,
  handleKeyDown = () => {},
}) => {
  const classes = useStyles();
  const { passwords: passwordsObject, setPasswords } = passwords;
  const { label, value, error, disabled } = passwordsObject[identificationKey];
  const [showPassword, setShowPassword] = React.useState(false);

  const handleChange = (event) => {
    setPasswords({
      ...passwordsObject,
      [identificationKey]: { ...passwordsObject[identificationKey], value: event.target.value },
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    const el = document.getElementById(identificationKey);
    if (isTabPressed[identificationKey]) {
      el.focus();
    } else {
      el.blur();
    }
  }, [isTabPressed, identificationKey]);

  return (
    <>
      <FormControl
        error={error?.length ? true : false}
        className={clsx(classes.margin, classes.textField)}
        focused={isTabPressed[identificationKey] ? isTabPressed[identificationKey] : false}
      >
        <InputLabel
          disabled={disabled}
          error={error?.length ? true : false}
          focused={isTabPressed[identificationKey] ? isTabPressed[identificationKey] : false}
          htmlFor={`standard-adornment-password`}
        >
          {label}
        </InputLabel>
        <Input
          id={identificationKey}
          error={error?.length ? true : false}
          type={disabled ? "password" : showPassword ? "text" : "password"}
          value={value}
          disabled={disabled}
          onKeyDown={(e) => handleKeyDown(e, identificationKey)}
          onClick={() => {
            if (identificationKey !== "currentPassword")
              setIsTabPressed({
                currentPassword: false,
                newPassword: false,
                confirmNewPassword: false,
                [identificationKey]: true,
              });
          }}
          onChange={handleChange}
          endAdornment={
            disabled ? null : (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {disabled ? <VisibilityOff /> : showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </FormControl>
      {error?.length ? <div className={classes.customError}>{error}</div> : null}
    </>
  );
};

const PasswordFields = ({ passwords }) => {
  const [isTabPressed, setIsTabPressed] = React.useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const handleKeyDown = (event, identificationKey) => {
    if (event.keyCode === 9) {
      event.stopPropagation();
      if (identificationKey === "newPassword") {
        setIsTabPressed({
          currentPassword: false,
          newPassword: false,
          confirmNewPassword: true,
        });
      }
      if (identificationKey === "confirmNewPassword") {
        setIsTabPressed({
          currentPassword: false,
          newPassword: false,
          confirmNewPassword: false,
        });
      }
    }
  };

  return (
    <DialogContent>
      <DialogContentText id="alert-dialog-description"></DialogContentText>
      <PasswordField
        passwords={passwords}
        identificationKey={"currentPassword"}
        isTabPressed={isTabPressed}
      />
      <PasswordField
        passwords={passwords}
        identificationKey={"newPassword"}
        handleKeyDown={handleKeyDown}
        isTabPressed={isTabPressed}
        setIsTabPressed={setIsTabPressed}
      />
      <PasswordField
        passwords={passwords}
        identificationKey={"confirmNewPassword"}
        isTabPressed={isTabPressed}
        handleKeyDown={handleKeyDown}
        setIsTabPressed={setIsTabPressed}
      />
    </DialogContent>
  );
};
export const ChangePasswordPopup = ({ popupState }) => {
  const classes = useStyles();
  const { isChangePasswordOpen, setIsChangePasswordOpen } = popupState;

  const handleClose = () => {
    setIsChangePasswordOpen(false);
  };
  const notify = useNotify();
  const handleChangePassword = async (password) => {
    const response = await instance.post(`${BASE_URL}/user/changePassword`, {
      newPassword: password,
    });
    if (response.status === 200) {
      notify(response.data.message, "success");
    }
    return false;
  };

  const handleConfirm = async () => {
    if (handleValidations()) {
      try {
        await handleChangePassword(passwords.confirmNewPassword.value);
        handleClose();
      } catch (error) {
        const status = error.response.status;
        if (status === 404 || status === 403) {
          const message = error.response.data.error;
          notify(message, "error");
        } else {
          notify("Please try again", "error");
        }
      }
    }
  };

  const handleValidations = () => {
    return (
      handleEmptyCheck("newPassword") &&
      handleEmptyCheck("confirmNewPassword") &&
      validateEqualityOfPasswords() &&
      validateStrongPassword("confirmNewPassword")
    );
  };

  const validateEqualityOfPasswords = () => {
    if (passwords.newPassword.value === passwords.confirmNewPassword.value) {
      return true;
    }
    setPasswords({
      ...passwords,
      newPassword: {
        ...passwords.newPassword,
        error: "",
      },
      confirmNewPassword: {
        ...passwords.confirmNewPassword,
        error: "Confirm password should match with new password",
      },
    });
    return false;
  };

  const validateStrongPassword = (key) => {
    if (isValidPassword(passwords[key].value)) {
      return true;
    }
    setPasswords({
      ...passwords,
      [key]: {
        ...passwords[key],
        error:
          "Password must be 8-20 characters long and include at least one digit, one uppercase letter, one lowercase letter, and one special character.",
      },
    });
    return false;
  };
  const [passwords, setPasswords] = React.useState({
    currentPassword: {
      label: "Current Password",
      value: "1234567890",
      error: "",
      disabled: true,
    },
    newPassword: {
      label: "New Password",
      value: "",
      error: "",
      disabled: false,
    },
    confirmNewPassword: {
      label: "Confirm Password",
      value: "",
      error: "",
      disabled: false,
    },
  });

  const handleEmptyCheck = (key) => {
    if (!passwords[key]?.value) {
      setPasswords({
        ...passwords,
        [key]: { ...passwords[key], error: "Please enter the password" },
      });
      return false;
    }
    return true;
  };

  return (
    <Dialog
      open={isChangePasswordOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Change Password"}</DialogTitle>
      <PasswordFields passwords={{ passwords, setPasswords }} />
      <DialogActions className={classes.footer}>
        <Button onClick={handleClose} variant="contained" color="secondary">
          CANCEL
        </Button>
        <Button onClick={handleConfirm} variant="outlined">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
