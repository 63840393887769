import * as React from "react";
import {
  Menu,
  MenuItemLink,
  getResources,
  useTranslate,
  usePermissions,
  DashboardMenuItem,
  useLogout,
} from "react-admin";
import { useSelector } from "react-redux";
import DefaultIcon from "@material-ui/icons/ViewList";

import SubMenu from "./subMenu";
import { ADMIN_ROLES, ActiveIcon, Icon, activeColor, inActiveColor } from "../Constants";
import { WarrantyIcon } from "../assets/icons/Warranty";
export const CustomMenu = (props) => {
  const resources = useSelector(getResources);
  const permissions = usePermissions();
  const role = permissions.loading === false ? permissions.permissions.roles[0] : "";
  const translate = useTranslate();
  const [activeResourceName, setActiveResourceName] = React.useState();
  const logout = useLogout();
  const handleLogout = React.useCallback(() => {
    logout();
  }, [logout]);

  React.useEffect(() => {
    const handleInvalidToken = (e) => {
      const token = JSON.parse(window.localStorage.getItem("token"));
      if (!token) {
        handleLogout();
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, [handleLogout]);

  return (
    <>
      {permissions.loading === false ? (
        <Menu hasDashboard={true} {...props}>
          <DashboardMenuItem to="/" primaryText="Dashboard" leftIcon={ActiveIcon["dashboard"]} />
          {resources.map((resource) => {
            return !resource.name.includes("documentsAdmin/divisions") &&
              !resource.name.includes("documentsAdmin/communities") &&
              !resource.name.includes("documentsAdmin/homes") &&
              !resource.name.includes("announcementsAdmin") &&
              !resource.name.includes("posts") &&
              !resource.name.includes("assets") ? (
              role === "office" && resource.name === "communities" ? null : role === "rep" &&
                resource.name === "divisions" ? null : activeResourceName &&
                activeResourceName === resource.name ? (
                <MenuItemLink
                  key={resource.name}
                  to={`/${resource.name}`}
                  primaryText={(resource.options && resource.options.label) || resource.name}
                  leftIcon={
                    activeResourceName === "documentsAdmin/warranty" ? (
                      <WarrantyIcon activeColor={activeColor} />
                    ) : ActiveIcon[resource.name] ? (
                      ActiveIcon[resource.name]
                    ) : (
                      <DefaultIcon style={{ fill: inActiveColor }} />
                    )
                  }
                  onClick={() => setActiveResourceName(resource.name)}
                />
              ) : (
                <MenuItemLink
                  key={resource.name}
                  to={`/${resource.name}`}
                  primaryText={(resource.options && resource.options.label) || resource.name}
                  leftIcon={
                    resource.name === "documentsAdmin/warranty" ? (
                      <WarrantyIcon activeColor={inActiveColor} />
                    ) : Icon[resource.name] ? (
                      Icon[resource.name]
                    ) : (
                      <DefaultIcon />
                    )
                  }
                  onClick={() => setActiveResourceName(resource.name)}
                />
              )
            ) : null;
          })}
          {role === "office" || role === "corp" || role === "rep" ? (
            <SubMenu name="Documents">
              <MenuItemLink
                to={{
                  pathname: "/documentsAdmin/homes",
                }}
                primaryText={translate(`Homes`, {
                  smart_count: 2,
                })}
                leftIcon={
                  activeResourceName === "/documentsAdmin/homes"
                    ? ActiveIcon["documents"]
                    : Icon["documents"]
                }
                onClick={() => setActiveResourceName("/documentsAdmin/homes")}
              />
              <MenuItemLink
                to={{
                  pathname: "/documentsAdmin/divisions",
                }}
                primaryText={translate(`Divisions`, {
                  smart_count: 2,
                })}
                leftIcon={
                  activeResourceName === "/documentsAdmin/divisions"
                    ? ActiveIcon["documents"]
                    : Icon["documents"]
                }
                onClick={() => setActiveResourceName("/documentsAdmin/divisions")}
              />
              <MenuItemLink
                to={{
                  pathname: "/documentsAdmin/communities",
                }}
                primaryText={translate(`Communites`, {
                  smart_count: 2,
                })}
                leftIcon={
                  activeResourceName === "/documentsAdmin/communities"
                    ? ActiveIcon["documents"]
                    : Icon["documents"]
                }
                onClick={() => setActiveResourceName("/documentsAdmin/communities")}
              />
            </SubMenu>
          ) : null}
          {role === "corp" || role === "office" || role === "rep" ? (
            <SubMenu name="Announcements">
              <MenuItemLink
                to={{
                  pathname: "/announcementsAdmin/divisions",
                }}
                primaryText={translate(`Divisions`, {
                  smart_count: 2,
                })}
                leftIcon={
                  activeResourceName === "/announcementsAdmin/divisions"
                    ? ActiveIcon["announcements"]
                    : Icon["announcements"]
                }
                onClick={() => setActiveResourceName("/announcementsAdmin/divisions")}
              />
              <MenuItemLink
                to={{
                  pathname: "/announcementsAdmin/communities",
                }}
                primaryText={translate(`Communities`, {
                  smart_count: 2,
                })}
                leftIcon={
                  activeResourceName === "/announcementsAdmin/communities"
                    ? ActiveIcon["announcements"]
                    : Icon["announcements"]
                }
                onClick={() => setActiveResourceName("/announcementsAdmin/communities")}
              />
            </SubMenu>
          ) : null}
          {role === "office" || role === "corp" || role === "rep" ? (
            <SubMenu name="Posts">
              <MenuItemLink
                to={{
                  pathname: "/posts/homes",
                }}
                primaryText={translate(`Homes`, {
                  smart_count: 2,
                })}
                leftIcon={
                  activeResourceName === "/posts/homes" ? ActiveIcon["posts"] : Icon["posts"]
                }
                onClick={() => setActiveResourceName("/posts/homes")}
              />
              <MenuItemLink
                to={{
                  pathname: "/posts/divisions",
                }}
                primaryText={translate(`Divisions`, {
                  smart_count: 2,
                })}
                leftIcon={
                  activeResourceName === "/posts/divisions" ? ActiveIcon["posts"] : Icon["posts"]
                }
                onClick={() => setActiveResourceName("/posts/divisions")}
              />
              <MenuItemLink
                to={{
                  pathname: "/posts/communities",
                }}
                primaryText={translate(`Communites`, {
                  smart_count: 2,
                })}
                leftIcon={
                  activeResourceName === "/posts/communities" ? ActiveIcon["posts"] : Icon["posts"]
                }
                onClick={() => setActiveResourceName("/posts/communities")}
              />
            </SubMenu>
          ) : null}
          {role === ADMIN_ROLES.CORPORATE ? (
            <SubMenu name="Assets">
              <MenuItemLink
                to={{
                  pathname: "/assets/warranty",
                }}
                primaryText={translate(`Warranty`, {
                  smart_count: 2,
                })}
                leftIcon={
                  activeResourceName === "/assets/warranty" ? ActiveIcon["warrantyDocuments"] : Icon["warrantyDocuments"]
                }
                onClick={() => setActiveResourceName("/assets/warranty")}
              />
            </SubMenu>
          ) : null}
        </Menu>
      ) : null}
    </>
  );
};
