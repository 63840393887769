import React, { useCallback, useEffect, useState, Fragment } from "react";
import { Loading, usePermissions } from "react-admin";
import { BASE_URL, ADMIN_ROLES } from "../../Constants";
import instance from "../../Core/service";
import { CardWithoutCount, CardWithCount } from "./Cards";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headingMargin: {
    marginBottom: "25px",
  },
}));

export const TotalRecords = (props) => {
  const classes = useStyles(props);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { permissions } = usePermissions();
  const isSalesAdmin = permissions && permissions.roles.includes(ADMIN_ROLES.SALES);
  const isSuperAdmin = permissions && permissions.roles.includes(ADMIN_ROLES.SUPER);
  const iterativeArray = [
    { id: "userWarranty", name: "Warranty Submissions" },
    { id: "warrantyDocuments", name: "Warranty Documents" },
    { id: "posts", name: "Posts" },
    { id: "announcements", name: "Announcements" },
    { id: "documents", name: "Documents" },
  ];
  isSalesAdmin && iterativeArray.pop();
  const fetchTotalRecords = useCallback(async () => {
    setLoading(true);
    const response = await instance.get(`${BASE_URL}/user/dashboard`);
    if (response.status === 200) {
      setData(response.data);
    } else {
      setError("Error while fetching elevation");
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchTotalRecords();
  }, [fetchTotalRecords]);

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Typography variant="h4" className={classes.headingMargin}>
            Dashboard
          </Typography>
          <Grid container spacing={3}>
            {data && data.map((page, index) => <CardWithCount key={index} page={page} />)}
            {!isSuperAdmin &&
              iterativeArray.map((page, index) => <CardWithoutCount key={index} page={page} />)}
          </Grid>
          {error && <div>Error Fetching Data</div>}
        </div>
      )}
    </Fragment>
  );
};
