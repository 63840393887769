import * as React from "react";
import {
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
  FileInput,
  FileField,
} from "react-admin";
import { WriteToolbarWithoutValidation } from "../../Components";
import { FILE_SIZE_TYPES, TYPE } from "../../Constants";
import { validateFileSize, maxLength } from "../../Utils";

export const TypeMapping = new Map([
  ["divisions", "Division"],
  ["homes", "Home"],
  ["warranty", "Warranty"],
  ["communities", "Community"],
]);

export const DocumentEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [index] = React.useState(props.basePath.lastIndexOf("/"));
  const [type] = React.useState(props.basePath.substring(index + 1));

  const onSuccess = () => {
    changedFile = false;
    notify("Your changes has been updated", "success");
    redirect(`/documentsAdmin/${type}`);
  };

  const onFailure = (error) => {
    changedFile = false;
    notify(error.body.error, "error");
  };

  let changedFile = false;
  const transform = (data) => {
    let documentReturnobject = {
      title: data.title,
      description: data.description,
    };
    if (changedFile) {
      documentReturnobject = {
        ...documentReturnobject,
        file: data.fileLocation,
      };
    }
    return documentReturnobject;
  };
  const isWarrantySpecific = props.basePath.includes(TYPE.WARRANTY);
  const titleMaxLen = isWarrantySpecific ? 50 : 30;

  return (
    <Edit
      {...props}
      mutationMode="optimistic"
      onSuccess={onSuccess}
      onFailure={onFailure}
      title={`Update ${TypeMapping.get(type)} Documents`}
      transform={transform}
    >
      <SimpleForm toolbar={<WriteToolbarWithoutValidation />}>
        <TextField source="Edit Document" />
        <TextInput source="documentType" label="Document Type" disabled />
        <TextInput
          source="title"
          label="Document Title"
          validate={[required(), maxLength(titleMaxLen)]}
          multiline={true}
        />
        {isWarrantySpecific && (
          <TextInput
            source="description"
            label=" Document Description"
            fullWidth={false}
            validate={[required(), maxLength()]}
            multiline={true}
          />
        )}
        <FileInput
          source="fileLocation"
          onChange={() => {
            changedFile = true;
          }}
          options={{ label: "Location" }}
          validate={[
            required(),
            validateFileSize(isWarrantySpecific ? 10 : 20, FILE_SIZE_TYPES.MB),
          ]}
          placeholder={`Please input a File less than ${isWarrantySpecific ? 10 : 20} MB`}
          label={"Attachment"}
        >
          <PreviewFile source="src" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

const PreviewFile = ({ record, source }) => {
  if (typeof record == "string") {
    record = {
      [source]: record,
    };
  }
  return (
    <FileField
      record={record}
      source={source}
      title="View File"
      label="Action"
      target="_blank"
      sortable={false}
    />
  );
};
