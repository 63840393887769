import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { BASE_URL, TYPE } from "../../Constants";
import { CustomSeePostsButton } from "../../Components";
import instance from "../../Core/service";

export const DivisionExpand = (props) => {
  const { id: divisionId } = props;
  const [communities, setCommunities] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  React.useEffect(() => {
    const getDivisionCommunities = async (id) => {
      setIsLoading(true);
      instance
        .get(`${BASE_URL}/divisions/${id}`)
        .then((response) => {
          setCommunities(response.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsError(true);
          setIsLoading(false);
        });
    };
    getDivisionCommunities(divisionId);
  }, [divisionId]);

  const tableHeadings = [
    "Community Id",
    "Community Name",
    "Community Abbreviation",
    "City",
    "State",
    "County",
    "Zip",
  ];
  const tabelRows = ["id", "name", "abbr", "city", "stateName", "county", "zip"];
  const isPostCommunityPage = window.location.href.search(TYPE.COMMUNITIES) !== -1;
  isPostCommunityPage && tableHeadings.push("Action");

  return (
    <Card>
      <CardContent>
        {isLoading ? (
          <Typography variant="body2">Loading ...</Typography>
        ) : isError ? (
          <Typography variant="body2">Something went wrong ...</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {tableHeadings.map((heading, index) => {
                  return (
                    <TableCell key={index}>
                      <strong>{heading}</strong>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {communities &&
                communities.map((community) => (
                  <TableRow key={community.id}>
                    {tabelRows.map((row, index) => {
                      return <TableCell key={index}>{community[row]}</TableCell>;
                    })}
                    {isPostCommunityPage && (
                      <TableCell>
                        <CustomSeePostsButton
                          type={`${TYPE.COMMUNITIES}`}
                          customId={community.id}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};
