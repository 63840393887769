import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  number,
  minValue,
  maxValue,
  useNotify,
  useRedirect,
  FunctionField,
  maxLength,
} from "react-admin";
import { WriteToolbar } from "../../Components";

const validateInput = [required(), number(), minValue(0), maxValue(99)];
const validateStringInput = [required(), maxLength(99)];

export const SettingEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    redirect("/settings");
    notify("Your changes has been updated", "success");
  };

  const onFailure = (error) => {
    notify(error.body.error, "error");
  };

  return (
    <Edit
      mutationMode="optimistic"
      onSuccess={onSuccess}
      onFailure={onFailure}
      title={"Edit Configuration"}
      {...props}
    >
      <SimpleForm toolbar={<WriteToolbar />}>
        <TextInput disabled label="id" source="id" />
        <TextInput disabled label="field" source="field" />
        <FunctionField
          label="Value"
          render={(record) => (
            <TextInput
              source="value"
              validate={
                record.id === "specialCharacterDisallow" ? validateStringInput : validateInput
              }
            />
          )}
        />
      </SimpleForm>
    </Edit>
  );
};
