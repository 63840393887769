import { Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { UserIntitalsImage } from "../../Components";
import { formatAMPM } from "../../Utils";

const useStyles = makeStyles((theme) => ({
  megRow: {
    minWidth: "270px",
    maxWidth: "75%",
    padding: "12px",
    borderRadius: "12px",
    marginTop: "10px",
    display: "inline-block",
  },
  megAvtar: {
    fontSize: "14px",
    fontWeight: "600",
  },
  megHeading: {
    fontSize: "14px",
    fontWeight: "700",
    color: "inherit",
    lineHeight: "1.3",
  },
  megText: {
    fontSize: "14px",
  },
  megTime: {
    fontSize: "10px",
    lineHeight: "1",
    textAlign: "right",
    marginTop: "5px",
  },
  megColumn: {
    fontSize: "14px",
  },
}));

const GetUserPhoto = (props) => {
  const { msg, record } = props;
  const { adminData, buyerData } = record;
  let firstName = "";
  let lastName = "";
  if (msg.from === "admin") {
    firstName = adminData.firstName;
    lastName = adminData.lastName;
  } else {
    firstName = buyerData.firstName;
    lastName = buyerData.lastName;
  }
  return (
    <UserIntitalsImage
      admin={msg.from === "admin" ? true : false}
      firstName={firstName}
      lastName={lastName}
    />
  );
};

export const MessageByDate = (props) => {
  const { messages, record } = props;
  const { adminData, buyerData } = record;
  const classes = useStyles(props);
  const getUserName = (msg) => {
    if (msg.from === "admin") {
      return `${adminData.firstName} ${adminData.lastName}`;
    } else {
      return `${buyerData.firstName} ${buyerData.lastName}`;
    }
  };

  return (
    <React.Fragment>
      {messages.map((msg, index) => {
        const datetime = new Date(msg.dateTime);
        const time = formatAMPM(datetime);
        return (
          <div
            key={index}
            className={classes.megRow}
            style={{
              alignSelf: msg.from === "admin" ? "end" : "start",
              background: msg.from === "admin" ? "#5589c7" : "#fff",
              color: msg.from === "admin" ? "#fff" : "#000",
            }}
          >
            <Grid container spacing={2}>
              <Grid item className={classes.megAvtar}>
                {<GetUserPhoto msg={msg} record={record} />}
              </Grid>
              <Grid item sm className={classes.megColumn}>
                <Typography component="h4" className={classes.megHeading}>
                  {`${getUserName(msg)} `}
                </Typography>
                <Typography variant="body2" className={classes.megText}>
                  {msg.message}
                </Typography>
                <Typography component="div" className={classes.megTime}>
                  {time}
                </Typography>
              </Grid>
            </Grid>
          </div>
        );
      })}
    </React.Fragment>
  );
};
