import React from "react";
import { Messages } from "./Messages";
import { Loading, useQuery } from "react-admin";
export const MessageWrapper = () => {
  const id = window.location.href.split("/").slice(-2)[0];
  const { loading, error, data } = useQuery({
    type: "getOne",
    resource: "conversations",
    payload: { id },
  });

  if (loading) return <Loading />;

  return (
    <React.Fragment>
      {error && <div>Something went wrong, please try again or contact admin</div>}
      {data && <Messages conversationRecord={data} />}
    </React.Fragment>
  );
};
